import { Button, Flex } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ITour } from "~/@types";
import icons from "~/assets/icons";
// import { EndTime } from "../Endtime";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { selectUserInfo, useAppSelector } from "~/redux";
import { addAuctionWishlist, addTourWishlist } from "~/services";
import { formatDate, formatMoney, isEndBids, isOpeningTime } from "~/utils";
import { EndTime } from "../Endtime";
import FButton from "../FButton";
import ItemThumbnail from "./ItemThumbnail";
import { ItemTourBids } from "./ItemTourBids";
import { ItemTourStatus } from "./ItemTourStatus";
import "./styles.scss";

type TItemTourProps = {
  layout?: "vertical" | "horizontal";
  data: ITour;
  controls?: boolean;
  border?: boolean;
  type?: "auction" | "tour";
};

function ItemTour(props: TItemTourProps) {
  const { t } = useTranslation();
  const {
    layout = "horizontal",
    data,
    controls = true,
    border = false,
    type = "auction",
  } = props;

  if (!data) {
    return null;
  }
  const {
    id,
    title,
    location,
    rating,
    reviewCounting,
    price,
    thumbnail,
    durationText,
    auctions,
    isFavourited,
  } = data;
  const [isFavorite, setIsFavorite] = useState(isFavourited || false);
  const auctionId = _.get(data, 'auctions[0].auctionId', null);
  const userInfo = useAppSelector(selectUserInfo);
  const isLogin = !!userInfo.id

  const detailsUrl = type === 'tour' ? `/tour/${id}` : `/auction/${id}/${auctionId}`

  async function handleClickWishlist() {
    if (type === "tour") {
      const res = await addTourWishlist(`${id}`)
      console.log("🚀 ~ addTourWishlist ~ res:", res)
    } else {
      const res = await addAuctionWishlist(`${auctionId}`)
      console.log("🚀 ~ addAuctionWishlist ~ res:", res)
    }
    setIsFavorite(!isFavorite)
  }
  return (
    <div
      className={`item-tour item-tour-wrap ${layout == "vertical" ? "item-tour--vertical" : "item-tour-horizontal"
        } ${border ? "item-tour--border" : ""}`}
    >
      <div className="item-tour__thumbnail">
        <Link to={`/${type}/${type === 'tour' ? id : auctionId}`}>
          <ItemThumbnail image={{
            src: thumbnail?.[0]?.originalUrl,
            alt: thumbnail?.[0]?.description
          }} />
        </Link>
        {isLogin ? (
          <div className="item-tour__favorite">
            <Button type="text" onClick={handleClickWishlist}>
              <img
                src={isFavorite ? icons.heart_active : icons.heart}
                alt="farvorite"
              />
            </Button>
          </div>
        ) : null}
        <div className="item-tour__count-date">
          <img src={icons.timer} alt="" />
          <span>
            {parseInt(durationText)} {t('days')}
          </span>
        </div>
        {
          auctions.length ?
            (<div className="item-tour__date-start">
              <img src={icons.calendar} alt="" />
              <span>{formatDate(auctions[0].timeStart, 'DD/MM')}</span>
            </div>)
            : null
        }

      </div>
      <div className="item-tour__content">
        <h3 className="item-tour__title"><Link to={detailsUrl}>{title}</Link></h3>
        <div className="item-tour__information">
          <div className="item-tour__details">
            <ul>
              <li>
                <img src={icons.pin} alt="" />
                <span>{`${location}`}</span>
              </li>
              {/* <li>
                <img src={icons.building} alt="" />
                <span>Lorem ipsum dolor sit amet.</span>
              </li>
              <li>
                <img src={icons.people} alt="" />
                <span>lo ipsum dolor sit amet.</span>
              </li>
              <li>
                <img src={icons.air_place} alt="" />
                <img src={icons.car} alt="" />
                <span>lorem ipsum dolor sit amet.</span>
              </li> */}
            </ul>
            <div className="item-tour__rate">
              <img src={icons.star} alt="" />
              <strong>{rating}</strong>
              <span>
                ({reviewCounting} {t('reviews')})
              </span>
            </div>
          </div>
          {
            type === "tour" ? (
              <Flex
                align="center"
                justify="space-between"
                className="item-tour__price"
              >
                <span>{t('starting_from')}</span>
                <strong>
                  USD <b>{formatMoney(parseFloat(price))}</b>
                </strong>
              </Flex>
            ) : (
              <div className="item-tour__bid">
                <Flex
                  align="center"
                  justify="space-between"
                  className="item-tour__price"
                >
                  <span>{t('original_price')}</span>
                  <strong>
                    USD <del>{formatMoney(parseFloat(price))}</del>
                  </strong>
                </Flex>
                <div className="item-tour__bid__content">
                  <ItemTourStatus status={0} startPrice={auctions[0]?.startPrice} timeEnd={auctions[0]?.timeEnd} />
                  <ItemTourBids bids={auctions[0]?.currentBid} currentBid={auctions[0]?.currentBid} />
                </div>
              </div>
            )
          }
          {type === 'auction' && isOpeningTime(auctions[0]?.timeEnd) ? (<Flex justify="end">
            <EndTime endTime={auctions[0]?.timeEnd} />
          </Flex>) : null}

        </div>

        {controls ? (
          <div className="item-tour__controls">
            <Flex gap={8}>
              <Link to={detailsUrl}>
                <FButton type="default" className="f-bg-none">
                  {t('see_details')}
                </FButton>
              </Link>
              {
                type === "tour" ? (
                  <FButton className="primary-1">{t('book')}</FButton>
                ) : (
                  !isEndBids(auctions[0]?.timeEnd) && (<FButton className="primary-1">
                    <Link to={`/auction/${id}/${auctionId}/join-bid`}>
                      {t('join_bid')}
                    </Link>
                  </FButton>)
                )
              }
            </Flex>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ItemTour;
