import { message } from 'antd';
import { AnyObject } from 'antd/es/_util/type';
import { ILogin, IProfile, TBaseResponse } from '~/types';
import AxiosInstance from './networking';

const login = async (data: ILogin) => {
  try {
    const response = await AxiosInstance.post('/auth/login', data);
    message.success('Login success');
    return response.data;
  } catch (error) {
    message.error('Login error');
  }
}

const getMe = async () => {
  try {
    const response = await AxiosInstance.get<IProfile>('/auth/getMe');
    return response.data;
  } catch (error: any) {
    message.error('Get me error');
    throw new Error(error?.message)
  }
}

const changePassword = async (data: AnyObject) => {
  try {
    const response = await AxiosInstance.post('/auth/changePassword', data);
    return response.data;
  }
  catch (error) {
    message.error('Login error');
  }
}


const forgotPassword = async (email: string) => {
  try {
    const response = await AxiosInstance.post('/auth/forgot-password', { email });
    return response.data;
  } catch (error) {
    message.error('Login error');
  }
}

const resetPassword = async (token: string, newPassword: string) => {
  try {
    const response = await AxiosInstance.post('/auth/reset-password', {
      token,
      newPassword
    });
    return response.data;
  }
  catch (error) {
    message.error('Login error');
  }
}

const activateUser = async (token: string) => {
  try {
    const response = await AxiosInstance.post<TBaseResponse<null, string>>('/auth/activate-user', {
      token
    });
    return response;
  }
  catch (error) {
    message.error('Login error');
  }
}

const authWithGoogle = async (token: string) => {
  try {
    const response = await AxiosInstance.post<{
      accessToken: string,
      refreshToken: string
    }>('/auth/google-login', {
      token
    });
    return response.data;
  }
  catch (error) {
    message.error('Login error');
  }
}

export const authServices = {
  login,
  getMe,
  changePassword,
  forgotPassword,
  resetPassword,
  activateUser,
  authWithGoogle
}