import { useTranslation } from "react-i18next";
import './styles.scss';
function NotFoundItem() {
  const { t } = useTranslation();
  return (
    <div className="not-found-item not-found-item-wrap">

      <div className="not-found-item__content">
        <h3>{t('not_found')}</h3>
        <p>{t('no_products_were_found_matching_your_request')}</p>
      </div>
    </div>
  )
}

export default NotFoundItem;