import { Divider, Result, Space } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { FButton } from "~/components";
import { LINKS } from "~/configs";
import useResponsive from "~/hooks/useResponsive";

const Successful = () => {
  const { isMobile } = useResponsive();
  const {t} = useTranslation();

  return (
    <>
      <h1 className="title success">{t('successful')}</h1>
      {isMobile && <Divider />}
      <Result
        status="success"
        icon={
          <i
            className="fa-solid fa-circle-check"
            style={{ color: "#27AE60" }}
          />
        }
        title={t('congratulations')}
        subTitle={
          <Space direction="vertical" size={30}>
            <p>
              {
                t('your_account_has_been_successfully_created_you_can_now_access_all_the_features_and_benefits_available_to_our_users')
              }
            </p>
            <p>
              {
                t('to_get_started_please_log_in_using_your_credentials_if_you_have_any_questions_feel_free_to_reach_out_to_our_support_team')
              }
            </p>
            <h3>{t('thank_you_for_joining_us')}</h3>
          </Space>
        }
        extra={
          <FButton className="primary-1 is-nav-link" type="link">
            <Link to={LINKS.signIn}>{t('sign_in')}</Link>
          </FButton>
        }
      />
    </>
  );
};

export default Successful;
