import { Col, Form, Input, Row } from "antd";
import { AnyObject } from "antd/es/_util/type";
import strings from "~/assets/strings";
import FButton from "~/components/FButton";

const Subscriber = () => {
  const handleSubscriber = async (values: AnyObject) => {
    console.log("🚀 ~ handleSubscriber ~ values:", values)
  }
  return (
    <Form onFinish={handleSubscriber}>
      <Row gutter={[5, 5]} justify="space-between">
        <Col xxl={18} xl={18} lg={18} xs={24}>
          <Form.Item name="email">
            <Input placeholder="Your email here" />
          </Form.Item>
        </Col>
        <Col xxl={6} xl={6} lg={6} xs={24}>
          <Form.Item>
            <FButton htmlType="submit" className="primary-1">
              {strings().submit}
            </FButton>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
};

export default Subscriber;