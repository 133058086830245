import axios, { AxiosError, AxiosRequestHeaders, AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { LINKS } from "~/configs";
import { env } from "~/utils/env";
import AxiosConfig from "./axiosConfig";

const AxiosInstance = axios.create({
  baseURL: env.VITE_BASE_URL,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json"
  },
});

const handleError = (error: AxiosError<any>) => {
  if (axios.isCancel(error)) {
    return Promise.reject({
      code: AxiosConfig.AXIOS_STATUS_CODE.CANCEL_REQUEST_ERROR,
      message: "Request Canceled"
    })
  }
  return Promise.reject(error)
}

AxiosInstance.interceptors.request.use(
  (config) => {
    // logRequest(config)
    let cookies = Cookies.get('accessToken')
    if (cookies) {
      config.headers = {
        ...(config.headers as AxiosRequestHeaders),
        "Authorization": 'Bearer ' + cookies
      } as AxiosRequestHeaders;
    }
    return config;
  },
  (error: AxiosError<any>) => {
    return handleError(error)
  }
)


AxiosInstance.interceptors.response.use(
  (response: AxiosResponse<any>): AxiosResponse<any> => {
    if (response.data !== null && response.data !== undefined) {
      if (response.data) {
        return response;
      } else {
        throw Error("Error")
      }
    } else {
      throw Error(response.statusText)
    }
  },
  async (error: any) => {
    const originalRequest = error.config
    const isTokenError = error.response.status === 401 || (
      error.response.status === 400 && error.response?.data?.message === "Token is invalid"
    )

    if (isTokenError && !originalRequest._retry) {
      originalRequest._retry = true // Mark the request as retried to avoid infinite loops.

      try {
        const refreshToken = Cookies.get('refreshToken');
        const response = await axios.post('/auth/refresh', { refreshToken });
        const { access_token, refresh_token } = response.data;
        Cookies.set('accessToken', access_token);
        Cookies.set('refreshToken', refresh_token);

        AxiosInstance.defaults.headers.common['Authorization'] = `Bearer ${access_token}`
        return AxiosInstance(originalRequest) // Retry the original request with the new access token.
      } catch (error) {
        console.error('Token refresh failed:', error)
        Cookies.remove('accessToken');
        Cookies.remove('refreshToken');
        window.location.href = LINKS.signIn
        return Promise.reject(error)
      }
    }

    return Promise.reject(error)
  }

);
export default AxiosInstance;
