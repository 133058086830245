import { Divider, Form, Input, Space } from "antd";
import { AnyObject } from "antd/es/_util/type";
import { Link } from "react-router-dom";

import strings from "~/assets/strings";
import { FButton } from "~/components";
import { LINKS } from "~/configs";
import { IRegisterEmail } from "~/types";
import AuthLayout from "../AuthLayout";
import Privacy from "../Privacy";
import { SocialsAuth } from "../SocialsAuth";

const RegisterEmail: React.FC<IRegisterEmail> = (props) => {
  const { onSubmitEmail } = props;
  const handleSubmit = (data: AnyObject) => onSubmitEmail(data);

  return (
    <AuthLayout onSubmit={handleSubmit}>
      <div className="header-title">
        <h3>{strings().create_an_account}</h3>
      </div>
      <Form.Item label={strings().email_address} name="email"
        rules={[
          {
            required: true,
            message: strings().please_input_your_email
          },
          {
            type: "email",
            message: `${strings().the_input_is_not_valid_email}!`,
          },
        ]}
      >
        <Input placeholder={strings().fill_out_an_email} />
      </Form.Item>
      <Form.Item>
        <FButton htmlType="submit" className="primary-1">
          {strings().continue}
        </FButton>
      </Form.Item>
      <div className="footer-title">
        <Space size={4}>
          <p>{strings().do_you_have_an_account}</p>
          <Link to={LINKS.signIn}>{strings().sign_in}</Link>
        </Space>
        <Divider />
        <p>{strings().or}</p>
        <SocialsAuth />
        <Privacy />
      </div>
    </AuthLayout>
  );
};

export default RegisterEmail;