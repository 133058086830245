import { Button, Divider, Flex, Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import strings from "~/assets/strings";
import { selectUserInfo, useAppDispatch, useAppSelector } from "~/redux";
import { loadMoreNotification, markAllAsRead, selectNotifications } from "~/redux/slices/notificationSlice";
import { getNotifications, readAllNotification } from "~/services/notification";
import EmptyScreen from "../EmptyScreen";
import { ItemNotification } from "./item";
import './styles.scss';

export function NotificationsAll() {
  const pivotRef = useRef<HTMLDivElement>(null);
  const pageRef = useRef(1);
  const [isLoading, setIsLoading] = useState<Boolean>();

  const { t } = useTranslation()
  const user = useAppSelector(selectUserInfo)
  const { data: notifications, totals } = useAppSelector(selectNotifications)
  const dispatch = useAppDispatch();

  const handleMarkRead = () => {
    dispatch(markAllAsRead());
    readAllNotification({ userId: user.id })
  }

  useEffect(() => {
    if (user.id && pivotRef.current) {
      const options = {
        root: pivotRef.current,
        rootMargin: '0px',
        threshold: 0,
      };
      
      var observer = new IntersectionObserver(callback, options);
      
      function callback() {
        setIsLoading(true);
        pageRef.current += 1;
        // element scrolled into viewport
        getNotifications({ userId: user.id, page: pageRef.current })
        .then((response) => {
          dispatch(loadMoreNotification(response.data))
          if (response.data.length === 0) {
            observer.disconnect()
          }
        })
        .finally(() => setIsLoading(false))
      }
  
      observer.observe(pivotRef.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [user.id, totals])


  return (
    <div className="f-notification f-notification-wrap">
      <Flex className="f-notification__head" justify="space-between">
        <h3>{strings().notifications}</h3>
        <Button type="text" onClick={handleMarkRead}>{strings().mark_all_as_read}</Button>
      </Flex>
      <Divider /> 
      <div className="f-notification__items">
        {notifications?.length ? notifications.map((notificationInfo, index) => (
          <ItemNotification key={index} notificationInfo={notificationInfo} />
        )): <EmptyScreen description={t('no_notification')} />}
        <Flex justify="center">
          {isLoading ? <Spin /> : null}
        </Flex>
        <div ref={pivotRef} />
      </div>
    </div>
  );
};
