import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import { IBlogs } from "~/@types";
import strings from "~/assets/strings";
import { makeExerpt } from "~/utils";

const firstItem = {
  xxl: 12,
  xl: 12,
  lg: 12,
  md: 12,
  sm: 24
};
const secondItem = {
  xxl: 6,
  xl: 6,
  lg: 6,
  md: 6,
  sm: 24
};
interface IProps {
  data: IBlogs[]
}

const ValuableExperience = ({ data }: IProps) => {
  return (
    <div className="container valuable-experience">
      <h2 className="home-thread__title">{strings().the_most_valuable_experiences}</h2>
      <Row justify="space-between" align="top" gutter={[15, 15]}>
        {
          data.map((item, index) => (
            <Col {...(!index ? firstItem : secondItem)} key={index} className={`news-item ${!index && 'news-item--first'}`}>
              <Row align="top" gutter={[10, 5]}>
                <Col xxl={24} xl={24} lg={24} md={24} sm={!index ? 24 : 8} xs={!index ? 24 : 8} className="news-item__thumbnail">
                  <Link to={`/blog/${item.id}`}>
                    <img src={item.thumbnail} alt={item.title} />
                  </Link>
                </Col>
                <Col xxl={24} xl={24} lg={24} md={24} sm={!index ? 24 : 16} xs={!index ? 24 : 16} className="news-item__intro">
                  <h4>
                    <Link to={`/blog/${item.id}`}>{item.title}</Link>
                  </h4>
                  <p>{makeExerpt(item.content)}</p>
                </Col>
              </Row>
            </Col>
          ))
        }
      </Row>
    </div >
  )
};

export default ValuableExperience;