import { Col, DatePicker, Divider, Flex, Form, Input, InputNumber, Row } from 'antd';
import React, { useState } from 'react';
import icons from '~/assets/icons';
import strings from '~/assets/strings';
import FButton from '~/components/FButton';
import FModal from '~/components/FModal';
import '../FormPayment/styles.scss';

let count = 0

const rules = [
  {
    required: true,
    message: strings().required
  }
]

const dummyPromise = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (count % 2 === 0) {
        reject(false);
        return;
      }

      resolve(true);
    }, 3000);
  });
};

const ConfirmPayment = () => {
  const [error, setError] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleFinish = () => {
    setIsLoading(true);
    dummyPromise()
      .then(() => {
        setError(false);
      })
      .catch(() => setError(true))
      .finally(() => {
        setIsLoading(false);
        setIsSubmitted(true);
        count += 1;
      });
  };

  return (
    <div>
      <span className='h5'>
        {strings().check_and_pay}
      </span>
      <Divider />
      <Form layout='vertical' onFinish={handleFinish} className='form-payment'>
        <Row gutter={[10, 0]} align="bottom" >
        <Col span={24}>
            <Form.Item name={'cardholderName'} label={strings().cardholders_name} rules={rules}>
              <Input placeholder={strings().enter_your_cardholders_name}/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name={'cardNumber'} label={strings().card_number} rules={rules}>
              <InputNumber prefix={<img src={icons.card} />} placeholder={strings().enter_your_card_number}  style={{ width: '100%'}}  />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="space-between" gutter={[10, 10]}>
              <Col xxl={12} xl={12} md={12} sm={24} xs={24}>
                <Form.Item name={'expDate'} label={strings().expiration_date} rules={rules}>
                  <DatePicker placeholder='MM/YY' />
                </Form.Item>
              </Col>
              <Col xxl={12} xl={12} md={12} sm={24} xs={24}>
                <Form.Item name={'cvc'} label={strings().cvc} rules={rules}>
                  <Input placeholder={strings().cvc} prefix={<img src={icons.card} />} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Flex vertical align='center' className='terms' gap={8}>
              <div>{String(strings().by_clicking_confirm_payment_and_completing_a_booking_you_agree_with_our)}</div>
              <Flex gap={4}>
                <span className='text-orange-1'>
                  <u>
                    {strings().terms__conditions}
                  </u>
                </span>
                <div>{strings().and}</div>
                <span className='text-orange-1'>
                  <u>
                    {strings().privacy_statement}
                  </u>
                </span>
              </Flex>
            </Flex>
          </Col>
          <Col span={24} className='mt-24'>
            <FButton className='primary-1 button-payment-action' loading={isLoading} htmlType='submit'>
              {strings().confirm_payment}
            </FButton>
          </Col>
        </Row>
        <FModal
          title={''}
          open={isSubmitted}
          rootClassName="payment-results"
          onCancel={() => setIsSubmitted(false)}
        >
          <div className="bid-content">
            <Flex
              vertical
              justify="space-between"
              align="center"
              style={{ height: "100%" }}
            >
              {!error ? (
                <React.Fragment>
                  <div className="h5">{strings().payment_successful}</div>
                  <img src={icons.success_icon} style={{ width: 86, height: 86 }} />
                  <div className='success-desc'>
                    <b>
                      {strings().your_payment_has_been_completed}
                    </b>
                  </div>
                  <div className='more-info'>
                    <span>{strings().the_system_will_send_your_tickets_to_your_email_within_the_next}</span>
                    {" "}
                    <span>
                      <b>
                        {strings().twenty_four}
                      </b>
                    </span>
                    {" "}
                    <span>
                      {strings().please_check_your_email_to_find_your_tickets}
                    </span>
                  </div>
                  <FButton
                    style={{ width: 375, height: 55, color: "black" }}
                    onClick={() => setIsSubmitted(false)}
                    className='button-payment-action'
                  >
                    {strings().close}
                  </FButton>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="h5">{strings().payment_failed}</div>
                  <img src={icons.error_icon} style={{ width: 86, height: 86 }} />
                  <div className='more-info'>
                    {strings().unfortunately_your_payment_was_not_successful_please_check_your_details_and_try_again}
                  </div>
                  <FButton
                    style={{ width: 375 }}
                    className="f-bg-none button-payment-action"
                    onClick={() => setIsSubmitted(false)}
                  >
                    {strings().try_again}
                  </FButton>
                </React.Fragment>
              )}
            </Flex>
          </div>
        </FModal>
      </Form>
    </div>
  );
};


export default ConfirmPayment;