import { Flex } from "antd";
import { useTranslation } from "react-i18next";
import { formatMoney, isEndBids } from "~/utils";

type TProps = {
  // TODO
  status?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  startPrice: number | string;
  timeEnd: string;
};

export function ItemTourStatus(props: TProps) {
  const { t } = useTranslation();
  const { startPrice, timeEnd } = props;
  return (
    <div className="item-tour__status item-tour__status-wrap">
      <Flex align="center" className="item-tour__status--inner">
        {!isEndBids(timeEnd) ? (<div className="item-tour__status__label">
          <span>{t('open')}</span>
        </div>) : null}

        <Flex align="center" className="item-tour__status__price">
          <div className="item-tour__status__progress">
            <span>{!isEndBids(timeEnd) ? t('starting') : null}</span>
          </div>
          <strong>
            {/* USD  */}
            <b>${formatMoney(parseFloat(`${startPrice}`))}</b>
          </strong>
        </Flex>
      </Flex>
    </div>
  );
}
