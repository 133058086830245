import { Breadcrumb } from "antd";
import { ReactNode } from "react";
import './styles.scss';

type TProps = {
  items: (string | ReactNode)[]
}

export function FBreadcrumb(props: TProps) {
  const { items } = props;
  return (
    <Breadcrumb
      className="f-breadcrumb"
      separator={<i className="fa-light fa-angle-right" style={{ color: '#09A4D1' }} />}
      items={items.map((item, index) => ({
        title: item,
        key: index
      }))}
    />
  )
}