import { Divider, Form, Input, Space } from "antd";
import { AnyObject } from "antd/es/_util/type";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FButton } from "~/components";
import { LINKS } from "~/configs";
import useResponsive from "~/hooks/useResponsive";
import { setUserInfo, useAppDispatch } from "~/redux";
import { authServices } from "~/services";
import { ILogin } from "~/types";
import AuthLayout from "../AuthLayout";
import Privacy from "../Privacy";
import { SocialsAuth } from "../SocialsAuth";

const SignInPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isMobile } = useResponsive();
  const [loginFailed, setLoginFailed] = useState<boolean>(false);

  const handleLogin = async (data: AnyObject) => {
    const response = await authServices.login(data as ILogin);
    if (response) {
      Cookies.set('accessToken', response.access_token);
      Cookies.set('refreshToken', response.refresh_token);
      const user: any = await authServices.getMe();
      dispatch(setUserInfo(user));
      navigate(LINKS.home)
    } else {
      setLoginFailed(true);
    }
  };

  return (
    <AuthLayout onSubmit={handleLogin} onValuesChange={() => setLoginFailed(false)}>
      <div className="header-title">
        <h3>{t('sign_in')}</h3>
        {isMobile && <Divider />}
      </div>
      <Form.Item
        name="email"
        label={t('email_address')}
        validateStatus={loginFailed ? 'error' : ''}
        rules={[
          {
            required: true,
            message: `${t('the_input_is_not_valid_email')}`,
          },
          {
            type: "email",
            message: `${t('the_input_is_not_valid_email')}!`,
          },
        ]}
      >
        <Input placeholder={`${t('enter_your')} Email`} />
      </Form.Item>
      <Form.Item
        name="password"
        label={t('password')}
        validateStatus={loginFailed ? 'error' : ''}
        rules={[
          {
            required: true,
            message: `${t('please_input_your')} ${t('password')}!`,
          },
        ]}
      >
        <Input.Password
          placeholder={`${t('enter_your')} ${t('password')}`}
        />
      </Form.Item>
      <Form.Item>
        <FButton htmlType="submit" className="primary-1">
          {t('sign_in')}
        </FButton>
      </Form.Item>
      <div className="footer-title">
        <Space size={4}>
          <p>{t('create_an_account')}?</p>
          <Link to={LINKS.register}>{t('register')}</Link>
        </Space>
        <p>
          <Link to={LINKS.forgotPassword}>{t('forgot_password')}</Link>
        </p>
        <Divider />
        <p>{t('or')}</p>
        <SocialsAuth />
        <Privacy />
      </div>
    </AuthLayout>
  );
};

export default SignInPage;
