import { Button, Col, FloatButton, Row, Space } from "antd";
import FooterDesktop from "./Desktop";
// import FooterMobile from "./Mobile";
import { Link } from "react-router-dom";
import strings from "~/assets/strings";
import { PAYMENT, SOCIALS } from "~/configs";
import useResponsive from "~/hooks/useResponsive";
import Subscriber from "./Subscriber";



const FooterPage = () => {
  const handleBackTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const { isMobile } = useResponsive();

  return (
    <div className="wrapper-footer-page">
      <div className="container">
        <div className="header">
          <h2>ATOUR</h2>
        </div>
        <div className="content">
          <Row justify="space-between">
            <FooterDesktop />
            {/* <FooterMobile /> */}
            <Col xxl={7} xl={9} lg={9} md={10} sm={24} xs={24}>
              <h4>{strings().follow_us_on}</h4>
              <Space direction="vertical" className="follow">
                <Space size={20}>
                  {SOCIALS.map((item, index) => (
                    <Link key={index} to={item.link}>
                      <img src={item.icon} alt={item.name} />
                    </Link>
                  ))}
                </Space>
                <h5>{strings().subsribe_to_our_newsletters}</h5>
                <Subscriber />
                <h5>{strings().payment_partners}</h5>
                <Space size={isMobile ? 5 : 20}>
                  {PAYMENT.map((item, index) => (
                    <Button style={{ width: 50 }} key={index} type="link"
                      icon={<img src={item} alt="" />}
                    />
                  ))}
                </Space>
              </Space>
            </Col>
          </Row>
        </div>
        <div className="footer">
          <p>Copyright © 2024 Atour. All rights reserved</p>
        </div>
        <FloatButton
          icon={<i className="fa-solid fa-comments fa-xs"></i>}
          style={{ insetBlockEnd: 24 + 90 }}
        />
        <FloatButton
          icon={<i className="fa-regular fa-angle-up"></i>}
          onClick={handleBackTop}
        />
      </div>
    </div>
  )
};

export default FooterPage;