import {
  Button,
  Col, DatePicker, Divider, Flex, Form,
  Popconfirm,
  Row, Select, SelectProps, Space,
} from "antd";
import { AnyObject } from "antd/es/_util/type";
import dayjs from 'dayjs';
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import icons from "~/assets/icons";
import strings from "~/assets/strings";
import { FButton, InputPlusMinus } from "~/components";
import { useAppSelector } from "~/redux";
import { selectFilter } from "~/redux/slices/filterSlice";
import { filterOptionSelect, formatDateTo_YYYY_MM_DD, searchParamsToObject } from "~/utils";

type TProps = {
  onSearch?: (values: Record<string, string>) => void;
}

const FindDestination = (props: TProps) => {
  const { onSearch } = props;
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const params = searchParamsToObject(searchParams);
  const { locations } = useAppSelector(selectFilter);
  const [options, setoptions] = useState<SelectProps['options']>([]);
  const [persons, setPersons] = useState<AnyObject>({
    numOfAdults: parseInt(`${searchParams.get("numOfAdults") || 0}`),
    numOfChild: parseInt(`${searchParams.get("numOfChild") || 0}`),
    numOfRoom: parseInt(`${searchParams.get("numOfRoom") || 0}`)
  });

  const handleFindDestination = (values: AnyObject) => {
    const initValueFilter: any = { ...persons };
    const { checkIn, locationId } = values;
    if (checkIn) {
      initValueFilter['dateFrom'] = formatDateTo_YYYY_MM_DD(checkIn[0])
      initValueFilter['dateTo'] = formatDateTo_YYYY_MM_DD(checkIn[1])
    }
    if (locationId) initValueFilter['locationId'] = locationId;
    onSearch && onSearch(initValueFilter);
  }

  const handleCountPerson = (value: number, type: string) => {
    switch (type) {
      case 'ADULT':
        setPersons((prev) => ({ ...prev, numOfAdults: value }));
        return;
      case 'CHILDREN':
        setPersons((prev) => ({ ...prev, numOfChild: value }));
        return;
      case 'ROOM':
        setPersons((prev) => ({ ...prev, numOfRoom: value }));
        return;
    }
  };

  useEffect(() => {
    setoptions(locations.map(({ name, id }) => ({ label: name, value: id })));
  }, [locations]);

  useEffect(() => {
    const initSearchPage: any = {};
    const locationId = parseInt(`${searchParams.get("locationId")}`);
    if (locationId) initSearchPage['locationId'] = locationId;

    const dateFrom = searchParams.get("dateFrom");
    const dateTo = searchParams.get("dateTo");
    if (dateFrom && dateTo) {
      initSearchPage['checkIn'] = [dayjs(dateFrom), dayjs(dateTo)]
    }

    form.setFieldsValue({ ...initSearchPage })
  }, [params])

  return (
    <div className="destination">
      <div className="form-search-content">
        <Form onFinish={handleFindDestination} labelCol={{ span: 24 }} requiredMark={false} form={form}>
          <Row justify="start" align="bottom" gutter={[7, 7]}>
            <Col xl={8} lg={8} md={12} sm={24} xs={24}>
              <Form.Item label={strings().destination} name="locationId">
                <Select
                  placeholder={strings().where_are_you_going}
                  showSearch={true}
                  suffixIcon={<img src={icons.location_search} alt="location" />}
                  options={options as any}
                  optionRender={({ label, key }) => (
                    <Space key={key}>
                      <img width={20} height={20} src={icons.location_search} alt="location" />
                      {label}
                    </Space>
                  )}
                  filterOption={filterOptionSelect}
                />
              </Form.Item>
            </Col>
            <Col xl={6} lg={6} md={12} sm={24} xs={24}>
              <Form.Item
                name="checkIn"
                label={
                  <Flex style={{ width: '100%' }} justify="space-between" wrap="nowrap" align="center">
                    <span>{strings().checkin_date}</span>
                    <Divider type="horizontal" style={{ flex: 1, margin: '0 8px', minWidth: 'unset', borderColor: '#828282' }} />
                    <span>{strings().checkout_date}</span>
                  </Flex>
                }
              >
                <DatePicker.RangePicker
                  suffixIcon={<i className="fa-solid fa-calendar-days"></i>}
                  placeholder={[strings().select_date, strings().to_date]}
                  format={'YYYY-MM-DD'}
                  rootClassName="checkin__checkout-date"
                />
              </Form.Item>
            </Col>
            <Col xl={8} lg={8} md={12} sm={24} xs={24}>
              <Form.Item label={strings().travellers} name="Travellers">
                <Popconfirm
                  placement="bottomRight"
                  icon={null}
                  rootClassName="select-person-dropdown"
                  title={null}
                  description={(
                    <Row gutter={[0, 5]} justify="space-between" className="booking-person">
                      <Col span={24}>
                        <Flex justify="space-between">
                          <Space size={0} direction="vertical">
                            <span className="person-type">{strings().adults}</span>
                            <p>{strings().ages_18_or_above}</p>
                          </Space>
                          <InputPlusMinus
                            increment={(value: number) => handleCountPerson(value, 'ADULT')}
                            decrement={(value: number) => handleCountPerson(value, 'ADULT')}
                            initValue={persons.numOfAdults}
                          />
                        </Flex>
                      </Col>
                      <Col span={24}>
                        <Flex justify="space-between">
                          <Space direction="vertical">
                            <span className="person-type">{strings().children}</span>
                            <p>{strings().ages_017}</p>
                          </Space>
                          <InputPlusMinus
                            initValue={persons.numOfChild}
                            increment={(value: number) => handleCountPerson(value, 'CHILDREN')}
                            decrement={(value: number) => handleCountPerson(value, 'CHILDREN')}
                          />
                        </Flex>
                      </Col>
                      <Col span={24}>
                        <Flex justify="space-between" align="center">
                          <Space>
                            <span className="person-type">{strings().room}</span>
                          </Space>
                          <InputPlusMinus
                            initValue={persons.numOfRoom}
                            increment={(value: number) => handleCountPerson(value, 'ROOM')}
                            decrement={(value: number) => handleCountPerson(value, 'ROOM')}
                          />
                        </Flex>
                      </Col>
                    </Row>
                  )}
                >
                  <Button block className="btn-select-children">
                    <Space align="baseline" size={25}>
                      <Space>
                        <span>
                          <i className="fa-solid fa-user-group"></i>
                        </span>
                        <span>
                          <span style={{ color: 'black' }}>{persons.numOfAdults}</span>
                          {` `}{strings().adults}
                        </span>
                      </Space>
                      <Space>
                        <i className="fa-solid fa-period" style={{ position: "relative", top: -3 }}></i>
                        <span>
                          <span style={{ color: 'black' }}>{persons.numOfChild}</span>
                          {` `}{strings().children}
                        </span>
                      </Space>
                      <Space>
                        <i className="fa-solid fa-period" style={{ position: "relative", top: -3 }}></i>
                        <span>
                          <span style={{ color: 'black' }}>
                            {persons.numOfRoom}
                          </span>
                          {` `}{strings().room}
                        </span>
                      </Space>
                      <i className="fa-regular fa-chevron-down"></i>
                    </Space>
                  </Button>
                </Popconfirm>
              </Form.Item>
            </Col>
            <Col xl={2} lg={2} md={4} sm={24} xs={24}>
              <Form.Item>
                <FButton htmlType="submit" className="primary-1">
                  {strings().search}
                </FButton>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  )
};

export default FindDestination;