import { Divider, Space } from 'antd';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import strings from '~/assets/strings';

import { FButton } from '~/components';

const OffersCarousel = () => {
  const banners = [
    { url: '/images/offer.png' },
    { url: '/images/offer.png' },
    { url: '/images/offer.png' },
    { url: '/images/offer.png' },
    { url: '/images/offer.png' }
  ]

  return (
    <div className="banner-offer-carousel">
      <div className="container">
        <h2 className="home-thread__title">{strings().offers}</h2>
        <Swiper
          modules={[Pagination]}
          speed={600}
          pagination={{ clickable: true }}
          className='slider-item'
          autoplay
        >
          {banners.map(({ url }, index) => (
            <SwiperSlide key={index}>
              <div
                className='offer-item'
                style={{ backgroundImage: `url(${url})` }}
              >
                <Space size={40} direction='vertical'>
                  <Space size={8} direction='vertical'>
                    <h1>Seize the moment!</h1>
                    <p>Save 15% or more when you book and stay before October 1, 2024</p>
                  </Space>
                  <FButton className='primary-1'>Read more...</FButton>
                </Space>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <Divider />
      </div>
    </div>
  );
};

export default OffersCarousel;