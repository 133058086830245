import Countdown from 'react-countdown';
import strings from '~/assets/strings';
import { formatDate } from '~/utils';
import './styles.scss';

interface TimeDisplayValuesType {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
}
export function EndTime(props: { endTime: string }) {
  const { endTime } = props;
  const renderer = (timmer: TimeDisplayValuesType) => {
    const { days, hours, completed } = timmer;
    if (completed) {
      return 'End Bids';
    } else {
      return <span className='f-endtime'>{`${days} ${strings().days} ${hours} ${strings().left} (${formatDate(endTime,'ddd, hh:mm A')})`}</span>;
    }
  };
  return (
    <Countdown
      date={endTime}
      renderer={renderer}
    />
  )
}