import { Link } from 'react-router-dom'
import { LINKS } from '~/configs'

export function AppLogo() {
  return (
    <div className="app-logo">
      <h1>
        <Link to={LINKS.home}>TourAuct</Link>
      </h1>
    </div>
  )
}