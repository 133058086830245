import { createSlice } from "@reduxjs/toolkit";
import { Socket } from "socket.io-client";
import { RootState } from "../store";

type State = {
  loading: Boolean,
  isLogin: Boolean,
  websocket?: Socket
}

const initialState: State = {
  loading: false,
  isLogin: false,
  websocket: undefined
};

export const appSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLogin: (state, action) => {
      state.isLogin = action.payload;
    },
    setWebsocket: (state, action) => {
      state.websocket = action.payload;
    }
  },
});

export const { setLoading, setLogin, setWebsocket } = appSlice.actions;

export const selectWebsocketInstance = (state: RootState) => state.app.websocket

export default appSlice.reducer;
