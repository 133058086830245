import { Form, Input, message } from "antd";
import { AnyObject } from "antd/es/_util/type";
import { useNavigate, useSearchParams } from "react-router-dom";

import strings from "~/assets/strings";
import { FButton } from "~/components";
import { LINKS } from "~/configs";
import { authServices } from "~/services";
import AuthLayout from "../AuthLayout";

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const handleSubmit = async (data: AnyObject) => {
    if (!token) return message.error('can not reset password without token');
    const response = await authServices.resetPassword(token!, data.newPassword);
    if (response.isSuccess) {
      message.success('Reset Password Success');
      navigate(LINKS.signIn)
    }
  };

  return (
    <AuthLayout onSubmit={handleSubmit}>
      <div className="header-title">
        <h3>{strings().reset_password}</h3>
      </div>
      <Form.Item
        name="newPassword"
        label={strings().new_password}
        rules={[
          {
            required: true,
            message: `${strings().please_input_your} ${strings().password}!`,
          },
          {
            min: 10,
            message: strings().password_must_be_at_least_10_characters_long,
          },
          {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{10,}$/,
            message: strings().password_must_contain_at_least_one_uppercase_letter_one_lowercase_letter_and_one_number,
          }
        ]}
      >
        <Input.Password placeholder={strings().enter_a_password} />
      </Form.Item>
      <Form.Item
        name="confirm"
        label={strings().config_new_password}
        dependencies={['newPassword']}
        rules={[
          {
            required: true,
            message: strings().please_confirm_your_password,
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(strings().the_new_password_that_you_entered_do_not_match));
            },
          }),
        ]}
      >
        <Input.Password placeholder={strings().enter_a_password} />
      </Form.Item>
      <Form.Item>
        <FButton htmlType="submit" className="primary-1">
          {strings().reset_password}
        </FButton>
      </Form.Item>
    </AuthLayout>
  )
};

export default ResetPassword;