import { useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { websocket } from '~/core/websocket';
import { getAccessToken } from '~/hooks';
import { setUserInfo, useAppDispatch } from '~/redux';
import { authServices } from '~/services';
export const ProtectedRoute = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
  console.log("🚀 ~ ProtectedRoute ~ isAuthenticated:", isAuthenticated)
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let userId: string | null = null;

    const fetchUser = async () => {
      const user: any = await authServices.getMe();
      dispatch(setUserInfo(user));
      userId = user.id;
    };

    fetchUser().catch(() => {
      navigate('/');
      websocket.close();
    });
    
    return () => {
      if (userId) {
        websocket.offEvent(userId)
      }
    }
  }, []);
  
  const token = getAccessToken();
  if (!token) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};