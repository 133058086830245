import { Space } from "antd";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import strings from "~/assets/strings";
import FMenuHeader from "~/components/FMenu";
import { LINKS } from "~/configs";
import useResponsive from "~/hooks/useResponsive";
import { objectToQueryString } from "~/utils";
import FindDestination from "./FindDestination";
export * from './HeaderBanner';
export * from './HeaderSmall';

function Header() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  function handleOnSearchHome(values: any) {
    navigate(`${LINKS.search}?${objectToQueryString({ ...values, page: 1 })}`);
  }
  return (
    <div>
      <div className="wrapper-header-page">
        <div className="container">
          <FMenuHeader />
          <div className="uncover-content">
            <Space direction="vertical" size={15}>
              <Link to="">{strings().feel_the_experience}</Link>
              <h1>{t('uncover_your_dream_tours')}</h1>
              <h2>{t('through_the_booking_andauction_platform')}</h2>
            </Space>
          </div>
          {!isMobile && <FindDestination onSearch={handleOnSearchHome} />}
        </div>
      </div>
      {isMobile && <FindDestination />}
    </div>
  )
}

export default Header