import { Flex } from 'antd';
import icons from '~/assets/icons';
import strings from '~/assets/strings';

type TPageHeadingProps = {
  title?: string,
  subtitle?: string,
  reviewCounting: number,
  rating: number
}

const PageHeading = ({
  rating,
  reviewCounting,
  subtitle = 'Tp. Nha Trang, Khánh Hòa, Việt Nam',
  title = 'Ninh Binh - Hoa Lu - Mua Cave - Tam Coc 1 Day Tour'
}: TPageHeadingProps) => {
  return (
    <Flex gap={14} style={{ margin: "24px 0" }} vertical>
      <p className="h3 mb-0">
        {title}
      </p>
      <Flex justify="space-between" wrap gap={8}>
        <Flex gap={2}>
          <img src={icons.location} />
          {subtitle}
        </Flex>
        <Flex style={{ fontSize: 14, gap: 8 }} align="center">
          <img src={icons.star} />
          <Flex gap={2}>
            <span>{rating}</span>
            <span>({reviewCounting} {' '} {strings().reviews})</span>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};


export default PageHeading;