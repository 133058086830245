import { useState } from 'react';
import icons from '~/assets/icons';
import './styles.scss';

const _max = 999;
const _min = 0;

type IProps = {
  increment?: (value: number) => void;
  decrement?: (value: number) => void;
  initValue?: number;
}

export function InputPlusMinus(props: IProps) {
  const { increment, decrement, initValue } = props;
  const [value, setValue] = useState(initValue || 0);

  const handleMinusClick = () => {
    setValue((prev) => {
      const incrementValue = Math.max(_min, prev - 1);
      increment && increment(incrementValue);
      return incrementValue;
    });
  };

  const handlePlusClick = () => {
    setValue((prev) => {
      const decrementValue = Math.min(_max, prev + 1);
      decrement && decrement(decrementValue);
      return decrementValue
    });
  };

  return (
    <div className="finput-number finput-number-wrap">
      <button type='button' onClick={handleMinusClick}>
        <img src={icons.minus} alt="" />
      </button>
      <input type="number" value={value} onChange={(e) => setValue(parseInt(e.target.value))} disabled />
      <button type='button' onClick={handlePlusClick}>
        <img src={icons.plus} alt="" />
      </button>
    </div>
  );
}
