// import useResponsive from "~/hooks/useResponsive";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";
import { getAllLocations, getBlogList, getInterestingTour, getLiveAuctions, getRecentAuctions, getUpCommingAuction } from "~/services";
import { LoaderData } from "~/utils";
import HomeBanner from "./components/HomeBanner";
import ListTours from "./components/ListTours";
import LocationCarousel from "./components/LocationsCarousel";
import OffersCarousel from "./components/OffersCarousel";
import Suppliers from "./components/Suppliers";
import ValuableExperience from "./components/ValuableExperience";
import './styles.scss';

export async function loaderHomePage() {
  const [
    interestingTour,
    liveAuctions,
    recentAuctions,
    locations,
    commingAuction,
    blogList
  ] = await Promise.all(
    [
      getInterestingTour(),
      getLiveAuctions(),
      getRecentAuctions(),
      getAllLocations(),
      getUpCommingAuction(),
      getBlogList({ limit: 3, page: 1 }),
    ]
  )

  return ({
    interestingTour,
    liveAuctions,
    recentAuctions,
    locations,
    commingAuction,
    blogList
  })
}

function HomePage() {
  const { t } = useTranslation();
  const res = useLoaderData() as LoaderData<typeof loaderHomePage>;
  const {
    interestingTour,
    liveAuctions,
    recentAuctions,
    locations,
    commingAuction,
    blogList
  } = res;
    console.log("🚀 ~ HomePage ~ commingAuction:", commingAuction)


  return (
    <main className="wrapper-home-page content">
      <OffersCarousel />
      <ListTours title={t('most_recent_successful_auction')} data={recentAuctions} />
      <ListTours title={t('live_auction')} data={liveAuctions} />
      <ListTours title={t('interesting_tour')} data={interestingTour} type='tour' />
      <ListTours title={t('exciting_tours_up_for_auction_soon')} data={commingAuction} />
      {locations ? <LocationCarousel locations={locations} /> : null}
      <ListTours title={t('the_most_exciting_cruise')} data={commingAuction} />
      <ValuableExperience data={blogList?.data!} />
      <Suppliers />
      <HomeBanner />
    </main>
  );
}

export default HomePage;
