import { Result, Space, message } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { FButton } from "~/components";
import { LINKS } from "~/configs";
import { authServices } from "~/services";

const ActiveUserPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  async function onLoad(){
    if (!token) {
      navigate(LINKS.signIn);
    } else{
      const res = await authServices.activateUser(token);
      if (res?.data.isSuccess) {
        message.success(t('your_account_has_been_successfully_activated'));
      }
      else{
        message.error(t('activation_link_is_invalid'));
      }
      navigate(LINKS.signIn);
    }
  }
  useEffect(() => {
    onLoad();
  },[]);

  return (
    <div className="wrapper-auth-layout container-small">
      <div className="content">
        <div className="header-title">
          <Result
            status="success"
            icon={
              <i
                className="fa-solid fa-circle-check"
                style={{ color: "#27AE60" }}
              />
            }
            title={t('congratulations')}
            subTitle={
              <Space direction="vertical" size={30}>
                <p>
                  {
                    t('thank_you_for_activating_your_account_were_excited_to_have_you_and_look_forward_to_supporting_your_journey')
                  }
                </p>
                <h3>{t('thank_you_for_joining_us')}</h3>
              </Space>
            }
            extra={
              <FButton className="primary-1 is-nav-link" type="link">
                <Link to={LINKS.signIn}>{t('sign_in')}</Link>
              </FButton>
            }
          />
        </div>
      </div>
    </div>
  )
};

export default ActiveUserPage;