import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useEffect } from "react";
import { Outlet, useNavigation } from "react-router-dom";
import { useAppDispatch } from "~/redux";
import { setDataOnLoad } from "~/redux/slices/filterSlice";
import { getAllCategories, getAllLocations } from "~/services";
export function WithLoading() {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    Promise.all([getAllCategories(), getAllLocations()]).then(([categories, locations]) => {
      dispatch(setDataOnLoad({ categories: categories.data.data, locations: locations }));
    });
  }, []);
  return (
    <Spin
      style={{ height: '100vh',maxHeight: 'unset' }}
      delay={500}
      spinning={navigation.state === "loading"}
      indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
    >
      <Outlet />
    </Spin>
  );
}
