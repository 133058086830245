import { Form, Select } from "antd";
import strings from "~/assets/strings";

const FieldLanguage = () => {
  return (
    <Form.Item label={strings().language}>
      <Select
        options={[{ label: 'American English', value: 'American English' }]}
        placeholder={strings().language}
      />
    </Form.Item>
  )
};

export default FieldLanguage;

