import { Form, Select } from "antd";
import strings from "~/assets/strings";

const FieldCurrency = () => {
  return (
    <Form.Item label={strings().currency}>
      <Select
        options={[{ label: 'USD($)', value: 'USD($)' }]}
        placeholder={strings().currency}
      />
    </Form.Item>
  )
};

export default FieldCurrency;

