import FMenuHeader from "~/components/FMenu";
import "./styles.scss";

type TProps = {
  background?: string;
};

export function HeaderSmall(props: TProps) {
  const { background = "/images/bg-header-small.png" } = props;
  return (
    <header
      id="header"
      className="header-small"
      style={{
        backgroundImage: `url(${background})`,
        boxShadow: "0 0 20px rgba(73, 151, 172, 1)",
      }}
    >
      <div className="container">
        <FMenuHeader />
      </div>
    </header>
  );
}
