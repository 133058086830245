import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import { IBlogs } from '~/@types';
import './styles.scss';

const { Paragraph } = Typography;
type TProps = {
  data: IBlogs
}

export function ItemBlog(props: TProps) {
  const { data } = props
  const { id, title, content, thumbnail } = data
  return (
    <div className="item-blog item-blog-wrap">
      <div className="item-blog__thumbnail">
        <Link to={`/blog/${id}`}>
          <img src={thumbnail} alt={title} />
        </Link>
      </div>
      <div className="item-blog__content">
        <Paragraph ellipsis={{ rows: 1 }} className="item-blog__title">
          <Link to={`/blog/${id}`}>{title}</Link>
        </Paragraph>
        <p className="item-blog__description">{content}</p>
      </div>
    </div>
  )
}