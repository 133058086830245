import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300,400;500;600;700&display=swap');
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Inter", sans-serif;
  }

  html,
  body{
    font-family: "Inter", sans-serif;
  }

  ol,
  ul {
    list-style: none;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  img{
    max-width:100%;
  }

  .container {
    max-width: 1200px;
    margin: 0 auto;
  }

  @media screen and (max-width: 1199px) {
    .container{
      padding: 0 15px;
    }
  }

  .container-small {
    max-width: 500px;
    margin: 0 auto;
  }
  .ant-checkbox-checked .ant-checkbox-inner{
    background-color: #09A4D1;
    border-color: #09A4D1;
  }
`;
export default GlobalStyle;
