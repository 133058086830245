import useResponsive from "~/hooks/useResponsive";
import FBannerContent from "./FBannerContent";
import './styles.scss';

type TProps = {
  background?: string;
  breadcrumb?: { label: string, link: string, isActive: boolean }[];
  renderControls?: () => JSX.Element;
  onlyControls?: boolean;
}

export function Banner(props: TProps) {
  const { isMobile } = useResponsive();
  const { background = "/images/bg-banner.jpg", breadcrumb, renderControls, onlyControls } = props;

  return (
    <div className="wrapper-banner-tour">
      <div className="f-banner f-banner-wrap" style={{ backgroundImage: `url(${background})` }}>
        {!isMobile && (
          <FBannerContent
            breadcrumb={breadcrumb}
            renderControls={renderControls}
            onlyControls={onlyControls}
          />
        )}
      </div>
      {isMobile && (
        <FBannerContent
          breadcrumb={breadcrumb}
          renderControls={renderControls}
          onlyControls={onlyControls}
        />
      )}
    </div>
  );
};