import { Col, Row, Space } from "antd";
import { Link } from "react-router-dom";
import useResponsive from "~/hooks/useResponsive";

type TProps = {
  breadcrumb?: { label: string, link: string, isActive: boolean }[];
  renderControls?: () => JSX.Element;
  onlyControls?: boolean;
}

const FBannerContent: React.FC<TProps> = (props) => {
  const { breadcrumb, renderControls, onlyControls = false } = props;
  const { isMobile } = useResponsive();

  return (
    <div className="container">
      <Row justify="space-between" align="middle">
        {!onlyControls ? (
          <Col xxl={9} xl={9} lg={9} md={9} sm={24} xs={24}>
            <div>
              {breadcrumb && breadcrumb.length > 0 && (
                <Space direction="vertical" size={12} className="header__breadcrums">
                  <Space size={15}>
                    {breadcrumb.map((item, index) => (
                      <Space key={index} size={15}>
                        <Link
                          className={(item.isActive && isMobile) ? 'is-active' : ''} to={item.link}
                        >
                          {item.label}
                        </Link>
                        {index < breadcrumb.length - 1 && (
                          <i className="fa-regular fa-chevron-right" style={{ color: '#09A4D1' }}></i>
                        )}
                      </Space>
                    ))}
                  </Space>
                  <h2 className="f-banner__current">{breadcrumb[breadcrumb.length - 1].label}</h2>
                </Space>
              )}
            </div>
          </Col>
        ) : null}
        <Col
          xxl={onlyControls ? 24 : 15}
          xl={onlyControls ? 24 : 15}
          lg={onlyControls ? 24 : 15}
          md={onlyControls ? 24 : 15}
          sm={24}
          xs={24}>
          <div>
            {renderControls && (
              <Row className="header__breadcrums__right">
                {renderControls()}
              </Row>
            )}
          </div>
        </Col>
      </Row>
    </div>
  )
};

export default FBannerContent;