import { Col, Divider, Flex, Form, Input, InputNumber, Row, message } from 'antd';
import { useState } from 'react';
import strings from '~/assets/strings';
import FButton from '~/components/FButton';
import './styles.scss';

const rules = [
  {
    required: true,
    message: strings().required
  }
]

const dummyPromise = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, 3000);
  });
};

const FormPayment = ({ onDone }: any) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleFinish = () => {
    setIsLoading(true);
    dummyPromise()
      .then(onDone)
      .catch(() => message.error(strings().retry))
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div>
      <span className='h5'>
        {strings().your_detail}
      </span>
      <Divider />
      <Form layout='vertical' onFinish={handleFinish} className='form-payment'>
        <Row gutter={[10, 0]} align="bottom" >
          <Col span={24}>
            <Row justify="space-between" gutter={[10, 10]}>
              <Col xxl={12} xl={12} md={12} sm={24} xs={24}>
                <Form.Item name={'firstName'} label={strings().first_name} rules={rules}>
                  <Input placeholder={strings().enter_your_first_name} />
                </Form.Item>
              </Col>
              <Col xxl={12} xl={12} md={12} sm={24} xs={24}>
                <Form.Item name={'lastName'} label={strings().last_name} rules={rules}>
                  <Input placeholder={strings().enter_your_last_name} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Form.Item name={'email'} label={strings().email_address} rules={rules}>
              <Input placeholder={strings().enter_your_email_address} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name={'phoneNumber'} label={strings().phone_number} rules={rules}>
              <InputNumber placeholder={strings().enter_your_phone_number} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Flex vertical align='center' className='terms' gap={8}>
              <div>{String(strings().by_clicking_payment_details_and_completing_a_booking_you_agree_with_our)}</div>
              <Flex gap={4}>
                <span className='text-orange-1'>
                  <u>
                    {strings().terms__conditions}
                  </u>
                </span>
                <div>{strings().and}</div>
                <span className='text-orange-1'>
                  <u>
                    {strings().privacy_statement}
                  </u>
                </span>
              </Flex>
            </Flex>
          </Col>
          <Col span={24} className='mt-24'>
            <FButton className='primary-1 button-payment-action' loading={isLoading} htmlType='submit'>
              {strings().payment_details}
            </FButton>
          </Col>
        </Row>
      </Form>
    </div>
  );
};


export default FormPayment;