import { Form, Input } from "antd";
import strings from "~/assets/strings";

const FieldPhoneNumber = () => {
  return (
    <Form.Item
      name="phoneNumber"
      label={strings().your_phone_number}
      rules={[{
        required: true,
        message: strings().please_enter_required_information
      }]}
    >
      {/* <InputNumber controls={false} placeholder={strings().enter_your_phone_number} /> */}
      <Input placeholder={strings().enter_your_phone_number}/>
      {/* <InputNumber controls={false} placeholder={strings().enter_your_phone_number} /> */}
    </Form.Item>
  )
};

export default FieldPhoneNumber;

