import { Col, Collapse } from "antd";

import strings from "~/assets/strings";
import { ABOUT_ATOUR, PRODUCT } from "~/configs";
import useResponsive from "~/hooks/useResponsive";
import FooterItems from "../FooterItem";

const FooterDesktop = () => {
  const { isMobile } = useResponsive();
  return (
    <>
      <Col span={24} lg={5} md={7} sm={7}>
        <h4 style={{ display: isMobile ? 'none' : 'block' }}>About ATOUR</h4>
        <FooterItems itemCols={ABOUT_ATOUR} hidden={isMobile} />
        <Collapse
          className="footer-collapse"
          style={{ display: isMobile ? 'block' : 'none' }}
          items={[
            {
              key: '1',
              label: strings().about_atour,
              children: <FooterItems itemCols={ABOUT_ATOUR} />,
            },
            {
              key: '2',
              label: strings().product,
              children: <FooterItems itemCols={PRODUCT} />,
            },
          ]}
          defaultActiveKey={['1']}
        />
      </Col>
      <Col style={{ display: isMobile ? 'none' : 'block' }} lg={10} md={7} sm={10}>
        <h4>{strings().product}</h4>
        <FooterItems itemCols={PRODUCT} hidden={isMobile} />
      </Col>
    </>
  )
};

export default FooterDesktop;