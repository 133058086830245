import { Outlet } from "react-router-dom";

import { FHeaderAuth } from "~/components";
import FooterPage from "~/pages/Footer";
import { HeaderSmall } from "~/pages/Header";

// The loader function is called when the route is loaded
// export async function loader() {
// const data = await fetch("https://jsonplaceholder.typicode.com/posts");
// const posts = await data.json();
// return { posts };
// }

export function Root() {
  // const { posts } = useLoaderData();
  return (
    <div id="wrapper">
      <HeaderSmall />
      <div className="outlet-wrapper">
        <Outlet />
      </div>
      <FooterPage />
    </div >
  );
}

export const RootAuth = () => {
  return (
    <div id="root-wrapper-auth">
      <FHeaderAuth />
      <Outlet />
      <FooterPage />
    </div >
  )
}