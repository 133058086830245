import { Space } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LINKS } from "~/configs";

const Privacy = () => {
  const {t} = useTranslation();
  return (
    <Space direction="vertical" style={{marginTop: 20}}>
      <p>{t('by_signing_in_or_creating_an_account_you_agree_with_our')}</p>
      <Space>
        <Link to={LINKS.term}>{t('terms__conditions')}</Link>
        <p>{t('and')}</p>
        <Link to={LINKS.privacy}>{t('privacy_statement')}</Link>
      </Space>
    </Space>
  )
};

export default Privacy;