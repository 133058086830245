import { Button, Checkbox, Col, Form, FormProps, Row, Slider } from "antd";
import { useTranslation } from "react-i18next";
import { useDebounceCallback } from "usehooks-ts";
import { useAppSelector } from "~/redux";
import { selectFilter } from "~/redux/slices/filterSlice";
import { InputPlusMinus } from "../InputPlusMinus";
import './styles.scss';

export type FieldData = {
  status?: ('upcoming' | 'open')[];
  categoryIds: string[];
  numberOfAdults: number;
  numOfChild: number;
  price: number[],
  timeofday: string[],
  locationIds: string[],
  language: string[],
};

const _dump = {
  status: ['upcoming', 'open'],
  categories: ['Tour', 'Nature & outdoor', 'Museums, arts & culture', 'Entertainment & tickets', 'Food & drink', 'Workshops & classes', 'Services & rentals'],
  timeofday: [{
    label: 'Morning',
    value: 'morning',
    description: 'Start before 12:00 PM'
  }, {
    label: 'Afternoon',
    value: 'afternoon',
    description: 'Start after 12:00 PM'
  }, {
    label: 'Evening',
    value: 'evening',
    description: 'Start after 16:00 PM'
  }],
  locaitons: ["Hanoi", "Da Nang", "Ho Chi Minh City", "Nha Trang", "Phu Quoc", "Bangkok", "Manila"],
  language: ["English", "Vietnamese", "French", "Russian"],
};

// const _initialValues: FieldData = {
//   categoryId: ['Tour'],
//   numberOfAdults: 2,
//   numOfChild: 0,
//   price: [200, 1200],
//   timeofday: ['morning'],
//   locationId: ['Hanoi'],
//   language: ['English'],
// };

type TProps = {
  // TODO
  initValues?: FieldData;
  isAuction?: boolean;
  onFieldsChange: (fields: any) => void;
};

export function FilterField(props: TProps) {
  const { isAuction = false, onFieldsChange, initValues } = props;
  const { t } = useTranslation();
  const { categories, locations } = useAppSelector(selectFilter);
  const [form] = Form.useForm();
  const debounced = useDebounceCallback(onFieldsChange, 500);

  const onFinish: FormProps<FieldData>['onFinish'] = (values) => {
    console.log('Success:', values);
  };

  const onFinishFailed: FormProps<FieldData>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleOnClear = () => {
    form.resetFields();
  };

  const onChangeComplete = (value: number[]) => {
    form.setFieldsValue({ price: value });
    const values = form.getFieldsValue();
    if (values.price) {
      values['minPrice'] = value[0];
      values['maxPrice'] = value[1];
      delete values.price;
      debounced(values);
    }
  };

  const handleOnFieldsChange = () => {
    const values = form.getFieldsValue();
    debounced(values);
  }
  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 0 }}
      wrapperCol={{ span: 24 }}
      initialValues={initValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      // onValuesChange={() => debounced()}
      autoComplete="off"
      className="filter-field filter-field-wrap"
    >
      <div className="filter-field__title">
        <h4>{t('filter_by')}</h4>
        <Button onClick={handleOnClear} type="link" className="f-button-link filter-field__clear">{t('clear')}</Button>
      </div>
      {
        // TODO
        isAuction ? (
          <div className="filter-field__thread">
            <h5 className="filter-field__thread__title">{t('status')}</h5>
            <div className="filter-field__thread__content">
              <Form.Item<FieldData>
                label={null}
                name="status"
                wrapperCol={{ span: 24 }}
              >
                <Checkbox.Group>
                  {_dump.status.map((stt, index) => (
                    <Col span={24} key={index} style={{ marginBottom: 8 }}>
                      <Checkbox value={stt} onChange={handleOnFieldsChange}>{stt}</Checkbox>
                    </Col>
                  ))}
                </Checkbox.Group>
              </Form.Item>
            </div>
          </div>
        ) : null
      }
      <div className="filter-field__thread">
        <h5 className="filter-field__thread__title">{t('category')}</h5>
        <div className="filter-field__thread__content">
          <Form.Item<FieldData>
            label={null}
            name="categoryIds"
            wrapperCol={{ span: 24 }}
          >
            <Checkbox.Group>
              {categories.map((category, index) => (
                <Col span={24} key={index} style={{ marginBottom: 8 }}>
                  <Checkbox onChange={handleOnFieldsChange} value={`${category.id}`}>{String(category.detailName).toLowerCase()}</Checkbox>
                </Col>
              ))}
            </Checkbox.Group>
          </Form.Item>
        </div>
      </div>
      <div className="filter-field__thread">
        <h5 className="filter-field__thread__title">{t('guests')}</h5>
        <div className="filter-field__thread__content">
          <ul className="filter-field__guests">
            <li>
              <Form.Item<FieldData>
                label={null}
                name={'numberOfAdults'}
                wrapperCol={{ span: 24 }}
              >
                <InputPlusMinus decrement={num => {
                  form.setFieldsValue({ numberOfAdults: num });
                  handleOnFieldsChange();
                }} increment={num => {
                  form.setFieldsValue({ numberOfAdults: num });
                  handleOnFieldsChange();
                }} />
                <span>{t('adult')}</span>
              </Form.Item>
            </li>
            <li>
              <Form.Item<FieldData>
                label={null}
                name={'numOfChild'}
                wrapperCol={{ span: 24 }}
                style={{ display: 'flex' }}
              >
                <InputPlusMinus decrement={num => {
                  form.setFieldsValue({ numOfChild: num });
                  handleOnFieldsChange();
                }} increment={num => {
                  form.setFieldsValue({ numOfChild: num });
                  handleOnFieldsChange();
                }} />
                <span>{t('children')}</span>
              </Form.Item>
            </li>
          </ul>
        </div>
      </div>
      <div className="filter-field__thread">
        <h5 className="filter-field__thread__title">{t('price')} <span>(USD)</span></h5>
        <div className="filter-field__thread__content">
          <Form.Item<FieldData>
            label={null}
            name={'price'}
            wrapperCol={{ span: 24 }}
          >
            <Slider range min={0} max={2000} onChangeComplete={onChangeComplete} tooltip={{
              visible: true,
              placement: 'bottom',
              arrow: false
            }} />
          </Form.Item>
        </div>
      </div>
      <div className="filter-field__thread">
        <h5 className="filter-field__thread__title">{t('time_of_day')}</h5>
        <div className="filter-field__thread__content">
          <Form.Item<FieldData>
            label={null}
            name={'timeofday'}
            wrapperCol={{ span: 24 }}
            className="filter-field__timeofday"
          >
            <Checkbox.Group>
              {_dump.timeofday.map((time, index) => (
                <Col span={24} key={index} style={{ marginBottom: 8 }}>
                  <Checkbox onChange={handleOnFieldsChange} value={time.value}>{time.label}</Checkbox>
                  <span>{time.description}</span>
                </Col>
              ))}
            </Checkbox.Group>
          </Form.Item>
        </div>
      </div>
      <div className="filter-field__thread">
        <h5 className="filter-field__thread__title">{t('location')}</h5>
        <div className="filter-field__thread__content">
          <Form.Item<FieldData>
            label={null}
            name={'locationIds'}
            wrapperCol={{ span: 24 }}
          >
            <Checkbox.Group>
              <Row>
                {locations.map((local, index) => (
                  <Col span={24} key={index} style={{ marginBottom: 8 }}>
                    <Checkbox onChange={handleOnFieldsChange} value={`${local.id}`}>{local.name}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </div>
      </div>
      <div className="filter-field__thread">
        <h5 className="filter-field__thread__title">{t('language')}</h5>
        <div className="filter-field__thread__content">
          <Form.Item<FieldData>
            label={null}
            name={'language'}
            wrapperCol={{ span: 24 }}
          >
            <Checkbox.Group>
              <Row>
                {_dump.language.map((local, index) => (
                  <Col span={24} key={index} style={{ marginBottom: 8 }}>
                  <Checkbox onChange={handleOnFieldsChange} value={local}>{local}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </div>
      </div>
      {/* <div className="filter-field__more">
        <Button type="link" className="f-button-link">{t('show_more')}</Button>
      </div> */}
    </Form>
  )
}
