import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Spin } from "antd";
import React from "react";
import "./styles.scss";

type TLoadingProps = {
  children?: React.ReactNode;
  isLoading: boolean;
  [x: string]: any;
};

const Loading = ({ children = null, ...rest }: TLoadingProps) => {
  return (
    <Flex className="loading-outer" justify="center" align="center" {...rest}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />} />
      <div className="loading-inner">{children}</div>
    </Flex>
  );
};

export default Loading;
