import { Flex, message } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import strings from "~/assets/strings";
import { selectUserInfo, useAppSelector } from "~/redux";
import { addTourWishlist } from "~/services";
import CustomCalendar from "../CustomCalendar";
import FButton from "../FButton";
import Price from "../Price";
import "./styles.scss";

type TTourCalendarProps = {
  tourDetails: any
};

const TourCalendar = ({ tourDetails }: TTourCalendarProps) => {
  const { price, id, isFavourited } = tourDetails;
  const [isFavorite, setIsFavorite] = useState(isFavourited || false);
  const userInfo = useAppSelector(selectUserInfo);
  const isLogin = !!userInfo.id

  async function handleClickWishlist() {
    try {
      await addTourWishlist(`${id}`)
      setIsFavorite(!isFavorite)
      message.success(strings().successful)
    } catch (error) {
      message.error(strings().failed)
    }
  }

  return (
    <Flex vertical gap={8} className="tour-calendar">
      <Flex vertical className="starting-form" gap={8}>
        {strings().starting_from}
        <Flex className="" justify="space-between">
          <Price price={price} priceClassName="price-start" />
          <Link to={`/tour/${tourDetails.id}/auctions`}>
            <FButton
              style={{ width: 140, background: "white" }}
              className="f-bg-none"
            >
              {strings().view_auction}
            </FButton>
          </Link>
        </Flex>
      </Flex>
      <Flex vertical className="main-calendar">
        <span className="h5 mb-0">{strings().tickets_and_prices}</span>
        <p className="bold-text departure-text">{strings().departure_date}</p>
        <CustomCalendar />
        <Flex justify="space-between" className="bold-text text-info">
          <span>{strings().start_time}</span>
          <span className="bold-text">07:00 AM</span>
        </Flex>
        <Flex justify="space-between" className="bold-text text-info">
          <span>{strings().language}</span>
          <span className="bold-text">English - Tour guide</span>
        </Flex>
        {isLogin ? (
          <div className="text-info">
            <FButton
              style={{ padding: "12px 0", height: 55 }}
              className="f-bg-none"
              onClick={handleClickWishlist}
              disabled={isFavorite}
            >
              {strings().add_to_wishlist}
            </FButton>
          </div>
        ) : null}
      </Flex>
    </Flex>
  );
};

export default TourCalendar;
