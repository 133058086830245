import { Drawer, Flex } from 'antd';
import icons from '~/assets/icons';
import strings from '~/assets/strings';
import { NotificationsAll } from '..';
import './styles.scss';

type TNavMobileNotificationProps = {
  isOpen: boolean,
  setIsOpen: (value: boolean) => void
}

const NavMobileNotification = ({ isOpen, setIsOpen }: TNavMobileNotificationProps) => {

  return (
      <Drawer
        open={isOpen}
        width={'100%'}
        placement='left'
        rootClassName='nav-mobile-notification'
        closeIcon={
          <Flex align='center' onClick={() => setIsOpen(false)} gap={8} style={{ fontWeight: 'normal', color: '#09A4D1' }}>
            <img src={icons.arrow_left} />
            {strings().back}
          </Flex>
        }
      >
        <NotificationsAll />
      </Drawer>
  );
};


export default NavMobileNotification;