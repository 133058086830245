import { Dates } from '~/utils/Dates';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// const localLanguage = (localStorage.getItem('lng') as LanguageType) || 'en';
export type TLanguageType = 'en' | 'vi';

export const useLanguage = (): { language: TLanguageType; setLanguage: (locale: TLanguageType) => Promise<void> } => {
  const { i18n } = useTranslation();

  const handleChangeLanguage = useCallback(
    (locale: TLanguageType): Promise<void> => {
      return new Promise(resolve => {
        Dates.setLocale(locale);
        localStorage.setItem('lng', locale);
        dayjs.locale(locale);
        i18n.changeLanguage(locale);
        resolve();
      });
    },
    [i18n],
  );

  // useEffect(() => {
  //   localLanguage && handleChangeLanguage(localLanguage);
  // }, [handleChangeLanguage]);

  return useMemo(
    () => ({ language: i18n.language as TLanguageType, setLanguage: handleChangeLanguage }),
    [handleChangeLanguage, i18n.language],
  );
};
