import { Form } from "antd";

import { IAuthLayout } from "~/types";

const AuthLayout: React.FC<IAuthLayout> = (props) => {
  const { children, onValuesChange, onSubmit } = props;

  return (
    <div className="wrapper-auth-layout container-small">
      <div className="content">
        <Form
          layout="vertical"
          onFinish={onSubmit}
          onValuesChange={onValuesChange}
        >
          {children}
        </Form>
      </div>
    </div>
  )
}

export default AuthLayout;