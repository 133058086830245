import { Card, CardProps } from "antd";
import clsx from "clsx";
import "./styles.scss";

type TFCardProps = {} & CardProps;

const FCard = ({ children, ...rest }: TFCardProps) => {
  return (
    <Card {...rest} className={clsx(rest.className, "f-card")}>
      {children}
    </Card>
  );
};

export default FCard;
