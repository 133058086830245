import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';
import EmptyScreen from '~/components/EmptyScreen';
import ItemTour from '~/components/ItemTour';
import PageHeading from '~/components/PageHeading';
import './styles.scss';

type TAuctionsInTourProps = {

}

const AuctionsInTour = (_props: TAuctionsInTourProps) => {
  const { tourDetails }: any = useLoaderData();
  const { auctions, reviewCounting, rating, title, location }: any = tourDetails;
  const { t } = useTranslation()

  return (
    <div className='auctions-in-tour container'>
      <PageHeading reviewCounting={reviewCounting} rating={rating} subtitle={location} title={title} />
      <Row gutter={16}>
        {auctions?.length ?
          auctions.map((item: any, index: number) => (
            <Col xxl={8} xl={8} md={8} sm={24} xs={24}>
              <ItemTour key={index} data={item} border type="auction" layout='vertical' controls={false} />
            </Col>
          )) : <EmptyScreen description={t('no_auctions_in_tour')} />
        }
      </Row>
    </div>
  );
};


export default AuctionsInTour;