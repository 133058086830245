import { Button, Col, Divider, Form, Row, Space } from "antd";
import { AnyObject } from "antd/es/_util/type";
import { useForm } from "antd/es/form/Form";
import dayjs from 'dayjs';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import { Fragment, useEffect, useMemo, useState } from "react";

import strings from "~/assets/strings";
import { FButton } from "~/components";
import useResponsive from "~/hooks/useResponsive";
import { selectUserInfo, setUserInfo, useAppDispatch, useAppSelector } from "~/redux";
import { userServices } from "~/services";
import { formatNationality, transformDateOfBirth } from "~/utils";
import { addressFormatToStr, showGender } from "~/utils/utils";
import FieldAddress from "./components/FieldAddress";
import FieldDateOfBirth from "./components/FieldDateOfBirth";
import FieldGender from "./components/FieldGender";
import FieldName from "./components/FieldName";
import FieldNationality from "./components/FieldNationality";
import FieldPhoneNumber from "./components/FieldPhoneNumber";

export function ProfilePersonal() {
  const [form] = useForm();
  const { isMobile } = useResponsive();
  const dispatch = useAppDispatch();
  const userProfile = useAppSelector(selectUserInfo);
  const [indexField, setIndexField] = useState<number | false>();

  function handleClickShowForm(index: number) {
    setIndexField((prev) => prev === index ? false : index)
  };

  const handleUpdateProfileUser = async (data: AnyObject) => {
    const { day, month, year } = _pick(data, ['day', 'month', 'year']);
    if (day && month && year) {
      data['dob'] = `${year}-${month}-${day}T07:00:00.000Z`;
      delete data['day'];
      delete data['month'];
      delete data['year'];
    }

    const response = await userServices.updateProfile(data);
    dispatch(setUserInfo(response.data));
    setIndexField(false);
  };

  useEffect(() => {
    let initialValue = userProfile;
    const dateOfBirth = transformDateOfBirth(userProfile.dob);
    if (dateOfBirth) {
      initialValue = { ...initialValue, ...dateOfBirth }
    }
    form.setFieldsValue(initialValue);
  }, [userProfile, indexField]);

  const initFields = useMemo(() => {
    return [
      {
        title: strings().name,
        isEdit: true,
        align: "middle",
        value: `${userProfile.firstName} ${userProfile.lastName}`,
        component: <FieldName />,
      },
      {
        title: strings().email_address,
        isEdit: false,
        align: "middle",
        value: userProfile.email,
        component: null,
      },
      {
        title: strings().phone_number,
        isEdit: true,
        align: "middle",
        value: userProfile.phoneNumber,
        placeholder: strings().enter_your_phone_number,
        component: <FieldPhoneNumber />,
      },
      {
        title: strings().date_of_birth,
        placeholder: strings().date_of_birth,
        isEdit: true,
        value: userProfile.dob ? dayjs(userProfile.dob).format('YYYY/MM/DD') : null,
        component: <FieldDateOfBirth />,
      },
      {
        title: strings().nationality,
        placeholder: strings().select_the_countryregion_youre_from,
        isEdit: true,
        align: "middle",
        value: formatNationality(userProfile.nationality),
        component: <FieldNationality />,
      },
      {
        title: strings().gender,
        isEdit: true,
        value: showGender(userProfile.gender),
        placeholder: strings().select_your_gender,
        align: "middle",
        component: <FieldGender />,
      },
      {
        title: strings().address,
        isEdit: true,
        value: addressFormatToStr(userProfile),
        placeholder: strings().add_your_address,
        align: "top",
        component: <FieldAddress />,
      },
    ]
  }, [userProfile]);

  return (
    <div className="wrapper-personal-detail">
      <Form form={form} labelCol={{ span: 24 }} onFinish={handleUpdateProfileUser}>
        <div className="header-title">
          <h1>{strings().personal_detail}</h1>
          {isMobile && <Divider />}
        </div>
        <Fragment>
          {initFields.map((item, index) => (
            <div key={index}>
              <Row justify="space-between" align={item.align as any} gutter={[10, 10]} >
                <Col xxl={5} xl={5} lg={5} md={5} sm={24} xs={24}>
                  <span className="title">{item.title}</span>
                </Col>
                <Col xxl={19} xl={19} lg={19} md={19} sm={24} xs={24}>
                  <Row
                    justify="space-between"
                    align={item.align as any}
                    gutter={{ xl: 10, sm: 0, xs: 0 }}
                  >
                    <Col span={(indexField === index) && isMobile ? 24 : 22}>
                      {indexField === index ? (
                        <Fragment>{item.component}</Fragment>
                      ) : item.value ? (
                        <span className="title">{item.value}</span>
                      ) : (
                        <span className="not-active">{item.placeholder}</span>
                      )}
                    </Col>
                    <Col span={(indexField === index) && isMobile ? 12 : 2}>
                      <Space className={indexField === index && isMobile ? 'btn-small' : ''}>
                        {item.isEdit && (
                          <Button type="link" className="btn-edit" onClick={() => { handleClickShowForm(index) }}>
                            {indexField === index
                              ? strings().cancel
                              : item.value ? strings().edit : strings().add
                            }
                          </Button>
                        )}
                        {indexField === index && isMobile && (
                          <FButton className="primary-1" htmlType="submit">
                            {strings().save}
                          </FButton>
                        )}
                      </Space>
                    </Col>
                  </Row>
                </Col>
                {(indexField === index) && !isMobile ? (
                  <Col span={24} className="btn-submit">
                    <Row justify="end">
                      <Col span={3}>
                        <Form.Item>
                          <FButton className="primary-1" htmlType="submit">
                            {strings().save}
                          </FButton>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                ) : null}
              </Row>
              <Divider />
            </div>
          ))}
        </Fragment>
      </Form>
    </div>
  );
}
