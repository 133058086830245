import { selectUserInfo, useAppSelector } from "~/redux";
import AuthHeader from "./components/AuthHeader";
import UserInfo from "./components/UserInfo";


const FMenuDesktop = () => {
  const userProfile = useAppSelector(selectUserInfo);

  return (
    <div className="f-menu-desktop">
      {(userProfile && userProfile?.id) ? <UserInfo /> : <AuthHeader />}
    </div>
  )
};

export default FMenuDesktop;