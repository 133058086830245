import { Button, Col, Row, Space } from "antd";
import { Outlet } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

import strings from "~/assets/strings";
import NavLinkIsProfile from "~/components/NavLink/NavLinkIsProfile";
import useResponsive from "~/hooks/useResponsive";
import FooterPage from "~/pages/Footer";
import { HeaderSmall } from "~/pages/Header";

export function ProfileLayout() {
  const { isMobile } = useResponsive();

  return (
    <Fragment>
      <HeaderSmall />
      <main className="main-content">
        <div className="container">
          <div className="wrapper-profile-page">
            {isMobile && (
              <div className="f-header-profile-mobile">
                <Space>
                  <Button
                    type="text"
                    className="btn-back-item"
                    icon={<i className="fa-light fa-chevron-left"></i>}
                  >
                    {strings().back}
                  </Button>
                </Space>
              </div>
            )}
            <div className={`sidebar-content ${isMobile ? 'active-mobile-side' : ''}`}>
              <Row gutter={[16, 0]}>
                {!isMobile && (
                  <Col span={6} className="f-menu-personal-detail">
                    <aside className="menu">
                      <NavLinkIsProfile />
                    </aside>
                  </Col>
                )}
                <Col span={isMobile ? 24 : 18}>
                  <Outlet />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </main>
      <FooterPage />
    </Fragment>
  );
}