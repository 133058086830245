import { message } from "antd";
import { ITour, TFilters } from "~/@types";
import { TBaseResponse } from "~/types";
import { objectToQueryString } from "~/utils";
import AxiosInstance from "./networking";

export const getTourDetails = async (id: string) => {
  try {
    const response = await AxiosInstance.get(`/tour/${id}`);
    if (response.data.isSuccess) {
      return response.data.data;
    }
  }
  catch (error) {
    message.error('getTourDetails failed');
  }
}

export async function getTourList(params: TFilters) {
  try {
    // const params = objectToQueryString(query);
    // const params = {};
    const paramsRequest = objectToQueryString({ limit: 10, page: 1, ...params });
    const response = await AxiosInstance.get<TBaseResponse<ITour[]>>(`/tour/filter?${paramsRequest}`);
    return response;
  }
  catch (error) {
    message.error('Can not get tours');
  }
}

export async function addTourWishlist(id: string) {
  try {
    const response = await AxiosInstance.post<TBaseResponse<{},{}>>(`tour/addToWishlist`,{tourId: id});
    return response;
  }
  catch (error) {
    message.error('Can not get tours');
  }
}

export const getInterestingTour = async () => {
  try {
    const response = await AxiosInstance.get(`/tour/interestingTour`);
    if (response.data.isSuccess) {
      return response.data.data;
    }
  } catch (error) {
    message.error('Fetching interesting tour failed');
  }
}
