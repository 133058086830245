import { createSlice } from "@reduxjs/toolkit";
import { ICategory, ILocation } from "~/@types";
import { RootState } from "../store";

type TFilterData = {
  categories: ICategory[];
  locations: ILocation[];
}

const initialState: TFilterData = {
  categories: [],
  locations: []
};

export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setDataOnLoad: (state, action) => {
      state.categories = action.payload.categories;
      state.locations = action.payload.locations;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setLocations: (state, action) => {
      state.locations = action.payload;
    },
  },
});

export const selectFilter = (state: RootState) => state.filter;
export const { setDataOnLoad, setCategories, setLocations } = filterSlice.actions;
export default filterSlice.reducer;
