export const DATE_FORMAT = {
  DATE_FORMAT_YYYYMMdd: 'YYYYMMdd',
  DATE_FORMAT_YYYY_MM_DD: 'YYYY-MM-DD',
  DATE_FORMAT_YYYY_MM_dd: 'YYYY-MM-dd',
  DATE_FORMAT_YYYY_MM_dd_Combined: 'YYYYMMddHHmm',
  DATE_FORMAT_YYYY_MM_dd_HH_mm: 'YYYY-MM-dd HH:mm',
  DATE_FORMAT_DD_MM_YYYY: 'DD-MM-YYYY',
  DATE_FORMAT_DD_MM_YYYY_WITH_DOT: 'DD.MM.YYYY',
  DATE_FORMAT_MM_DD_YYYY_WITH_SLASH: 'MM/DD/YYYY',
  DATE_FORMAT_m_d_YYYY_WITH_SLASH: 'M/d/YYYY',
  DATE_FORMAT_DD_MM_YYYY_WITH_SLASH: 'DD/MM/YYYY',
  DATE_FORMAT_dd_MMM: 'ddMMM',
  DATE_FORMAT_dd_MM_yy: 'ddMMyy',
  DATE_FORMAT_dd_MMM_YYYY: 'ddMMMYYYY',
  DATE_FORMAT_DD_MM_YYYY_COMBINED: 'DDMMYYYY',
  DATE_FORMAT_DD_MM_YYYY_HH_mm_ss: "YYYY-MM-dd'T'HH:mm:ss",
  DATE_FORMAT_DD_MM_YYYY_HH_mm_ss_sz: "YYYY-MM-dd'T'HH:mm:ss.SSS",
  DATE_FORMAT_DD_MM_YYYY_HH_mm: 'dd-MM-YYYY HH:mm',
  DATE_FORMAT_HH_mm: 'HH:mm',
  DATE_FORMAT_YYYY_MM_DDTHH_mm_ssZ: "YYYY-MM-DD'T'HH:mm:ssZ",
  DATE_FORMAT_USAGE_TIME: 'HH:mm',
  DATE_FORMAT_TEST_TIME: "mm's",
  DATE_FORMAT_MM: 'M',
  DATE_FORMAT_DD: 'DD',
  DATE_FORMAT_YYYY: 'YYYY',
  DATE_FORMAT_MM_YYYY: 'MM-YYYY',
  DATE_FORMAT_DD_MM: 'DD/MM',
  DATE_FORMAT_D_M: 'D/M',
  DATE_FORMAT_MM_DD_YYYY: 'MM-DD-YYY',
}