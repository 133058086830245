import { Col, Flex } from "antd";
import dayjs from 'dayjs';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData, useNavigate, useSearchParams } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

import { Banner, FPagination, FilterField, FrmSearch, NotFoundItem, SortControl } from "~/components";
import ItemTour from "~/components/ItemTour";
import RecentlyViewed from "~/components/RecentlyViewed";
import { LINKS, dummy_item } from "~/configs";
import useResponsive from "~/hooks/useResponsive";
import { getTourList } from "~/services";
import { LoaderData, getAllParams, makeDummyData, objectToQueryString, searchParamsToObject } from "~/utils";
import HomeBanner from "../Home/components/HomeBanner";

export async function loaderTourPage({ request }: any) {
  const params = getAllParams(request.url);
  const tours = await getTourList(params);
  return tours;
}

function TourPage() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const params = searchParamsToObject(searchParams);
  const tours = useLoaderData() as LoaderData<typeof loaderTourPage>;
  const [page, setPage] = useState<number>(parseInt(`${searchParams.get("page") || 1}`));
  const navigate = useNavigate();
  const { isMobile } = useResponsive();
  const recently = makeDummyData(6, tours?.data?.data ? tours?.data?.data[0] : dummy_item);

  function handleOnSearch(values: any) {
    const initLocation: any = {};
    const { dateFrom, locationIds } = values;
    if (dateFrom) {
      initLocation['dateFrom'] = `${dayjs(dateFrom).format('YYYY-MM-DD')}T07:00:00.000Z`;
    }
    initLocation['locationIds'] = locationIds;
    navigate(`${LINKS.tour}?${objectToQueryString({ ...initLocation, page: 1 })}`);
  }

  function handleChangePage(page: number) {
    setPage(page);
    const _params = { ...params, page };
    navigate(`${LINKS.tour}?${objectToQueryString(_params)}`);
  }

  function handleFielChange(values: any) {
    setPage(1);
    navigate(`${LINKS.tour}?${objectToQueryString({ ...values, page: 1 })}`);
  };

  function handleOnSortOption(value: string) {
    setPage(1);
    navigate(`${LINKS.tour}?${objectToQueryString({ ...params, type: value, page: 1 })}`);
  }

  useEffect(() => { window.scrollTo({ top: 0, behavior: 'smooth' }) }, []);

  return (
    <Fragment>
      <Banner
        breadcrumb={[
          { label: t('home'), link: LINKS.home, isActive: false },
          { label: t('tour'), link: LINKS.tour, isActive: true }
        ]}
        renderControls={() => <FrmSearch onSearch={handleOnSearch} />}
      />

      <div className="page-container">
        <Flex className="container" style={{ paddingTop: 40, paddingBottom: 40 }} wrap={isMobile ? "wrap" : "nowrap"}>
          <Col md={{ span: 24 }} lg={{ span: 6 }} hidden={isMobile}>
            <FilterField onFieldsChange={handleFielChange} initValues={params as any} />
          </Col>
          <Col md={{ span: 24 }} lg={{ span: 18 }} style={{ paddingLeft: isMobile ? 0 : 16 }}>
            <SortControl radioItems={[
              {
                label: t('most_popular'),
                value: 'most_popular',
              },
              {
                label: t('lowest_price'),
                value: 'price_lowest'
              },
              {
                label: t('highest_price'),
                value: 'price_highest'
              },
              {
                label: t('highest_rate'),
                value: 'rating_highest'
              },
            ]} onChange={handleOnSortOption} result={tours?.data?.totals} />
            {tours?.data?.data?.length ? tours?.data?.data?.map((item, index) => (
              <ItemTour layout={isMobile ? "vertical" : "horizontal"} key={index} data={item} border controls type="tour" />
            )) : <NotFoundItem />}
            <FPagination hideOnSinglePage defaultCurrent={parseInt(`${page}`)} total={tours?.data.totals} onChange={handleChangePage} current={page} />
          </Col>
        </Flex>
        <RecentlyViewed data={recently} />
        <HomeBanner />
      </div>
    </Fragment>
  )
}

export default TourPage;
