import { CredentialResponse, GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { message } from 'antd';
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';
import { LINKS } from '~/configs';
import { setUserInfo, useAppDispatch } from '~/redux';
import { authServices } from '~/services';

export const GoogleLoginButton = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLoginSuccess = async (credentialResponse: CredentialResponse) => {
    const response = await authServices.authWithGoogle(`${credentialResponse.credential}`);
    // You can send the credential to your backend for verification or authentication
    console.log("🚀 ~ handleLoginSuccess ~ response:", response);
    if (response) {
      Cookies.set('accessToken', response.accessToken);
      Cookies.set('refreshToken', response.refreshToken);
      const user: any = await authServices.getMe();
      dispatch(setUserInfo(user));
      navigate(LINKS.home)
    } else {
      message.error('Login error');
    }
  };

  const handleLoginFailure = () => {
    // console.log('Login Failed');
    message.error('Login Failed');
  };

  return (
    <GoogleOAuthProvider clientId={"993637887358-95andio031nq44g7q1e26l0ll13jprhm.apps.googleusercontent.com"}>
      <GoogleLogin
        logo_alignment='left'
        onSuccess={handleLoginSuccess}
        onError={handleLoginFailure}
      />
    </GoogleOAuthProvider>
  );
};