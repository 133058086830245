import {
  // Button,
  // Collapse,
  // CollapseProps,
  Divider,
  Flex
} from "antd";
// import { useRef } from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
import icons from "~/assets/icons";
import strings from "~/assets/strings";
// import BenefitListing from "~/components/BenefitListing";
// import FButton from "~/components/FButton";
// import ItineraryInformationItem from "~/components/ItineraryInformation";
// import useResponsive from "~/hooks/useResponsive";
// import TourFeedback from "./TourFeedback";
// import { FrequentlyAsked } from "./constant";
import "./style.scss";

const AuctionInfo = ({ tourDetails }: any) => {
  // const swiperRef = useRef<any | null>(null);
  // const { isMobile } = useResponsive();
  const { summary } = tourDetails;

  // const contentMore: CollapseProps["items"] = [
  //   {
  //     key: "2",
  //     label: (
  //       <Flex gap={8} align="center">
  //         <span>{strings().show_more}</span>
  //         <img src={icons.arrow_primary_down} />
  //       </Flex>
  //     ),
  //     children: null,
  //   },
  // ];

  return (
    <div className="auction-info">
      <Flex className="text-success h6" gap={4}>
        <img src={icons.calendar_success} />
        <span>{strings().free_cancellation_available}</span>
      </Flex>
      <Flex className="h6" gap={4}>
        <img src={icons.panda_foot} />
        <span>{strings().service_animals_welcome}</span>
      </Flex>
      <p className="content" dangerouslySetInnerHTML={{ __html: summary }} />
      <Divider />
      {/* <p className="h5">{strings().whats_included}</p>
      <BenefitListing
        profits={[
          "Bottle of water per personinthebus",
          "Pick up & Drop off from hotel/stay in Hanoi old Quarter",
          "Al entrance fees",
          "Biking time in the countryside",
          "Mua cave hiking on Dragon Moutain",
          "Hoa Lu ancient capital visiting",
          "English speaking tour guide",
          "Photo hunting time",
          "Local daily life experiecing",
          "Rowing Boat trip in Tam Coc",
          "Lunch with Vietnamese dishes",
        ]}
        nonProfits={[
          " Beverages, Tip & Service charge ",
          " Tip for local rower, tour guide and driver Travel insurance ",
          " Personal Expenses & those not clearly mentioned above ",
          " Pick-up and Drop-off outside Hanoi old quarter",
        ]}
      />
      <Divider /> */}
      {/* <p className="h5">{strings().additional_information}</p>
      <p className="mb-32">
        Infants and small children can ride in a pram or stroller
      </p>
      <p className="mb-32">Service animals allowed</p>
      <p className="mb-32">
        Public transportation options are available nearby
      </p>
      <p className="mb-32">Infants are required to sit on an adult’s lap</p>
      <p className="mb-16">Specialized infant seats are available</p> */}
      {/* <Collapse
        bordered={false}
        expandIcon={() => null}
        items={contentMore}
        className="custom-collapse"
      />
      <Divider style={{ marginTop: 0 }} /> */}
      {/* <div className="mb-16">
        <p className="h5">{strings().itinerary_information}</p>
        <FButton
          icon={<img src={icons.timer} style={{ width: 16, height: 16 }} />}
          className="button-time primary-1 mb-16"
        >
          3 Days 2 Nights
        </FButton>
        <Flex gap={8} vertical>
          <ItineraryInformationItem />
          <ItineraryInformationItem />
        </Flex>
        <Collapse
          bordered={false}
          expandIcon={() => null}
          items={[
            {
              key: "2",
              label: (
                <Flex gap={8} align="center" className="primary-text-1">
                  <span>{strings().show_all_stops}</span>
                  <img src={icons.arrow_primary_down} />
                </Flex>
              ),
              children: null,
            },
          ]}
          className="custom-collapse"
        />
        <Divider style={{ marginTop: 0 }} />
        <p className="h5">{strings().what_guests_loved_most}</p>
        <div className="list-tour-items__content f-swiper-wrapper">
          <Button
            className="f-btn-minus left-arrow center-arrow"
            type="default"
            onClick={() => swiperRef.current.slidePrev()}
            hidden={isMobile}
          >
            <img src={icons.slide_arrow_left} alt="" />
          </Button>
          <Swiper
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            slidesPerView={3}
            loop
            autoplay
            spaceBetween={0}
            breakpoints={{
              0: {
                slidesPerView: 1,
                navigation: false,
              },
              768: {
                slidesPerView: 2,
                navigation: false,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            <SwiperSlide>
              <TourFeedback />
            </SwiperSlide>
            <SwiperSlide>
              <TourFeedback />
            </SwiperSlide>
            <SwiperSlide>
              <TourFeedback />
            </SwiperSlide>
            <SwiperSlide>
              <TourFeedback />
            </SwiperSlide>
          </Swiper>
          <Button
            className="f-btn-minus right-arrow center-arrow"
            type="default"
            onClick={() => swiperRef.current.slideNext()}
            hidden={isMobile}
          >
            <img src={icons.slide_arrow_right} alt="" />
          </Button>
        </div>
      </div>
      <div className="frequently-asked-questions mb-16">
        <Flex justify="space-between" className="mb-16" wrap gap={16}>
          <p className="h5 mb-0">{strings().frequently_asked_questions}</p>
          <Collapse
            bordered={false}
            expandIcon={() => null}
            items={contentMore}
            className="custom-collapse"
          />
        </Flex>
        <div className="collapse-frequently ">
          <Collapse
            bordered={false}
            items={FrequentlyAsked}
            defaultActiveKey={["1"]}
            expandIconPosition="end"
            expandIcon={
              ({ isActive }) => <img src={!isActive ? icons.arrow_down : icons.arrow_up} />
            }
          />
        </div>
      </div> */}
    </div>
  );
};

export default AuctionInfo;
