import dayjs from 'dayjs';
import duration from "dayjs/plugin/duration";
import i18next from "i18next";
import strings from "~/assets/strings";
import { countries } from "~/configs";
import { IProfile } from '~/types';
import { IHistoryWallet } from '~/types/wallet';
import { formatMoney } from './number';
dayjs.extend(duration)

export const currentLanguage = () => {
  return i18next.language;
};

interface Breakpoints {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
  span?: number;
}

interface ColProps {
  span?: number;
}

/**
 * generate responsive props
 * generateResponsiveProps
 * @param props 
 * @returns 
 */
export function generateResponsiveProps(props: Breakpoints): Record<string, ColProps | number> {
  const breakpoints = {
    xs: 480,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600
  };

  const responsiveProps: Record<string, ColProps | number> = {};

  // Iterate over breakpoints to assign values from props
  Object.keys(breakpoints).forEach((key) => {
    if (props[key as keyof Breakpoints]) {
      responsiveProps[key] = { span: props[key as keyof Breakpoints] as number };
    }
  });

  // Add the default span if provided
  if (props.span) {
    responsiveProps['span'] = props.span;
  }

  return responsiveProps;
}

export const showGender = (value: any) => {
  if (value !== null) {
    switch (value) {
      case 0:
        return strings().male;
      case 1:
        return strings().female;
      case 2:
        return strings().other;
    }
  } else {
    return null;
  }
};

export function getNameFromCountryCode(countryCode: string): string {
  if (!countryCode && !countryCode.length) {
    return '';
  }
  const result = countries.filter(c => c.value === countryCode);
  if (result.length > 0) {
    return result[0].label;
  }
  else {
    return countryCode;
  }
}

export const addressFormatToStr = (userInfo: IProfile) => {
  const { address, city, countryCode } = userInfo;
  return `${address ? `${address} -` : ''} ${city ? `${city} -` : ''} ${getNameFromCountryCode(countryCode)}`
}

export const EXPIRED = 'expired';
export const NOT_STARED = 'not-started';

export const countdownFormADay = (timeEnd: string, timeStart: string, cb: Function) => {
  var countDownDate = dayjs(timeEnd);
  var startDate = dayjs(timeStart);
  var now = dayjs();

  if (countDownDate.isBefore(now) || countDownDate.isSame(now, 'second')) {
    return cb(EXPIRED)
  }

  var _countDownDate = new Date(timeEnd).getTime();
  // Update the count down every 1 second
  var x = setInterval(function () {

    // Get today's date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = _countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if (startDate.isAfter(now)) {
      cb(NOT_STARED)
    } else {
      // Display the result in the element with id="demo"
      cb(days + ' ' + strings().days + ' ' + hours + ' ' + strings().hours + ' '
        + minutes + ' ' + strings().mins + ' ' + seconds + ' ' + strings().sec);
    }


    // If the count down is finished, write some text
    if (distance < 0) {
      clearInterval(x);
      cb(EXPIRED)
    }
  }, 1000);

  return x;
}

export function buildQueryString(params: any) {
  return Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');
}

// Helper function to convert object to query params
export const objectToQueryString = (params: Record<string, any>) => {
  const query = new URLSearchParams();

  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value === undefined) {
      return;
    }
    if (Array.isArray(value)) {
      value.forEach((val) => {
        if (val !== undefined) {
          query.append(key, val);
        }
      });
    } else if (typeof value === 'object' && value !== null) {
      Object.keys(value).forEach((nestedKey) => {
        if (value[nestedKey] !== undefined) {
          query.append(`${key}[${nestedKey}]`, value[nestedKey]);
        }
      });
    } else {
      query.append(key, value);
    }
  });

  return query.toString();
};

export const getAllParams = (requestUrl: string): Record<string, string | string[]> => {
  const url = new URL(requestUrl);
  const searchParams = url.searchParams;

  const params: Record<string, string | string[]> = {};
  searchParams.forEach((value, key) => {
    if (key in params) {
      const currentValue = params[key];
      if (Array.isArray(currentValue)) {
        currentValue.push(value);
      } else {
        params[key] = [currentValue, value];
      }
    } else {
      //if value is undefined, remove it
      if (value === 'undefined') {
        return;
      }
      params[key] = value;
    }
  });

  return params;
};

//cover searchParams.entries() to object
export const searchParamsToObject = (searchParams: URLSearchParams) => {
  const params: Record<string, (string | number)[] | string> = {};
  for (let entry of searchParams.entries()) {
    //if value is undefined, remove it
    if (entry[1] === 'undefined') {
      continue;
    }
    //if same key, convert to array
    if (entry[0] in params) {
      const currentValue = params[entry[0]];
      if (Array.isArray(currentValue)) {
        currentValue.push(entry[1]);
      } else {
        params[entry[0]] = [currentValue, entry[1]];
      }
    } else {
      params[entry[0]] = entry[1];
    }
  }
  return params;
};

export const isMaxShowBidder = (bidders: any) => bidders.length > 5;

export const hiddenName = (name: string) => name.slice(0, 4) + '*****'

export const sortByBidAmount = (bidders: any) => bidders.sort((a: any, b: any) => b.bidAmount - a.bidAmount)

export const sliceBidersToFive = (bidders: any) => bidders.slice(0, 5);

export const getBiddersToShow = (bidders: any) => sortByBidAmount(sliceBidersToFive(bidders))

export const AUCTION_STATUS = {
  UPCOMING: 0,
  OPEN: 1,
  ENDED: 3,
}

enum PAYMENT_STATUS {
  Deposit = 'Deposit',
  Return = 'Return',

}

export const statusMoney = (history: IHistoryWallet) => {
  const { transAction, amoutChanged } = history;
  switch (transAction) {
    case PAYMENT_STATUS.Deposit:
      return `+$${formatMoney(amoutChanged || 0)}`

    case PAYMENT_STATUS.Return:
      return `-$${formatMoney(amoutChanged || 0)}`
    default:
      return '';
  }
}

export const walletTransactions = (history: IHistoryWallet) => {
  const { transAction } = history;
  switch (transAction) {
    case PAYMENT_STATUS.Deposit:
      return `add-funds`

    case PAYMENT_STATUS.Return:
      return `deduct-funds`
    default:
      return '';
  }
}

export const makeExerpt = (content: string, length: number = 100) => {
  return content.length > length ? content.slice(0, length) + '...' : content
}