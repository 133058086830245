import { Form, Input, message } from "antd";
import { AnyObject } from "antd/es/_util/type";
import strings from "~/assets/strings";
import { FButton } from "~/components";
import { authServices } from "~/services";
import AuthLayout from "../AuthLayout";

const ForgotPassword: React.FC = () => {
  const handleSubmit = async (data: AnyObject) => {
    const response = await authServices.forgotPassword(data.email);
    if (response.isSuccess) message.success('Please check your email to change your password')
  };

  return (
    <AuthLayout onSubmit={handleSubmit}>
      <div style={{ minHeight: 430 }}>
        <div className="header-title">
          <h3>{strings().forgot_password}</h3>
        </div>
        <Form.Item label={strings().email_address} name="email"
          rules={[{
            required: true,
            message: strings().please_input_your_email
          }]}
        >
          <Input placeholder={strings().fill_out_an_email} />
        </Form.Item>
        <Form.Item>
          <FButton htmlType="submit" className="primary-1">
            {strings().forgot_password}
          </FButton>
        </Form.Item>
      </div>
    </AuthLayout>
  )
};

export default ForgotPassword;