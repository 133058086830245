import { Col, Divider, Flex, Row } from 'antd';
import clsx from 'clsx';
import { useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import PageHeading from '~/components/PageHeading';
import useResponsive from '~/hooks/useResponsive';
import AuctionBidCard from '../AuctionBidCard';
import ConfirmPayment from './ConfirmPayment';
import FormPayment from './FormPayment';
import TicketCard from './TicketCard';

type TPaymentProps = {

}

const Payment = (_props: TPaymentProps) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { isMobile } = useResponsive();
  const {  tourDetails }: any = useLoaderData();
  const {  reviewCounting, rating } = tourDetails;

  return (
    <div className='container'>
      <PageHeading
        rating={rating}
        reviewCounting={reviewCounting}
      />
      <Divider />
      <Row gutter={[16, 16]} className={clsx('payment-form', { 'layout-mobile': isMobile })}>
        <Col xxl={16} xl={14} md={12} sm={24}>
        {isSubmitted ? (
          <ConfirmPayment />
        ) : (
          <FormPayment onDone={() => setIsSubmitted(true)} />
        )}
        </Col>
        <Col flex={1}>
          <Flex vertical gap={8} >
            <AuctionBidCard isShowAction={false} />
            <TicketCard />
          </Flex>
        </Col>
      </Row>
      <Divider className='mt-0' />
    </div>
  );
};


export default Payment;