// import { useLoaderData } from 'react-router-dom';
// import { getAuctionList } from '~/services/auction';

import { Col, Flex } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData, useNavigate, useSearchParams } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import { ITour } from "~/@types";
import { Banner, FPagination, FilterField, SortAuctionControl } from "~/components";
import ItemTour from "~/components/ItemTour";
import { LINKS } from "~/configs";
import useResponsive from "~/hooks/useResponsive";
import { getSearchHome } from "~/services";
import { LoaderData, getAllParams, objectToQueryString, searchParamsToObject } from "~/utils";
import FindDestination from "../Header/FindDestination";

// export const loader = async ({ params }) => {
// //params from the route
// console.log("🚀 ~ params:", params);
// const auctions = await getAuctionList();
// return { auctions };
// }

export async function loaderSearchPage({ request }: any) {
  const params = getAllParams(request.url);
  const data = await getSearchHome(params);
  if (data) {
    return data;
  }

  return {
    data: [] as ITour[],
    pages: 1,
    totals: 0
  };
}

function SearchPage() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const params = searchParamsToObject(searchParams);
  const { data, totals } = useLoaderData() as LoaderData<typeof loaderSearchPage>;
  const [page, setPage] = useState<number>(parseInt(`${searchParams.get("page") || 1}`));
  const navigate = useNavigate();
  const { isMobile } = useResponsive();

  function handleFielChange(values: any) {
      setPage(1);
      //merge filter to search params current
      navigate(`${LINKS.search}?${objectToQueryString({ ...values, ...params, page: 1 })}`);
  }
  function handleOnSearchHome(values: any) {
    setPage(1);
    navigate(`${LINKS.search}?${objectToQueryString({ ...values, page: 1 })}`);
  }

  function handleChangePage(page: number) {
    setPage(page);
    const _params = { ...params, page };
    navigate(`${LINKS.search}?${objectToQueryString(_params)}`);
  }
  return (
    <Fragment>
      <Banner
        renderControls={() => (<div className="destination" style={{ width: '100%' }}>
          <FindDestination onSearch={handleOnSearchHome}  />
        </div>)}
        onlyControls
      />
      <div className="page-container">
        <Flex className="container" style={{ paddingTop: 40, paddingBottom: 40 }} wrap={isMobile ? "wrap" : "nowrap"}>
          <Col md={{ span: 24 }} lg={{ span: 6 }} hidden={isMobile}>
            <FilterField isAuction onFieldsChange={handleFielChange} />
          </Col>
          <Col md={{ span: 24 }} lg={{ span: 18 }} style={{ paddingLeft: isMobile ? 0 : 16 }}>
            <SortAuctionControl sortItems={[
              { label: t('all'), value: 'all' },
              { label: t('auction'), value: 'auction' },
              { label: t('buy_it_now'), value: 'buy-now' }
            ]}
              onChange={() => { }} result={totals} />
            {data.map((item, index) => (
              <ItemTour key={index} data={item} border type="auction" />
            ))}
            <FPagination hideOnSinglePage defaultCurrent={parseInt(`${page}`)} total={totals} onChange={handleChangePage} current={page} />
          </Col>
        </Flex>
      </div >
    </Fragment>
  )
}

export default SearchPage;