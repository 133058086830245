import { Button, Divider, Space } from "antd";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { NavLink as NavLinkRoute } from "react-router-dom";
import { TranslationKeys } from "~/@types";

import { NAVIGATION_DESKTOP } from "~/configs";

const NavLinkIsDesktop = () => {
  const {t} = useTranslation();
  // Remove cookies
  const handleSingOut = () => {
    Cookies.remove('accessToken');
    window.location.reload();
  };

  return (
    <div className="f-nav-menu-link">
      <Space
        direction="vertical"
        split={<Divider type="horizontal" />}
      >
      {NAVIGATION_DESKTOP.map((nav, index) => (
          <div key={index}>
            {nav.isLink ? (
              <NavLinkRoute
                className={({ isActive, isPending }) => {
                  return isPending ? "pending" : isActive ? "active" : ""
                }}
                to={nav.link}
                key={`nav-link-${index}`}
              >
                <Space>
                  <span><img src={nav.icon} alt="" /></span>
                  <span style={{position: "relative", top: -2}}>{t(nav.title as TranslationKeys)}</span>
                </Space>
              </NavLinkRoute>
            ) : (
              <Button
                type="text"
                icon={<img src={nav.icon} alt="" />}
                onClick={handleSingOut}
                className="btn-sign-out"
              >
                <b>{t(nav.title as TranslationKeys)}</b>
              </Button>
            )}
          </div>
        ))}
      </Space>
    </div>
  )
};

export default NavLinkIsDesktop;