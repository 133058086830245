import { Button, Card, Col, Divider, Flex, Form, FormProps, Input, Modal, Row, Space } from "antd";

import dayjs from 'dayjs';
import { useEffect, useState } from "react";
import icons from "~/assets/icons";
import strings from "~/assets/strings";
import { FButton } from "~/components";
import useResponsive from "~/hooks/useResponsive";
import { selectUserInfo, setWallet, useAppDispatch, useAppSelector } from "~/redux";
import { walletServices } from "~/services";
import { IHistoryWallet } from "~/types/wallet";
import { formatDate, formatHourMinuteSecond, formatMoney, statusMoney, walletTransactions } from "~/utils";

type FieldType = {
  money: number;
};

export const MyWallet = () => {
  const { isMobile } = useResponsive();
  const [form] = Form.useForm();
  const { wallet, id: _userId } = useAppSelector(selectUserInfo);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [historyWallet, setHistoryWallet] = useState<IHistoryWallet[]>([]);
  const [typeModal, setTypeModal] = useState<'deposit' | 'withdraw'>('deposit');
  const dispatch = useAppDispatch();
  const { id } = useAppSelector(selectUserInfo);

  const showModal = (type: 'deposit' | 'withdraw' = 'deposit') => {
    setTypeModal(type);
    setIsModalOpen(true);
  };

  const hiddenModal = () => {
    setIsModalOpen(false);
  };

  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    console.log('Success:', values);
    if (typeModal == 'deposit') {
      const res = await walletServices.depositMoney(values.money, id);
      console.log("🚀 ~ constonFinish:FormProps<FieldType>['onFinish']= ~ res:", res)
      dispatch(setWallet(1 * values.money));
    }
    else {
      const res = await walletServices.withDrawMoney(values.money, id);
      console.log("🚀 ~ walletServices.withDrawMoney ~ data:", res);
      dispatch(setWallet(-1 * values.money));
    }
    form.resetFields();
    hiddenModal()
  };

  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    const fetchWalletHistory = async () => {
      const data = await walletServices.getIncludeHistoryByUserId(_userId);
      if (data) setHistoryWallet(data);
    };
    if (_userId) fetchWalletHistory();
  }, [_userId]);

  return (
    <div className="wrapper-my-wallet-page">
      <div className="header-title">
        <h1>{strings().my_wallet}</h1>
      </div>
      <Card>
        <Row justify="space-between" align="middle">
          <Col xxl={18} xl={18} lg={18} md={18} sm={24} xs={24}>
            <Row justify="start" align="middle" gutter={[12, 0]}>
              <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6}>
                <img src={icons.wallet} alt="wallet" />
              </Col>
              <Col xxl={21} xl={21} lg={21} md={19} sm={18} xs={18} className="your-money">
                <Space direction="vertical">
                  <p>{strings().your_balance}</p>
                  <Space align="baseline" size={15}>
                    <img src={icons.money} alt={strings().my_wallet} />
                    <h2>{formatMoney(wallet || 0)}</h2>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col xxl={4} xl={4} lg={4} md={4} sm={24} xs={24}>
            <Row className="group-btn" gutter={[10, 10]}>
              <Col xxl={24} xl={24} lg={24} md={24} sm={12} xs={12} order={isMobile ? 2 : 1}>
                <FButton onClick={() => showModal('deposit')} className="primary-1">{strings().add_money}</FButton>
              </Col>
              <Col xxl={24} xl={24} lg={24} md={24} sm={12} xs={12} order={isMobile ? 1 : 2}>
                <FButton onClick={() => showModal('withdraw')} className="btn-with-draw">{strings().withdraw}</FButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <div className="wrapper-my-wallet-page">
        <Space direction="vertical" size={15} className="header-wallet">
          <h3>{strings().history}</h3>
          <p className="apply-date">{formatDate(dayjs(), 'DD/MM/YYYY')}</p>
        </Space>
        <Divider />
        <div className="contents">
          {historyWallet.map((val, index) => (
            <Row
              key={index}
              justify="space-between"
              className="content-history"
            >
              <Col span={16}>
                <Space align="start">
                  <img src={icons.money_send} alt="money" />
                  <Space direction="vertical" size={8}>
                    <h4 className="sub-title">{val.transTitle}</h4>
                    <p>{val.transContent}</p>
                  </Space>
                </Space>
              </Col>
              <Col span={8}>
                <Flex justify="end">
                  <Space direction="vertical" align="end" size={8}>
                    <h5 className={`sub-title ${walletTransactions(val)}`}>
                      {statusMoney(val)}
                    </h5>
                    <Space className="apply-time">
                      <span>{formatHourMinuteSecond(val.transDateTime)}</span>
                      <span>{formatDate(val.transDateTime, 'DD/MM/YYYY')}</span>
                    </Space>
                  </Space>
                </Flex>
              </Col>
            </Row>
          ))}
        </div>
      </div>
      <Modal title={null} open={isModalOpen} footer={null} onCancel={hiddenModal}>
        <h4 style={{ textAlign: 'center', margin: '16px 0' }}>{typeModal == 'deposit' ? strings().add_money : strings().withdraw}</h4>
        <Form
          name="basic"
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item<FieldType>
            label={strings().amount}
            name="money"
            rules={[{ required: true, message: 'Please input your amount!' }, { min: 1, message: 'Please input number > 0!' }]}
          >
            <Input min={1} type='number' />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
