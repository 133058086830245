import { Col, Divider, Row, Space } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import strings from "~/assets/strings";
import { SortAuctionControl } from "~/components";
import ItemTour from "~/components/ItemTour";
import { LINKS } from "~/configs";
import { getWishListByType } from "~/services";
 
const MyWishList = () => {
  const [type, setType] = useState('1');
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([])

  useEffect(() => {
    getWishListByType({ params: { type } }).then(setDataSource);

    // return () => {
    //   setDataSource([])
    // }
  }, [type]);

  return (
    <div className="wrapper-my-wish-list">
      <div className="container">
        <Space className="group-breadcrumb" align="baseline">
          <span>
            <Link to={LINKS.home}>{strings().home}</Link>
          </span>
          <i className="fa-light fa-angle-right" style={{ color: '#09A4D1' }}></i>
          <Link to={LINKS.myWishList}>{strings().my_wishlist}</Link>
        </Space>
        <div className="header">
          <Row justify="space-between" align="middle">
            <Col span={12}>
              <h1>{strings().my_wishlist}</h1>
            </Col>
            <Col span={12}>
              <SortAuctionControl
                enableSort={false}
                sortItems={[{
                  label: t('auction'),
                  value: '1',
                }, {
                  label: t('tour'),
                  value: '2',
                }]}
                onChange={setType}
                value={type}
              />
            </Col>
          </Row>
        </div>
        <Divider />
        <div className="my-wish-list">
          <Row justify="start" wrap>
            {dataSource.map((item, index) => (
              <Col xl={8} lg={8} md={12} sm={24} xs={24} key={index}>
                <ItemTour layout="vertical" data={item as any} controls={false} type={type === '1' ? 'auction' : 'tour'} />
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </div>
  )
};

export default MyWishList;