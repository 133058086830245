import { Empty } from 'antd';

type TEmptyScreenProps = {
  description: string
}

const EmptyScreen = ({ description }: TEmptyScreenProps) => {
  return (
    <div className='container'>
      <div className='mt-16 mb-16'>
        <div className='wrapper'><Empty description={description} /></div>
      </div>
    </div>
  );
};


export default EmptyScreen;