import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en';
import isBetween from 'dayjs/plugin/isBetween';
import localeData from 'dayjs/plugin/localeData';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { isEmpty } from 'lodash';
import { countries } from '~/configs';
import { DATE_FORMAT } from '~/constants';

dayjs.extend(LocalizedFormat);
dayjs.extend(localeData);
dayjs.extend(isBetween);

export type AppDate = Dayjs;

export class Dates {
  static setLocale(locale: string): void {
    dayjs.locale(locale);
  }

  static getToday(): AppDate {
    return dayjs();
  }

  static getClearDate(): AppDate {
    return this.getToday().hour(0).minute(0).second(0).millisecond(0);
  }

  static getMonths(): string[] {
    return dayjs.months();
  }

  static getDays(): string[] {
    const lng = localStorage.getItem('lng');
    if (lng === 'en')
      return dayjs.weekdays();
    return ['Chủ nhật', 'Thứ hai', 'Thứ ba', 'Thứ tư', 'Thứ năm', 'Thứ sáu', 'Thứ bảy']
  }

  static getMonthsShort(): string[] {
    const lng = localStorage.getItem('lng');
    if (lng === 'en')
      return dayjs.monthsShort();
    return ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12']
  }

  static getDate(date: number | string): AppDate {
    return dayjs(date);
  }

  static format(date: AppDate | string | number, query: string = ''): string {
    if (isEmpty(query)) {
      query = 'YYYY-MM-DD HH:mm:ss';
    }
    if (typeof date === 'string' || typeof date === 'number') {
      return dayjs(date).format(query);
    } else {
      return date.format(query);
    }
  }
}

export function formatDate(date: AppDate | string | number, format: string = 'DD MMM YYYY'): string {
  return dayjs(date).format(format);
}

export function calcDateLeftFromNow(date: string): string {
  const now = Dates.getToday();
  const end = Dates.getDate(date).format(); // Convert end to string using the format method
  const days = Dates.getDate(end).diff(now, 'days');
  const hours = Dates.getDate(end).diff(now, 'hours');
  // const minutes = Dates.getDate(end).diff(now, 'minutes');

  if (days < 0) {
    return '';
  }
  return `${days}d ${hours}h left (${formatDate(end, 'DD MMM YYYY HH:mm')})`;
}

export function makeEndtimeRandom() {
  const random = Math.floor(Math.random() * 20);
  return Date.now() + (86400000 * random);
}

export function formatNationality(code: string) {
  const findNationality = countries.find(({ value }) => value === code);
  if (findNationality) {
    return findNationality.label
  } else {
    return '';
  }
};

export function transformDateOfBirth(date: Date) {
  if (date) {
    const _date = dayjs(date);
    return {
      day: _date.get('date'),
      month: _date.get('month') + 1,
      year: _date.get('year')
    }
  } else {
    return null;
  }
}

export function selectDays() {
  const days = [];
  for (let index = 1; index <= 31; index++) {
    days.push({ label: index, value: index })
  }
  return days;
};

export function selectMonths() {
  const days = [];
  for (let index = 1; index <= 12; index++) {
    days.push({ label: index, value: index })
  }
  return days;
};

export function selectYear() {
  const days = [];
  for (let index = 1952; index <= dayjs().year(); index++) {
    days.push({ label: index, value: index })
  }
  return days;
}

export const formatDateTo_YYYY_MM_DD = (date: Date) => {
  return `${dayjs(date).format(DATE_FORMAT.DATE_FORMAT_YYYY_MM_DD)}T07:00:00.000Z`;
}

export function isOpeningTime(time: string) {
  const now = Dates.getToday();
  const start = Dates.getDate(time);
  return now.isAfter(start);
}

export function isEndBids(time: string) {
  const now = Dates.getToday();
  const end = Dates.getDate(time);
  return now.isAfter(end);
}