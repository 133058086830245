import { LoadingOutlined } from '@ant-design/icons';
import { Col, Flex, Spin } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ITour } from "~/@types";
import strings from "~/assets/strings";
import { FPagination, NotFoundItem, SortAuctionControl } from "~/components";
import ItemTour from "~/components/ItemTour";
import useResponsive from "~/hooks/useResponsive";
import { selectUserInfo, useAppSelector } from "~/redux";
import { userServices } from "~/services";

export function History() {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const userProfile = useAppSelector(selectUserInfo);
  const [loading, setLoading] = useState<boolean>(true);
  const [tourHistory, setTourHistory] = useState<ITour[]>([]);
  const [currentPageData, setCurrentPageData] = useState<ITour[]>([]);
  const [pageSize, _setPageSize] = useState<number>(5);
  const [cateValue, setCateValue] = useState<string>('all');
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    const fetchHistory = async (type: number) => {
      try {
        setLoading(true);
        const response = await userServices.getHistory(type);
        if (response.isSuccess) setTourHistory(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("🚀 ~ fetchHistory ~ error:", error);
      }
    };

    if (userProfile.type) fetchHistory(userProfile.type);
  }, [userProfile]);

  useEffect(() => {
    let filterHistory = cateValue === 'all'
      ? tourHistory
      : tourHistory.filter(({ auctions }) => auctions[0].type === parseInt(cateValue))

    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setCurrentPageData(filterHistory.slice(startIndex, endIndex));
  }, [page, pageSize, tourHistory, cateValue]);

  return (
    <Flex
      className="container"
      style={{ paddingTop: 10, paddingBottom: 40 }}
      wrap={isMobile ? "wrap" : "nowrap"}
    >
      <Col span={24}>
        <h1 style={{ fontSize: 40 }}>{strings().history}</h1>
        {loading
          ? <Flex justify="center"><Spin indicator={<LoadingOutlined spin />} size="large" /></Flex>
          : currentPageData.length > 0
            ? (
              <Fragment>
                <SortAuctionControl
                  sortItems={[
                    { label: t('all'), value: 'all' },
                    { label: t('auction'), value: '0' },
                    { label: t('buy_it_now'), value: '1' }
                  ]}
                  value={cateValue}
                  onChange={(value: any) => setCateValue(value)}
                />
                {currentPageData.map((item, index) => (
                  <ItemTour
                    layout={isMobile ? "vertical" : "horizontal"}
                    key={index}
                    data={item}
                    border
                  />
                ))}
                <FPagination
                  hideOnSinglePage
                  defaultCurrent={page}
                  total={tourHistory.length}
                  onChange={(val: number) => setPage(val)}
                  current={page}
                  pageSize={pageSize}
                />
              </Fragment>
            )
            : <NotFoundItem />
        }
      </Col>
    </Flex>
  );
}
