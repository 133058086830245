import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { LINKS } from "~/configs";
import './styles.scss';

function NotFound() {
  const { t } = useTranslation();
  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <div></div>
          <h1>404</h1>
        </div>
        <h2>{t('page_not_found')}</h2>
        <p>{t('the_page_you_are_looking_for_might_have_been_removed_had_its_name_changed_or_is_temporarily_unavailable')}</p>
        <Link to={LINKS.home}>{t('home')}</Link>
      </div>
    </div>
  )
}

export default NotFound