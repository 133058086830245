import { Col, Form, Input, Row, Select } from "antd";
import strings from "~/assets/strings";
import { countries } from "~/configs";
import { filterOptionSelect } from "~/utils";

const FieldAddress = () => {
  return (
    <Row gutter={[10, 0]} align="bottom" >
      <Col span={24}>
        <Form.Item
          name="countryCode"
          label={strings().countryregion}
          rules={[{
            required: true,
            message: strings().please_enter_required_information
          }]}
        >
          <Select
            showSearch
            filterOption={filterOptionSelect}
            options={countries}
            placeholder={strings().countryregion}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="address"
          label={strings().address}
          rules={[{
            required: true,
            message: strings().please_enter_required_information
          }]}
        >
          <Input placeholder={strings().your_street_name_and_houseapartment_number} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Row justify="space-between" gutter={[10, 10]}>
          <Col span={12}>
            <Form.Item
              name="city"
              label={strings().towncity}
              rules={[{
                required: true,
                message: strings().please_enter_required_information
              }]}
            >
              <Input placeholder={strings().enter_your_towncity} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="zipCode"
              label={strings().zip_code}
              rules={[{
                required: true,
                message: strings().please_enter_required_information
              }]}
            >
              <Input placeholder={strings().enter_your_zip_code} />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  )
};

export default FieldAddress;

