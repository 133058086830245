import { Form, Select } from "antd";
import strings from "~/assets/strings";

const FieldGender = () => {
  return (
    <Form.Item
      name='gender'
      label={strings().gender}
      rules={[{
        required: true,
        message: strings().please_enter_required_information
      }]}
    >
      <Select
        options={[
          { label: strings().male, value: 0 },
          { label: strings().female, value: 1 },
          { label: strings().other, value: 2 },
        ]}
        placeholder={strings().select_your_gender}
      />
    </Form.Item>
  )
};

export default FieldGender;

