import { Col, Row } from "antd";
import { AnyObject } from "antd/es/_util/type";
import { useState } from "react";

import { userServices } from "~/services";
import Failed from "./Failed";
import RegisterEmail from "./RegisterEmail";
import RegisterPassword from "./RegisterPassword";
import Successful from "./Successful";


const RegisterPage: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<AnyObject>({ status: false, isShow: false });

  const handleSubmitEmail = async (data: AnyObject) => {
    const response = await userServices.registerEmail(data.email);
    if (response) setEmail(data.email)
  };

  const handleSubmitPassword = async (data: AnyObject) => {
    const payload = { email, password: data.password };
    const response = await userServices.registerUser(payload);
    if (response?.isSuccess) {
      setIsSuccess({ status: true, isShow: true })
    } else {
      setIsSuccess({ status: false, isShow: true })
    }
  };

  return (
    <>
      {isSuccess.isShow && (
        <Row
          justify="center"
          align="middle"
          className="wrapper-register-status"
        >
          <Col xxl={8} xl={9} lg={10} md={13}>
            {isSuccess.status ? <Successful /> : <Failed />}
          </Col>
        </Row>
      )}
      { !email && <RegisterEmail onSubmitEmail={handleSubmitEmail} />}
      {
        (!isSuccess.isShow && email) &&
        <RegisterPassword onSubmitPassword={handleSubmitPassword} />
      }
    </>
  )
};

export default RegisterPage;