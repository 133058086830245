import { Outlet } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import FooterPage from "~/pages/Footer";
import { HeaderBanner } from "~/pages/Header";

export function BannerLayout() {
  return (
    <Fragment>
      <main className="content-with-banner">
        <HeaderBanner />
        <Outlet />
      </main>
      <FooterPage />
    </Fragment >
  );
}