import { Divider, Result, Space } from "antd";
import { Link } from "react-router-dom";
import strings from "~/assets/strings";

import { FButton } from "~/components";
import { LINKS } from "~/configs";
import useResponsive from "~/hooks/useResponsive";

const Failed = () => {
  const { isMobile } = useResponsive();

  return (
    <>
      <h1 className="title error">{strings().failed}</h1>
      {isMobile && <Divider />}
      <Result
        status="error"
        title={strings().were_sorry}
        subTitle={
          <>
            <Space direction="vertical" size={30}>
              <p>{strings().but_there_was_an_issue_with_your_registration_please_doublecheck_the_information_you_provided_and_ensure_that_all_fields_are_filled_out_correctly}</p>
              <p>{strings().if_you_continue_to_experience_issues_please_contact_our_support_team_for_assistance}</p>
              <h3>{strings().thank_you_for_your_understanding}</h3>
            </Space>
          </>
        }
        extra={
          <FButton className="primary-1 is-nav-link" type="link">
            <Link to={LINKS.signIn}>{strings().retry}</Link>
          </FButton>
        }
      />
    </>
  )
};

export default Failed;