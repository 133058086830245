import { message } from "antd";
import { ITour } from "~/@types";
import { objectToQueryString } from "~/utils";
import AxiosInstance from "./networking";

type TSearch = {
  limit?: string;
  page?: string;
  destination?: string;
  dateFrom?: string;
  numOfAdults?: string;
  numOfChild?: string;
  numOfRoom?: string;
}

export async function getSearchHome(params: TSearch) {
  try {
    const paramsRequest = objectToQueryString({ limit: 10, page: 1, ...params });
    const response = await AxiosInstance.get(`/search/home?${paramsRequest}`);

    if (response.data) {
      const { isSuccess } = response.data;
      if (isSuccess) {
        return response.data as {
          data: ITour[],
          totals: number,
          pages: number
        }
      }
    }
  }
  catch (error) {
    message.error('Can not get tours');
    throw new Error('search page error')
  }
}
