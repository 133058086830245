import { Button } from "antd";
import { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import icons from "~/assets/icons";
import ItemTour from "~/components/ItemTour";
import useResponsive from "~/hooks/useResponsive";

type TProps = {
  title?: string | null | undefined;
  data: Record<string, string>[];
  type?: "auction" | "tour"
}

function ListTours(props: TProps) {
  const { title = null, data, type = 'auction' } = props;
  const swiperRef = useRef<any | null>(null);
  const { isMobile } = useResponsive();
  if(!data || data.length === 0) return null;

  return (
    <div className="list-tour-items banner-offer-carousel">
      <div className="container">
        {title && <h2 className="home-thread__title">{title}</h2>}
        <div className="list-tour-items__content f-swiper-wrapper">
          <Button className="f-btn-minus left-arrow" type="default" onClick={() => swiperRef.current.slidePrev()} hidden={isMobile}>
            <img src={icons.slide_arrow_left} alt="" />
          </Button>
          <Swiper
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            slidesPerView={3}
            loop
            autoplay
            spaceBetween={0}
            breakpoints={{
              0: {
                slidesPerView: 1.4,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            {data.map((item, index) => (
              <SwiperSlide key={index}>
                <ItemTour layout="vertical" data={item as any} controls={false} type={type} />
              </SwiperSlide>
            ))}
          </Swiper>
          <Button className="f-btn-minus right-arrow" type="default" onClick={() => swiperRef.current.slideNext()} hidden={isMobile}>
            <img src={icons.slide_arrow_right} alt="" />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ListTours