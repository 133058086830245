export enum NOTIFICATION_STATUS {
  AUCTION_PREPARE_START = 600,
  AUCTION_PREPARE_END = 601,
  AUCTION_END = 603,
  USER_AUCTION_SUCCESSFULLY = 604,
  USER_BID_HIGHER = 605,
  USER_AUCTION_FAILED = 606
}

export type TNotification = {
  type: NOTIFICATION_STATUS,
  id: string,
  isRead: boolean,
  createdAt: string
}

export type TNotificationList = {
  data: TNotification[],
  pages: number,
  totals: number
}