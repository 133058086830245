import { createBrowserRouter } from "react-router-dom";
import ErrorPage from "~/pages/Error";
import HomePage, { loaderHomePage } from "~/pages/Home";
// import HomePage from "~/pages/Home";
import AuctionPage, { loaderAuctionPage } from "~/pages/Auction";
import RegisterPage from "~/pages/Auth/Register/RegisterPage";
import SignInPage from "~/pages/Auth/SignIn";
// import FooterPage from "~/pages/Footer";
import Payment from "~/components/Auction/Payment";
import PlaceYourBid from "~/components/Auction/PlaceYourBid";
import NotificationProvider from "~/components/NotificationProvider";
import { websocket } from "~/core/websocket";
import { isAuthenticated } from "~/hooks";
import { BannerLayout, HomeLayout, ProfileLayout, ProtectedRoute, Root, RootAuth } from "~/layout";
import { WithLoading } from "~/layout/WithLoading";
import AuctionDetail from "~/pages/AuctionDetail";
import ActiveUserPage from "~/pages/Auth/Active";
import ChangePassword from "~/pages/Auth/ChangePassword";
import ForgotPassword from "~/pages/Auth/ForgotPassword";
import ResetPassword from "~/pages/Auth/ResetPassword";
import BidderListPage from "~/pages/BidderList";
import BlogPage from "~/pages/Blog";
import BlogDetails from "~/pages/BlogDetails";
import CartPage from "~/pages/Cart";
import MyWishList from "~/pages/MyWishList";
import NotFound from "~/pages/NotFound";
import { History, MyWallet, ProfilePersonal, Settings } from "~/pages/Profile";
import SearchPage, { loaderSearchPage } from "~/pages/Search";
import TourPage, { loaderTourPage } from "~/pages/Tour";
import AuctionsInTour from "~/pages/auctionsInTour";
import { getAuctionByTourId, getBlogDetails } from "~/services";
import LINKS from "./links";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <NotificationProvider>
        <WithLoading />
      </NotificationProvider>
    ),
    loader: async () => {
      try {
        const data = await websocket.asyncOpen()
        console.info(data);
        return data;
      } catch (error) {
        console.error('connect ws error', error)
        return error;
      }
    },
    children: [
      {
        path: "/",
        element: <HomeLayout />,
        errorElement: <ErrorPage />,
        children: [
          { index: true, element: <HomePage />, loader: loaderHomePage },
        ],
      },
      {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
          { path: LINKS.auctionDetails, element: <AuctionDetail type="auction" />, loader: async ({ params }) => await getAuctionByTourId(params) },
          { path: LINKS.tourDetails, loader: async ({ params }) => await getAuctionByTourId(params), element: <AuctionDetail type="tour" /> },
          { path: LINKS.auctionBidder, element: <BidderListPage /> },
          {
            element:  <ProtectedRoute isAuthenticated={isAuthenticated()} />,
            children: [
              {
                path: LINKS.auctionBuynow, element: <CartPage /> 
              }
            ]
          },
          { path: LINKS.blogDetails, element: <BlogDetails />, loader: async ({ params }) => await getBlogDetails(params) },
          { path: LINKS.myWishList, element: <MyWishList /> },
          {
            element:  <ProtectedRoute isAuthenticated={isAuthenticated()} />,
            children: [
              {
                path: LINKS.JOIN_BIDS, loader: async ({ params }) => await getAuctionByTourId(params), element: <PlaceYourBid /> 
              }
            ]
          },
          {
            element:  <ProtectedRoute isAuthenticated={isAuthenticated()} />,
            children: [
              {
                path: LINKS.payment, loader: async ({ params }) => await getAuctionByTourId(params), element: <Payment /> 
              }
            ]
          },
          { path: LINKS.auctionsInTour, loader: async ({ params }) => await getAuctionByTourId(params), element: <AuctionsInTour /> },
        ],
      },
      {
        path: "/",
        element: <BannerLayout />,
        errorElement: <ErrorPage />,
        children: [
          { path: LINKS.auction, element: <AuctionPage />, loader: loaderAuctionPage },
          { path: LINKS.tour, element: <TourPage />, loader: loaderTourPage },
          { path: LINKS.search, element: <SearchPage />, loader: loaderSearchPage },
          { path: LINKS.blog, element: <BlogPage /> },
        ],
      },
      {
        path: "/auth",
        element: <RootAuth />,
        errorElement: <ErrorPage />,
        children: [
          { path: "sign-in", element: <SignInPage /> },
          { path: "register", element: <RegisterPage /> },
          { path: "forgot-password", element: <ForgotPassword /> },
          { path: "reset-password", element: <ResetPassword /> },
          { path: "active-user", element: <ActiveUserPage /> },
        ],
      },
      {
        element: <ProtectedRoute isAuthenticated={isAuthenticated()} />,
        children: [
          {
            path: "/profile",
            element: <ProfileLayout />,
            errorElement: <ErrorPage />,
            children: [
              { path: "personal", element: <ProfilePersonal /> },
              { path: "change-password", element: <ChangePassword /> },
              { path: "history", element: <History /> },
              { path: "wallet", element: <MyWallet /> },
              { path: "settings", element: <Settings /> },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

export default router;
