export function makeDummyData(count: number, item: Record<string,any> | Function): any[] {
  const dummyData = [];
  for (let i = 0; i < count; i++) {
    if (typeof item === "function") {
      dummyData.push(item());
    }
    else{
      dummyData.push(item);
    }
  }
  return dummyData;
}

export function makeRandomFromRange(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function makeRandomItemInArray(arr: any[]): any {
  return arr[makeRandomFromRange(0, arr.length - 1)];
}