import { message } from 'antd';
import { AnyObject } from 'antd/es/_util/type';

import AxiosInstance from './networking';

const updateProfile = async (data: AnyObject) => {
  try {
    const response = await AxiosInstance.put('/users/updateProfile', data);
    message.success('Update profile success');
    return response.data;
  }
  catch (error) {
    message.error('Update profile failed');
  }
}

const registerEmail = async (email: string) => {
  try {
    const response = await AxiosInstance.get(`/users/duplicateEmail?email=${email}`);
    message.success('Register email success');
    return response.data;
  } catch (error) {
    message.error('Register email failed');
  }
}

const registerUser = async (data: AnyObject) => {
  try {
    const response = await AxiosInstance.post(`/users/registerUser`, data);
    message.success('Register account success');
    return response.data;
  } catch (error) {
    message.error('Register account failed');
  }
};

const getHistory = async (type: number) => {
  try {
    const response = await AxiosInstance.get(`/users/history?type=${type}`);
    message.success('Get history success');
    return response.data;
  } catch (error) {
    message.error('Get history failed');
  }
};

const wishlistOfTour = async () => {
  try {
    const response = await AxiosInstance.get(`/users/wishlistOfTour`);
    return response.data;
  } catch (error) {
    message.error('Get history failed');
  }
};

export const getWishListByType = async (options?: any) => {

  let axiosOptions = {}

  if (options) {
    axiosOptions = options;
  }

  try {
    const response = await AxiosInstance.get(`/users/wishlist`, axiosOptions);
    if (response?.data?.isSuccess) {
      return response.data.data;
    }
  } catch (error) {
    message.error('Get history failed');
  }
};

const wishlistOfAuction = async () => {
  try {
    const response = await AxiosInstance.get(`/users/wishlistOfAuction`);
    return response.data;
  } catch (error) {
    message.error('Get history failed');
  }
};

const deleteUser = async () => {
  try {
    const response = await AxiosInstance.delete(`/users/deleteUser`);
    message.success('Delete account success');
    return response.data;
  } catch (error) {
    message.error('Delete account failed');
  }
};

export const userServices = {
  updateProfile,
  registerEmail,
  registerUser,
  deleteUser,
  getHistory,
  wishlistOfTour,
  wishlistOfAuction,
  history
}