import { Avatar, Button, Col, Divider, Flex, Row, Space } from "antd";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Link, NavLink as NavLinkRoute, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { TranslationKeys } from "~/@types";
import strings from "~/assets/strings";
import { LINKS, LIST_MENUS, NAVIGATION_MOBILE } from "~/configs";
import { selectUserInfo, useAppSelector } from "~/redux";
import FButton from "../FButton";
import NavMobileNotification from "../NotificationsAll/NavMobileNotification";
import avatar from "/images/profile.png";


const NavLinkIsMobile = () => {
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const { t } = useTranslation();
  const userInfo = useAppSelector(selectUserInfo);
  const [openNotification, setIOpenNotification] = useState<boolean>(false);
  const navigation = useNavigate();
  useEffect(() => {
    setIsLogin(!!userInfo.id);
  },[userInfo.id, navigation]);

  if (openNotification) {
    return (
      <NavMobileNotification
        isOpen={openNotification}
        setIsOpen={setIOpenNotification}
      />
    )
  };

  // Remove cookies
  const handleSignOut = () => {
    Cookies.remove('accessToken');
    window.location.reload();
  };

  return (
    <div className="f-nav-menu-link">
      {isLogin
        ? (
          <Space
            direction="vertical"
            split={<Divider type="horizontal" />}
            className={`mobile-user-info ${userInfo && userInfo.id ? "mobile-dark" : ""}`}
          >
            <Row justify="space-between" align="middle">
              <Col flex="auto">
                <Link to={LINKS.profilePersonal}>
                  <Space align="center">
                    <Avatar src={avatar} shape="circle" size="default" />
                    <h3>{`${userInfo.firstName} ${userInfo.lastName}`}</h3>
                  </Space>
                </Link>
              </Col>
              <Col flex="90px">
                <FButton
                  type="link"
                  iconPosition="end"
                  icon={<i className="fa-regular fa-chevron-right"></i>}
                  onClick={handleSignOut}
                >
                  {strings().sign_out}
                </FButton>
              </Col>
            </Row>
            {NAVIGATION_MOBILE.map((nav, index) => (
              <div key={index}>
                {nav.isLink ? (
                  <NavLinkRoute
                    className={({ isActive, isPending }) => {
                      return isPending ? "pending" : isActive ? "active" : ""
                    }}
                    to={nav.link}
                    key={`nav-link-${index}`}
                  >
                    <Space>
                      <span><img src={nav.icon} alt="" /></span>
                      <span>{t(nav.title as TranslationKeys)}</span>
                    </Space>
                  </NavLinkRoute>
                ) : (
                  <Button
                    type="text"
                    icon={<img src={nav.icon} alt="" />}
                    onClick={() => setIOpenNotification(true)}
                  >
                    <b>{t(nav.title as TranslationKeys)}</b>
                  </Button>
                )}
              </div>
            ))}
          </Space>
        ) : (
          <Flex gap={10}>
            <FButton className="is-nav-link btn-register">
              <Link to={LINKS.register}>{strings().register}</Link>
            </FButton>
            <FButton className="primary-1">
              <Link to={LINKS.signIn}>{strings().sign_in}</Link>
            </FButton>
          </Flex>
        )}
      <div>
        <Divider style={{ margin: "20px 0 15px 0" }} />
        <Space direction="vertical" size={20} className="nav-link-item">
          {LIST_MENUS.map(({ key, name }, index) => (
            <Link to={key} key={`navlink-${index}`}>
              {name}
            </Link>
          ))}
        </Space>
      </div>
    </div>
  )
};

export default NavLinkIsMobile;