import Cookies from "js-cookie";

// Function to get the access token from cookies
export const getAccessToken = () => {
  return Cookies.get("accessToken");
};

// Function to check if the user is authenticated
export const isAuthenticated = () => {
  return !!getAccessToken();
};

export const useAuth = () => {
  return {
    isAuthenticated: isAuthenticated(),
  };
}
