import { Col, Form, Input, Row } from "antd";
import strings from "~/assets/strings";

const FieldName = () => {
  return (
    <Row gutter={[10, 10]}>
      <Col span={12}>
        <Form.Item
          name="firstName"
          label={strings().first_name}
          rules={[{
            required: true,
            message: strings().please_enter_required_information
          }]}
        >
          <Input placeholder={strings().first_name} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="lastName"
          label={strings().last_name}
          rules={[{
            required: true,
            message: strings().please_enter_required_information
          }]}
        >
          <Input placeholder={strings().last_name} />
        </Form.Item>
      </Col>
    </Row>
  )
};

export default FieldName;

