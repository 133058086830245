import { Divider, Form, Input } from "antd";
import { AnyObject } from "antd/es/_util/type";

import strings from "~/assets/strings";
import { FButton } from "~/components";
import useResponsive from "~/hooks/useResponsive";
import { IRegisterPassword } from "~/types";
import AuthLayout from "../AuthLayout";
import Privacy from "../Privacy";

const RegisterPassword: React.FC<IRegisterPassword> = (props) => {
  const { onSubmitPassword } = props;
  const { isMobile } = useResponsive();
  const handleSubmit = (data: AnyObject) => onSubmitPassword(data);

  return (
    <AuthLayout onSubmit={handleSubmit}>
      <div className="header-title">
        <h3>{strings().create_password}</h3>
        <p>{strings().use_a_minimum_of_10_characters_including_uppercase_letters_lowercase_letters_and_numbers}</p>
        {isMobile && <Divider />}
      </div>
      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: `${strings().please_input_your} ${strings().password}!`,
          },
          {
            min: 10,
            message: strings().password_must_be_at_least_10_characters_long,
          },
          {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{10,}$/,
            message: strings().password_must_contain_at_least_one_uppercase_letter_one_lowercase_letter_and_one_number,
          },
        ]}
      >
        <Input.Password placeholder="Enter a password" />
      </Form.Item>
      <Form.Item
        name="confirm"
        label={strings().confirm_password}
        dependencies={['password']}
        rules={[
          {
            required: true,
            message: strings().please_confirm_your_password,
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The new password that you entered do not match!'));
            },
          }),
        ]}
      >
        <Input.Password placeholder="Config your password" />
      </Form.Item>
      <Form.Item>
        <FButton htmlType="submit" className="primary-1">
          {strings().create_an_account}
        </FButton>
      </Form.Item>
      <div className="footer-title">
        <Privacy />
      </div>
    </AuthLayout>
  );
};

export default RegisterPassword;