import { Button, Col, DatePicker, Form, FormProps, Row, Select, Space } from "antd";
import { useEffect, useState } from "react";

import icons from "~/assets/icons";
import strings from "~/assets/strings";
import { useAppSelector } from "~/redux";
import { filterOptionSelect } from "~/utils";
import './styles.scss';

type TProps = {
  onSearch: (values: FieldType) => void;
}

type FieldType = {
  locationIds: string;
  dateFrom: string;
};

export function FrmSearch(props: TProps) {
  const { onSearch } = props;
  const { locations } = useAppSelector(state => state.filter);
  const [options, setoptions] = useState<{ value: number, label: string }[]>();

  useEffect(() => {
    setoptions(locations.map(({ id, name }) => ({ value: id, label: name })));
  }, [locations]);

  const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
    onSearch(values);
  };

  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      className="frm-search frm-search-wrap"
    >
      <Row justify="space-between" gutter={[10, 10]}>
        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
          <Form.Item<FieldType> name="locationIds" className="frm-field__location">
            <Select
              placeholder="Select location"
              showSearch={true}
              allowClear
              suffixIcon={<img src={icons.location_search} alt="location" />}
              options={options as any}
              optionRender={({ label, key }) => (
                <Space key={key}>
                  <img width={20} height={20} src={icons.location_search} alt="location" />
                  {label}
                </Space>
              )}
              filterOption={filterOptionSelect}
            />
          </Form.Item>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
          <Form.Item<FieldType> name="dateFrom" className="frm-field__date">
            <DatePicker className="frm-field__date__input" suffixIcon={<img src={icons.calendar_active} />} allowClear={false} />
          </Form.Item>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} sm={24} xs={24}>
          <Form.Item>
            <Button block type="primary" htmlType="submit" className="f-button primary-1">
              {strings().search}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
