import { Button, Col, Divider, Flex, Row, Space } from "antd";
import Cookies from "js-cookie";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import icons from "~/assets/icons";
import strings from "~/assets/strings";
import { FButton } from "~/components";
import FModal from "~/components/FModal";
import { LINKS } from "~/configs";
import useResponsive from "~/hooks/useResponsive";
import { userServices } from "~/services";

const FieldDeleteAccount = () => {
  const { isMobile } = useResponsive();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const handleDeleteUser = async () => {
    const response = await userServices.deleteUser();
    if (response.isSuccess) {
      setTimeout(() => {
        Cookies.remove('accessToken');
        navigate(LINKS.home)
        window.location.reload();
      }, 1000)
    }
  }

  return (
    <div>
      <Row justify="space-between" gutter={{ xl: 10, sm: 0, xs: 0 }}>
        <Col span={isMobile ? 20 : 22}>
          <span className="title">{strings().delete_account}</span>
        </Col>
        <Col span={isMobile ? 3 : 2}>
          <Button type="link" className="btn-edit"
            onClick={() => setOpen(true)}
          >
            {strings().delete}
          </Button>
        </Col>
      </Row>
      <Divider />
      <FModal
        title={strings().account_deletion_confirmation}
        rootClassName="account-delete-confirmation"
        open={open}
        onCancel={() => setOpen(false)}
        onClose={() => setOpen(false)}
      >
        <Flex justify="center">
          <Space direction="vertical" size={isMobile ? 30 : 66} align="center" style={{ width: 500 }}>
            <img width={84} height={75} src={icons.Warning} alt="warning" />
            <p>
              {strings().are_you_sure_you_want_to_delete_your_account_this_action_is_irreversible_please_confirm_to_proceed}
            </p>
          </Space>
        </Flex>
        <Row
          justify="space-between"
          gutter={[15, 15]}
          className="btn-confirms"
          style={{ marginTop: isMobile ? 10 : 50 }}
        >
          <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24} order={isMobile ? 2 : 1}>
            <FButton block onClick={() => setOpen(false)}>{strings().close}</FButton>
          </Col>
          <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24} order={isMobile ? 1 : 2}>
            <FButton block className="btn-delete" onClick={handleDeleteUser}>{strings().delete}</FButton>
          </Col>
        </Row>
      </FModal>
    </div>
  );
};

export default FieldDeleteAccount;