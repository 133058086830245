import { Flex, Select } from "antd";
import icons from "~/assets/icons";
import { TLanguageType, useLanguage } from "~/hooks";
import "./styles.scss";

function LanguageSwitch() {
  const { setLanguage, language } = useLanguage();
  function handleChangeLanguage(value: TLanguageType) {
    setLanguage(value);
  }
  return (
    <Select
      defaultValue={language}
      key={language}
      className="f-language-switch"
      onChange={handleChangeLanguage}
      options={[
        {
          value: "en",
          label: (
            <Flex align="center">
              <img src={icons.flag_en} alt="en" />
            </Flex>
          ),
        },
        // {
        //   value: "vi",
        //   label: (
        //     <Flex align="center">
        //       <img src={icons.flag_vi} alt="vi" />
        //     </Flex>
        //   ),
        // },
      ]}
    />
  );
}

export default LanguageSwitch;
