import { Button, Drawer, Flex } from "antd";
import { useState } from "react";

import strings from "~/assets/strings";
import NavLinkIsMobile from "~/components/NavLink/NavLinkIsMobile";


const FMenuMobile = () => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Flex justify="flex-end">
        <Button
          type="link"
          icon={<i className="fa-solid fa-bars fa-xl" style={{ color: '#ffffff' }}></i>}
          onClick={() => setOpen(true)}
        />
      </Flex>
      <Drawer
        title={<h2>{strings().menu}</h2>}
        placement="left"
        rootClassName="f-drawer-menu-mobile"
        closeIcon={null}
        onClose={() => setOpen(false)}
        footer={
          <p>{strings().copyright__2024_atour_all_rights_reserved}</p>
        }
        open={open}
        extra={
          <Button
            icon={<i className="fa-regular fa-xmark fa-2xl"></i>}
            type="text"
            onClick={() => setOpen(false)}
          />
        }
      >
        <NavLinkIsMobile />
      </Drawer>
    </>
  )
};

export default FMenuMobile;