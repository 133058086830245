import { Outlet } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import FooterPage from "~/pages/Footer";
import Header from "~/pages/Header";

export function HomeLayout() {
  return (
    <Fragment>
      <Header />
      <Outlet />
      <FooterPage />
    </Fragment>
  );
}