import { useMediaQuery } from "usehooks-ts";

function useResponsive() {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 1023px)');
  const isPc = useMediaQuery('(min-width: 1024px)');

  return { isMobile, isTablet, isPc };
}

export default useResponsive;
