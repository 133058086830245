import { Flex } from 'antd';
import icons from '~/assets/icons';

type TDownUpProps = {
  isDown: boolean
}

const DownUp = ({ isDown }: TDownUpProps) => {
  return (
    <Flex justify='center' align='center' style={{ width: 32, height: 32 }}>
      <img src={isDown ? icons.arrow_down : icons.arrow_up} />
    </Flex>
  );
};


export default DownUp;