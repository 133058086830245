import { Col, Form, Row, Select } from "antd";
import strings from "~/assets/strings";
import { selectDays, selectMonths, selectYear } from "~/utils";

const FieldDateOfBirth = () => {
  return (
    <Row gutter={[10, 10]} align="bottom" >
      <Col span={8}>
        <Form.Item
          name='month'
          label={strings().date_of_birth}
          rules={[{
            required: true,
            message: strings().please_enter_required_information
          }]}
        >
          <Select options={selectMonths()} placeholder={strings().month} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name='day'
          rules={[{
            required: true,
            message: strings().please_enter_required_information
          }]}
        >
          <Select options={selectDays()} placeholder={strings().day} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name='year'
          rules={[{
            required: true,
            message: strings().please_enter_required_information
          }]}
        >
          <Select options={selectYear()} placeholder={strings().year} />
        </Form.Item>
      </Col>
    </Row>
  )
};

export default FieldDateOfBirth;

