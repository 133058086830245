import { Collapse, CollapseProps, Flex } from 'antd';
import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DownUp from '~/components/DownUp';
import FCard from '~/components/FCard';
import useResponsive from '~/hooks/useResponsive';
import { formatDate } from '~/utils';
import './style.scss';


const TicketCard = ({ auctionDetails }: any) => {
  const [isCollapsedTicket, setIsCollapsedTicket] = useState(false);
  const { isMobile } = useResponsive()
  const { auction } = auctionDetails;
  const { numOfAdults, numOfChild, registrationTime, numOfRoom } = auction;
  const { t } = useTranslation();

  const items: CollapseProps["items"] = [
    {
      key: "2",
      label: (
        <Flex gap={8} align="center">
          <span>{t('see_calendar')}</span>
          <i className="fa-solid fa-chevron-down"></i>
        </Flex>
      ),
      children: null,
    },
  ];

  const handleCollapse = () => {
    if (isMobile) {
      setIsCollapsedTicket(prev => !prev)
    }
  }

  return (
    <FCard title="Tickets" onClick={handleCollapse} className={clsx("mb-16 ticket-card", { collapsed: isCollapsedTicket })} extra={
      isMobile ? <DownUp isDown={isCollapsedTicket} /> : null
    }>
    <Flex justify="space-between">
      <span>{t('departure_date')}</span>
      <span className="bold-text">08/10 - 12/10</span>
    </Flex>
    <Collapse
      bordered={false}
      expandIcon={() => null}
      items={items}
      className="custom-collapse"
    />
    <div className="calendar-content">
      <Flex justify="space-between">
        <span>{t('start_time')}</span>
        <span className="bold-text">{formatDate(registrationTime)}</span>
      </Flex>
      <Flex justify="space-between">
        <span>{t('language')}</span>
        <span className="bold-text">{t('english__tour_guide')}</span>
      </Flex>
      <Flex vertical>
        <span className="mb-16">{t('number_of_tickets')}</span>
        <ul className="bold-text tickets-number">
          {typeof numOfAdults !== undefined ? <li>{numOfAdults} {t('adult_tickets')}</li> : null}
          {typeof numOfChild !== undefined ? <li>{numOfAdults} {t('children_tickets')}</li> : null}
          {typeof numOfRoom !== undefined ? <li>{numOfAdults} {t('rooms')}</li> : null}
        </ul>
      </Flex>
    </div>
  </FCard>
  );
};


export default TicketCard;