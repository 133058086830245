import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from 'dayjs';
import { NOTIFICATION_STATUS, TNotification, TNotificationList } from "~/@types/notification";
import { RootState } from "../store";

type INotificationState = {
  notificationList: TNotificationList,
}

const initialState: INotificationState = {
  notificationList: {
    data: [],
    pages: 1,
    totals: 1
  },
};

export const notificationSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    unshiftNotification: (state, action: PayloadAction<NOTIFICATION_STATUS>) => {
      state.notificationList.data.unshift({
        isRead: true,
        type: action.payload,
        createdAt: dayjs().toString(),
        id: String(state.notificationList.data.length)
      });
    },
    loadMoreNotification: (state, action: PayloadAction<TNotification[]>) => {
      state.notificationList.data.push(...action.payload);
      state.notificationList.pages += 1;
    },
    setNotification: (state, action: PayloadAction<TNotificationList>) => {
      state.notificationList = action.payload;
    },
    markAllAsRead: (state) => {
      state.notificationList.data = state.notificationList.data.map(({ isRead, ...item }) => ({ ...item, isRead: true }));
    },
  },
});

export const selectNotifications = (state: RootState) => state.notification.notificationList;
export const selectIsHasNewNoti = (state: RootState) => state.notification.notificationList.data.some(({ isRead }) => !isRead);
export const { unshiftNotification, setNotification, loadMoreNotification, markAllAsRead } = notificationSlice.actions;
export default notificationSlice.reducer;
