import { Modal, ModalProps } from "antd";
import clsx from "clsx";
import React from "react";

type TFModalProps = {
  title: string;
} & ModalProps;

const FModal = ({ title, children, ...props }: TFModalProps) => {
  return (
    <Modal
      width={800}
      centered
      footer={null}
      {...props}
      rootClassName={clsx(props.rootClassName, "f-modal-root")}
      wrapClassName={clsx(props.wrapClassName, "f-modal-wrap")}
    >
      <React.Fragment>
        <div className="f-modal-title h5">{title}</div>
        {children}
      </React.Fragment>
    </Modal>
  );
};

export default FModal;
