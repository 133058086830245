import { RouterProvider } from 'react-router-dom';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import router from './configs/routes';
import GlobalStyle from './styles/styled-component/GlobalStyle';
import './styles/styles.scss';

function App() {
  return (
    <>
      <GlobalStyle />
      <RouterProvider router={router} />
    </>
  )
}

export default App
