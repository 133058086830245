import { Badge, Space } from "antd";
import { Link, useLoaderData } from "react-router-dom";

import strings from "~/assets/strings";
import { FBreadcrumb } from "~/components";
import { LINKS } from "~/configs";
import { formatDate } from "~/utils";
import "./styles.scss";

function BlogDetails() {
  const blogDetail: any = useLoaderData();

  return (
    <div className="blog-details blog-details-wrap">
      <div className="container">
        <div className="blog-details__breadcrumb">
          <FBreadcrumb
            items={[
              <Link to={LINKS.home}>{strings().home}</Link>,
              <Link to={LINKS.blog}>{strings().blog}</Link>,
              `${blogDetail.title}`
            ]} />
        </div>
        <div className="blog-details__content">
          <h1>{blogDetail.title}</h1>
          <div className="blog-details__intro">
            <Space size={15}>
              <time>{formatDate(blogDetail.createAt, 'DD MMM YYYY')}</time>
              <Badge status="default" text={blogDetail.user.email} />
            </Space>
          </div>
          <p>{blogDetail.content}</p>
          <div style={{ textAlign: "center" }}>
            <img src={blogDetail.thumbnail} alt="Berlin, Germany" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogDetails;