import { Col, Flex } from "antd";
import { useTranslation } from "react-i18next";
import { NOTIFICATION_STATUS, TNotification } from "~/@types/notification";
import icons from "~/assets/icons";

type TProps = {
  notificationInfo: TNotification
}

export function ItemNotification({ notificationInfo }: TProps) {
  const { type: status, isRead: isReadable } = notificationInfo;
  const [t] = useTranslation()

  function getNotificationContent(status: NOTIFICATION_STATUS) {
    switch (status) {
      case NOTIFICATION_STATUS.USER_AUCTION_SUCCESSFULLY:
        return {
          icon: icons.icon_success,
          title: t('auction_successful'),
          desc: t('your_bid_was_successful_thank_you_for_participating_check_your_email_for_details'),
        };
      case NOTIFICATION_STATUS.USER_AUCTION_FAILED:
        return {
          icon: icons.icon_unsucess,
          title: t('auction_unsuccessful'),
          desc: t('your_bid_was_not_successful_better_luck_next_time_stay_tuned_for_upcoming_auctions'),
        };
      case NOTIFICATION_STATUS.AUCTION_PREPARE_START:
        return {
          icon: icons.icon_starting_soon,
          title: t('auction_starting_soon'),
          desc: t('the_auction_will_start_in_10_minutes_get_ready_to_place_your_bids'),
        };
      case NOTIFICATION_STATUS.AUCTION_PREPARE_END:
        return {
          icon: icons.icon_ending_soon,
          title: t('auction_ending_soon'),
          desc: t('the_auction_is_ending_in_10_minutes_place_your_final_bids_now_before_its_too_late'),
        };
      case NOTIFICATION_STATUS.USER_BID_HIGHER:
        return {
          icon: icons.icon_received,
          title: t('higher_bid_received'),
          desc: t('a_higher_bid_has_been_placed_your_current_bid_is_no_longer_the_highest_consider_making_a_new_bid'),
        };
      case NOTIFICATION_STATUS.AUCTION_END:
        return {
          icon: icons.icon_ended,
          title: t('auction_ended'),
          desc: t('the_auction_has_ended_thank_you_for_your_participation_review_the_results_in_your_account'),
        };
      default:
        return {
          icon: icons.icon_success,
          title: t('auction_successful'),
          desc: t('your_bid_was_successful_thank_you_for_participating_check_your_email_for_details'),
        };
    }
  }

  const { icon, title, desc: description } = getNotificationContent(status);
  return (
    <Flex className="f-notification-item f-notification-item-wrap" justify="space-between" align="center">
      <Col md={2} lg={4} className={`fotification-item__icon-n f-notification-item__icon--${status}`}>
        <img src={icon} alt='notification status' />
      </Col>
      <Col span={20} className="f-notification-item__content">
        <h4>{title}</h4>
        <p>{description}</p>
      </Col>
      <div className="f-notification-item__action f-notification-item__action--isReadable">
        {!isReadable && <i className="f-notification-item__dot" />}
      </div>
    </Flex>
  )
}
