import { message } from "antd";
import { ITour, TFilters } from "~/@types";
import { TBaseResponse } from "~/types";
import { objectToQueryString } from "~/utils";
import AxiosInstance from "./networking";
import { getTourDetails } from "./tour";

export async function getAuctionList(params: TFilters) {
  try {
    const paramsRequest = objectToQueryString({ limit: 10, page: 1, ...params });
    const response = await AxiosInstance.get<TBaseResponse<ITour[]>>(`/auction/filter?${paramsRequest}`);
    return response;
  } catch (error) {
    message.error('Can not get tours');
  }
}

export const getAuctionDetails = async (id: string) => {
  try {
    const response = await AxiosInstance.get(`/auction/${id}`);
    if (response.data.isSuccess) {
      return response.data.data;
    }
  }
  catch (error) {
    message.error('getTourDetails failed');
  }
}

export async function getLiveAuction() {
  try {
    const paramsRequest = objectToQueryString({ limit: 10, page: 1 });
    const response = await AxiosInstance.get<TBaseResponse<ITour[]>>(`/auction/getLiveAuction?${paramsRequest}`);
    return response;
  } catch (error) {
    message.error('Can not get live auction');
  }
}

export async function updateAuction(data: any) {
  const response = AxiosInstance.put('/auctions', data);
  return response;
}

export async function createAuction(data: any) {
  const response = AxiosInstance.post('/auctions', data);
  return response;
}

export async function deleteAuction(id: number) {
  const response = AxiosInstance.delete(`/auctions/${id}`);
  return response;
}

export async function placeBid(data: any) {
  const response = await AxiosInstance.post('/auction/placeBid', data);

  if (response.data) {
    const { isSuccess, message } = response.data

    if (!isSuccess) {
      throw new Error(message)
    }
  }

  return response;
}

export async function getAuctionByTourId(params: any) {
  const { tourId, auctionId: auctionIdParams } = params;

  let tourDetails = {};
  if (tourId) {
    tourDetails  = await getTourDetails(tourId);
  }

  let auctionDetails = {};
  if (auctionIdParams) {
    auctionDetails  = await getAuctionDetails(auctionIdParams);
  }

  return {
    auctionDetails,
    tourDetails
  }

  return {};
}

export const getLiveAuctions = async () => {
  try {
    const response = await AxiosInstance.get(`/auction/getLiveAuction`);
    if (response.data.isSuccess) {
      return response.data.data;
    }
  }
  catch (error) {
    message.error('getTourDetails failed');
  }
}

export const getUpcomingAuctions = async () => {
  try {
    const response = await AxiosInstance.get(`/auction/getUpcomingAuction`);
    if (response.data.isSuccess) {
      return response.data.data;
    }
  }
  catch (error) {
    message.error('getTourDetails failed');
  }
}

export const getRecentAuctions = async () => {
  try {
    const response = await AxiosInstance.get(`/auction/getRecentAuction`);
    if (response.data.isSuccess) {
      return response.data.data;
    }
  }
  catch (error) {
    message.error('getTourDetails failed');
  }
}

export const getUpCommingAuction = async () => {
  try {
    const response = await AxiosInstance.get(`/auction/getUpCommingAuction`);
    if (response.data.isSuccess) {
      return response.data.data;
    }
  }
  catch (error) {
    message.error('getTourDetails failed');
  }
}

export async function addAuctionWishlist(id: string) {
  try {
    const response = await AxiosInstance.post<TBaseResponse<{}, {}>>(`auction/addToWishlist`, { auctionId: id });
    return response;
  }
  catch (error) {
    message.error('Can not get tours');
  }
}

export async function registerAuctionForBidding(id: string) {
  try {
    const response = await AxiosInstance.post<TBaseResponse<{}, {}>>(`auction/${id}/register`);
    return response.data;
  }
  catch (error) {
    message.error('Can not get tours');
  }
}

export async function checkRegisterAuction(userId: string) {
  try {
    const response = await AxiosInstance.get<TBaseResponse<{}, {}>>(`auction/${userId}/check-register`);
    return response.data;
  }
  catch (error) {
    throw new Error('checkRegisterAuction error')
  }
}