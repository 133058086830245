import { Col, Radio, Row } from "antd";
import { useTranslation } from "react-i18next";
import './styles.scss';

type TProps = {
  // TODO
  result?: number;
  radioItems?: Array<{ value: string, label: string }>;
  onChange: (value: string) => void;
};

export function SortControl(props: TProps) {
  const { t } = useTranslation();
  const { result, radioItems = [], onChange } = props;
  return (
    <div className="sort-control sort-control-wrap">
      <Row justify={'space-between'} align={'middle'}>
        <Col span={4} className="sort-control__result">
          {result ?
            <span>{`${result} ${t('results')}`}</span>
            : null}
        </Col>
        <Col span={20} className="sort-control__options">
          <Row justify={'end'}>
            <Radio.Group defaultValue={"0"}>
              {radioItems.map((item, index) => (
                <Radio.Button onChange={(e) => onChange(e.target.value)} key={index} value={`${item.value}`}>{item.label}</Radio.Button>
              ))}
            </Radio.Group>
          </Row>
        </Col>
      </Row>

    </div>
  )
}
