import { Button, Col, Divider, Flex, Image, ImageProps, Row } from "antd";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import icons from "~/assets/icons";
import AuctionBidCard from "~/components/Auction/AuctionBidCard";
import AuctionInfo from "~/components/Auction/AuctionInfo";
import TicketCard from "~/components/Auction/Payment/TicketCard";
import PageHeading from "~/components/PageHeading";
import RecentlyViewed from "~/components/RecentlyViewed";
import TourCalendar from "~/components/TourCalendar";
import useResponsive from "~/hooks/useResponsive";
import { getRecentAuctions } from "~/services";
import HomeBanner from "../Home/components/HomeBanner";
import "./styles.scss";

type TAuctionDetailProps = {
  type?: 'tour' | 'auction';
};

const AuctionDetail = (props: TAuctionDetailProps) => {
  const { type = 'auction' } = props;
  const { isMobile } = useResponsive();
  const swiperRef = useRef<any | null>(null);
  const { auctionDetails, tourDetails }: any = useLoaderData();
  const [recently, setRecently] = useState([]);
  const { thumbnail = [], reviewCounting, rating, title, location } = tourDetails;
  const thumbs = thumbnail?.slice(0, 5) || [];
  const { t } = useTranslation()

  const maskPreview: ImageProps["preview"] = {
    mask: (
      <Flex
        justify="center"
        align="center"
        style={{ height: "100%" }}
        vertical
        gap={10}
      >
        <img src={icons.image_preview} style={{ width: 24, height: 24 }} />
        <span>{t('see_all_image')}</span>
      </Flex>
    ),
  };

  useEffect(() => {
    getRecentAuctions().then(setRecently)
  }, [])

  const ticketCard = type === 'tour' ? null : <TicketCard auctionDetails={auctionDetails} />

  return (
    <main className="container">
      <PageHeading rating={rating} reviewCounting={reviewCounting} title={title} subtitle={location} />
      <div className="image-grid">
        <Image.PreviewGroup
          preview={{
            onChange: (current, prev) =>
              console.log(`current index: ${current}, prev index: ${prev}`),
          }}
        >
          {isMobile ? (
            <div className="list-tour-items__content f-swiper-wrapper mb-16">
              <Button
                className="f-btn-minus left-arrow"
                type="default"
                onClick={() => swiperRef.current.slidePrev()}
                hidden={isMobile}
              >
                <img src={icons.slide_arrow_left} alt="" />
              </Button>
              <Swiper
                onSwiper={(swiper) => {
                  swiperRef.current = swiper;
                }}
                slidesPerView={3}
                loop
                autoplay
                spaceBetween={0}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    navigation: false,
                  },
                  768: {
                    slidesPerView: 1,
                    navigation: false,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                }}
              >
                {thumbs.map(({ originalUrl }: any) => (
                  <SwiperSlide style={{ height: 340 }}>
                    <Image src={originalUrl} preview={maskPreview} />
                  </SwiperSlide>
                ))}
              </Swiper>
              <Button
                className="f-btn-minus right-arrow"
                type="default"
                onClick={() => swiperRef.current.slideNext()}
                hidden={isMobile}
              >
                <img src={icons.slide_arrow_right} alt="" />
              </Button>
            </div>
          ) : (
            <Row className="mt-8 mb-24">
              <Col xxl={12} xl={12} md={24} sm={24} style={{ padding: 4 }}>
                <Image src={thumbs[0].originalUrl} preview={maskPreview} />
              </Col>
              <Col xxl={12} xl={12} md={24} sm={24}>
                <Row style={{ height: '100%' }}>
                  {thumbs.filter((_: any, index: number) => index !== 0).map(({ originalUrl, alternateText }: any) => (
                    <Col span={12} style={{ height: '50%', padding: 4 }}>
                      <Image src={originalUrl} preview={maskPreview} alt={alternateText} />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          )}
        </Image.PreviewGroup>
      </div>
      <Row gutter={[16, 16]} className={clsx({ 'layout-mobile': isMobile })}>
        <Col xxl={16} xl={16} md={16} sm={24} xs={24}>
          {!isMobile ? ticketCard : null}
          <AuctionInfo tourDetails={tourDetails} />
        </Col>
        <Col xxl={8} xl={8} md={8} sm={24} xs={24}>
          {type == 'tour' ? <TourCalendar tourDetails={tourDetails} /> : (
            <React.Fragment>
              {isMobile ? ticketCard : null}
              <AuctionBidCard auctionDetails={auctionDetails} />
              {isMobile ? <Divider className="mb-0" /> : null}
            </React.Fragment>
          )}
        </Col>
      </Row>
      <Divider className="mt-0 mb-0" />
      <RecentlyViewed data={recently} />
      <HomeBanner />
    </main>
  );
};

export default AuctionDetail;
