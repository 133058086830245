import { useTranslation } from "react-i18next";
import { Link, useRouteError } from "react-router-dom";
import { LINKS } from "~/configs";
import './styles.scss';


export default function ErrorPage() {
  const error: any = useRouteError();
  const { t } = useTranslation();
  console.error(error);

  return (
    <div id="error-page">
      <div className="container notfound">
        <div className="notfound-404">
          <div></div>
          <h1>500</h1>
        </div>
        <h1>Oops!</h1>
        <p>{t('sorry_an_unexpected_error_has_occurred')}</p>
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
        <Link to={LINKS.home}>{t('home')}</Link>
      </div>
    </div>
  );
}