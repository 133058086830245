import { Button, Col, Divider, Flex, Row, Space, Tag } from "antd";

import icons from "~/assets/icons";
import strings from "~/assets/strings";
import { FButton } from "~/components";

const BidderListPage = () => {
  const fakeData = {
    title: "Ninh Binh - Hoa Lu - Mua Cave - Tam Coc 1 Day Tour",
    address: "Tp. Nha Trang, Khánh Hòa, Việt Nam",
    star: {
      count: 4.5,
      views: 156
    },
    bidders: {
      count: 5,
      total: 33
    },
    current: 20.55,
    bidderList: [
      { name: "Nguy******", date: '28 Aug 2024 at 7:28:01pm PDT', money: 20.55 },
      { name: "Anna******", date: '28 Aug 2024 at 7:28:01pm PDT', money: 20.55 },
      { name: "Jaxe******", date: '28 Aug 2024 at 7:28:01pm PDT', money: 20.55 },
      { name: "Nguy******", date: '28 Aug 2024 at 7:28:01pm PDT', money: 20.55 },
      { name: "Jaxe******", date: '28 Aug 2024 at 7:28:01pm PDT', money: 20.55 },
      { name: "Nguy******", date: '28 Aug 2024 at 7:28:01pm PDT', money: 20.55 },
    ]
  };

  return (
    <div className="container">
      <div className="wrapper-bidder-list">
        <Space direction="vertical" split={<Divider type="horizontal" />} className="main-content">
          <Space direction="vertical" size={15} className="sub-title">
            <Button
              type="text"
              className="btn-back-item"
              icon={<i className="fa-light fa-chevron-left"></i>}
            >
              {strings().back_to_item_description}
            </Button>
            <h1>{fakeData.title}</h1>
            <Flex justify="space-between">
              <div>
                <Space>
                  <i className="fa-solid fa-location-dot"></i>
                  <p>{fakeData.address}</p>
                </Space>
              </div>
              <div>
                <Space direction="horizontal">
                  <i className="fa-solid fa-star" style={{ color: '#FFD43B' }}></i>
                  <p>{fakeData.star.count} ({fakeData.star.views} {strings().reviews})</p>
                </Space>
              </div>
            </Flex>
          </Space>
          <div>
            <h2>{strings().bidder_list}</h2>
          </div>
          <Row justify="space-between" align="middle" gutter={[0, 13]} className="amount-total">
            <Col span={8}>
              <span className="head-title">{strings().bidders}: </span>
              <span>5</span>
            </Col><Col span={8}>
              <Tag
                icon={<img src={icons.Hammer} alt="tag-bids" />}
                className="tag-bids">
                33 {strings().bids}
              </Tag>
            </Col><Col span={8}>
              <Space>
                <p className="current-title">{strings().current_bid}</p>
                <strong>USD</strong>
                <h2>20.55</h2>
              </Space>
            </Col>
            <Col span={9}>
              <FButton className="primary-1">{strings().place_bid}</FButton>
            </Col>
          </Row>
          <div>
            <div className="header-bidder-title">
              <Row justify="space-between" align="middle">
                <Col span={8}>
                  <p className="head-title">{strings().bidder}</p>
                </Col>
                <Col span={8}>
                  <p className="head-title">{strings().bid_amount}</p>
                </Col>
                <Col span={8}>
                  <p className="head-title">{strings().bid_time}</p>
                </Col>
              </Row>
            </div>
            <div className="bidder-content">
              {fakeData.bidderList.map((item, index) => (
                <Row key={index} justify="space-between" align="middle">
                  <Col span={8}>
                    <Space direction="vertical">
                      <h4>{item.name}</h4>
                      <p>{item.date}</p>
                    </Space>
                  </Col>
                  <Col span={8}></Col>
                  <Col span={8} className="bid-money">
                    <p>${item.money}</p>
                  </Col>
                </Row>
              ))}
            </div>
          </div>
        </Space>
      </div>
    </div>
  )
};

export default BidderListPage;