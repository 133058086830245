
export default {
  API_TIMEOUT: 30000,
  AXIOS_STATUS_CODE: {
    RESPONSE_SUCCESS: 0,
    EXPIRED_SESSION: 6,
    TIMEOUT: 300,
    NOT_FOUND: 404,
    CANCEL_REQUEST_ERROR: 1002,
    FIELD_EXIST_ERROR: 400,
    INTERNET_ERROR: 601,
    HANDLE_ERROR: 1000,
    CONFIG_ERROR: 1001,
    EXPIRED: 401,
    VALIDATE_FIELD_ERROR: 422,
    UNKNOWN_ERROR: 500
  }
}
