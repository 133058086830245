import { Flex } from 'antd'
import { Link } from 'react-router-dom'
import icons from '~/assets/icons'
import strings from '~/assets/strings'
import { FButton } from '~/components'

function HomeBanner() {
  return (
    <div className="home-banner">
      <div className="container">
        <img src={icons.traveller} alt="" />
        <div className="home-banner__content container-small">
          <h3>{strings().register_for_collaboration}</h3>
          <p>
            {strings().register_now_to_bid_on_surprisingly_affordable_tour_packages_receive_important_updates_you_cant_miss_and_join_in_the_excitement}
          </p>
          <Flex justify="center">
            <Link to="/auth/register">
              <FButton type="primary" className="primary-1" size="large">{strings().register}</FButton></Link>
          </Flex>
        </div>
      </div>
    </div>
  )
}

export default HomeBanner