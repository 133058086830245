import { Form, Select } from "antd";
import strings from "~/assets/strings";
import { countries } from "~/configs";
import { filterOptionSelect } from "~/utils";

const FieldNationality = () => {
  return (
    <Form.Item
      name="nationality"
      label={strings().nationality}
      rules={[{
        required: true,
        message: strings().please_enter_required_information
      }]}
    >
      <Select
        showSearch
        filterOption={filterOptionSelect}
        options={countries}
        placeholder={strings().select_the_countryregion_youre_from}
      />
    </Form.Item>
  )
};

export default FieldNationality;

