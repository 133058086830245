import { message } from 'antd';
import { TBaseResponse } from '~/types';
import { IHistoryWallet } from '~/types/wallet';
import AxiosInstance from './networking';


export const depositMoney = async (money: number, id: string) => {
  try {
    const response = await AxiosInstance.post<TBaseResponse>('/wallet/depositMoney', {
      "userId": id,
      "moneyChange": money,
      "currency": "USD"
    });
    return response;
  } catch (error) {
    message.error('Deposit error');
  }
}

export const withDrawMoney = async (money: number, id: string) => {
  try {
    const response = await AxiosInstance.post<TBaseResponse>('/wallet/withDrawMoney', {
      "userId": id,
      "moneyChange": money,
      "currency": "USD"
    });
    return response;
  } catch (error) {
    message.error('Withdraw error');
  }
}

export const getIncludeHistoryByUserId = async (userId: string) => { 
  try {
    const response = await AxiosInstance.get<IHistoryWallet[]>(`/wallet/getIncludeHistoryByUserId/${userId}`);
    return response.data
  }
  catch (error) {
    message.error('getIncludeHistoryByUserId failed');
  }
}


export const walletServices = {
  depositMoney,
  withDrawMoney,
  getIncludeHistoryByUserId
}