import { Button, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import icons from '~/assets/icons';
import ListTours from '~/pages/Home/components/ListTours';
import './styles.scss';

type TProps = {
  data: Record<string, any>[];
}

function RecentlyViewed(props: TProps) {
  const { t } = useTranslation();
  const { data } = props;
  return (
    <div className="recently-viewed recently-viewed-wrap">
      <Flex className="recently-viewed__title container" justify='space-between' align='center'>
        <h3>{t('recently_viewed')}</h3>
        <Button type="link">{t('see_all')}
          <img src={icons.arrow_right} alt={t('see_all')} />
        </Button>
      </Flex>
      <ListTours data={data} />
    </div>
  )
}

export default RecentlyViewed