import { message } from "antd";
import { IBlogs, ICategories, IFiltersBlog, TFilters } from "~/@types";
import { TBaseResponse } from "~/types";
import { objectToQueryString } from "~/utils";
import AxiosInstance from "./networking";

export async function getCategories() {
  try {
    const response = await AxiosInstance.get<TBaseResponse<ICategories[]>>(`/blog/categories`);
    return response.data;
  }
  catch (error) {
    message.error('Can not get categories');
  }
}

export async function getBlogList(params: TFilters) {
  try {
    const paramsRequest = objectToQueryString({ limit: 12, page: 1, ...params });
    const response = await AxiosInstance.get<TBaseResponse<IBlogs[]>>(`/blog/lastet?${paramsRequest}`);
    return response.data;
  }
  catch (error) {
    message.error('Can not get tours');
  }
}


export async function getBlogListByCategory(params: IFiltersBlog) {
  try {
    const paramsRequest = objectToQueryString({ limit: 10, page: 1, ...params });
    const response = await AxiosInstance.get<TBaseResponse<IBlogs[]>>(`/blog/byCategory?${paramsRequest}`);
    return response;
  }
  catch (error) {
    message.error('Can not get tours');
  }
}

export const getBlogDetails = async (params: any) => {
  const { id } = params;
  try {
    const response = await AxiosInstance.get(`/blog/${id}`);
    if (response.data.isSuccess) {
      return response.data.data;
    }
  }
  catch (error) {
    message.error('getBlogDetails failed');
  }
}



