export const dummy_item = {
  "isFavourited": false,
  "id": 1,
  "title": "Mai Chau - Pu Luong 2 days group tour",
  "summary": "Mai Chau Pu Luong 2 days 1 night tour departing from Hanoi is a great ideal package for the travelers who love to be off the beaten path and discover new culture of the destination.",
  "location": "Hanoi",
  "rating": 0,
  "reviewCounting": 0,
  "price": "112.83",
  "thumbnail": [
    {
      "id": 1,
      "originalUrl": "https://bokun.s3.amazonaws.com/46daa836-c791-466f-b182-2d2181fb8c5a.jpg",
      "description": "Landscape in Pu Luong",
      "alternateText": null,
      "height": null,
      "width": null,
      "flags": [],
      "fileName": "/46daa836-c791-466f-b182-2d2181fb8c5a.jpg",
      "derived": [
        {
          "id": 1,
          "name": "thumbnail",
          "url": "https://imgcdn.bokun.tools/46daa836-c791-466f-b182-2d2181fb8c5a.jpg?w=80&h=80&mode=crop",
          "cleanUrl": "https://imgcdn.bokun.tools/46daa836-c791-466f-b182-2d2181fb8c5a.jpg?w=80&h=80&mode=crop"
        }
      ]
    },
    {
      "id": 2,
      "originalUrl": "https://bokun.s3.amazonaws.com/47996d35-f614-429d-a711-03ad93402ed7.jpg",
      "description": "local people",
      "alternateText": null,
      "height": null,
      "width": null,
      "flags": [],
      "fileName": "/47996d35-f614-429d-a711-03ad93402ed7.jpg",
      "derived": [
        {
          "id": 4,
          "name": "thumbnail",
          "url": "https://imgcdn.bokun.tools/47996d35-f614-429d-a711-03ad93402ed7.jpg?w=80&h=80&mode=crop",
          "cleanUrl": "https://imgcdn.bokun.tools/47996d35-f614-429d-a711-03ad93402ed7.jpg?w=80&h=80&mode=crop"
        }
      ]
    },
    {
      "id": 3,
      "originalUrl": "https://bokun.s3.amazonaws.com/f240a8ba-59e5-4bf0-be25-58ca62df0508.jpg",
      "description": "biking through rice paddy",
      "alternateText": null,
      "height": null,
      "width": null,
      "flags": [],
      "fileName": "/f240a8ba-59e5-4bf0-be25-58ca62df0508.jpg",
      "derived": [
        {
          "id": 7,
          "name": "thumbnail",
          "url": "https://imgcdn.bokun.tools/f240a8ba-59e5-4bf0-be25-58ca62df0508.jpg?w=80&h=80&mode=crop",
          "cleanUrl": "https://imgcdn.bokun.tools/f240a8ba-59e5-4bf0-be25-58ca62df0508.jpg?w=80&h=80&mode=crop"
        }
      ]
    },
    {
      "id": 4,
      "originalUrl": "https://bokun.s3.amazonaws.com/24a6dd4f-0ea0-440a-ab02-c9df66060780.jpg",
      "description": "Rice paddy in harvest season",
      "alternateText": null,
      "height": null,
      "width": null,
      "flags": [],
      "fileName": "/24a6dd4f-0ea0-440a-ab02-c9df66060780.jpg",
      "derived": [
        {
          "id": 10,
          "name": "thumbnail",
          "url": "https://imgcdn.bokun.tools/24a6dd4f-0ea0-440a-ab02-c9df66060780.jpg?w=80&h=80&mode=crop",
          "cleanUrl": "https://imgcdn.bokun.tools/24a6dd4f-0ea0-440a-ab02-c9df66060780.jpg?w=80&h=80&mode=crop"
        }
      ]
    },
    {
      "id": 5,
      "originalUrl": "https://bokun.s3.amazonaws.com/42040138-634c-451f-8f71-b29b715807f8.jpg",
      "description": "Mai Chau",
      "alternateText": null,
      "height": null,
      "width": null,
      "flags": [],
      "fileName": "/42040138-634c-451f-8f71-b29b715807f8.jpg",
      "derived": [
        {
          "id": 13,
          "name": "thumbnail",
          "url": "https://imgcdn.bokun.tools/42040138-634c-451f-8f71-b29b715807f8.jpg?w=80&h=80&mode=crop",
          "cleanUrl": "https://imgcdn.bokun.tools/42040138-634c-451f-8f71-b29b715807f8.jpg?w=80&h=80&mode=crop"
        }
      ]
    }
  ],
  "durationText": "2 days",
  "auctions": [
    {
      "isFavourited": false,
      "auctionId": "6ed0b74e-b7c0-42b3-8f4e-66f6278618b4",
      "adults": 1,
      "childs": 1,
      "rooms": 1,
      "startPrice": "20",
      "currentBid": 40,
      "numOfAuction": 1,
      "timeStart": "2024-10-19T04:11:49.077Z",
      "timeEnd": "2024-10-23T16:11:49.077Z",
      "type": 1,
      "buyPriceNow": null
    }
  ]
};

export const dummy_blog = {
  "id": 1,
  "created": "2021-01-15T00:00:00.000Z",
  "updated": "2021-01-15T00:00:00.000Z",
  "author": "John Doe",
  "title": "5 of the best hotels in Los Angeles",
  "description": "From Hollywood to Beverly Hills discover 5 of the best hotels in Los Angeles for your stay",
  "thumbnail": "https://placedog.net/288",
  "rating": 5.0,
  "createdDate": "January 15, 2021",
  "category": "Travel"
};