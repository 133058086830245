import { Col, Flex } from "antd";
import dayjs from 'dayjs';
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData, useNavigate, useSearchParams } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

import { Banner, FPagination, FilterField, FrmSearch, NotFoundItem, SortAuctionControl } from "~/components";
import ItemTour from "~/components/ItemTour";
import { LINKS } from "~/configs";
import useResponsive from "~/hooks/useResponsive";
import { getAuctionList } from "~/services";
import { LoaderData, getAllParams, objectToQueryString, searchParamsToObject } from "~/utils";


export async function loaderAuctionPage({ request }: any) {
  const params = getAllParams(request.url);
  const auctions = await getAuctionList(params);
  return auctions;
}

function AuctionPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isMobile } = useResponsive();
  const params = searchParamsToObject(searchParams);

  // const params = getAllParams(searchParams.toString());
  const [page, setPage] = useState<number>(parseInt(`${searchParams.get("page") || 1}`));
  const auctions = useLoaderData() as LoaderData<typeof loaderAuctionPage>;

  function handleOnSearch(values: any) {
    const initLocation: any = {};
    const { dateFrom, locationIds } = values;
    if (dateFrom) {
      initLocation['dateFrom'] = `${dayjs(dateFrom).format('YYYY-MM-DD')}T07:00:00.000Z`;
    }
    initLocation['locationIds'] = locationIds;
    navigate(`${LINKS.auction}?${objectToQueryString({ ...initLocation, page: 1 })}`);
  }

  function handleChangePage(page: number) {
    setPage(page);
    const _params = { ...params, page };
    navigate(`${LINKS.auction}?${objectToQueryString(_params)}`);
  }

  function handleFieldChange(values: any) {
    console.log("🚀 ~ handleFieldChange ~ values:", values)
    setPage(1);
    navigate(`${LINKS.auction}?${objectToQueryString({ ...values, page: 1 })}`);
    //merge filter to search params current
  }

  function handleOnSortOption(value: string) {
    setPage(1);
    //if value = 'all' remove 
    if (value === 'all') {
      delete params.type;
    } else {
      params.type = value;
    }
    navigate(`${LINKS.auction}?${objectToQueryString({ ...params, page: 1 })}`);
  }

  return (
    <Fragment>
      <Banner breadcrumb={[
        { label: t('home'), link: LINKS.home, isActive: false },
        { label: t('auction'), link: LINKS.auction, isActive: true }
      ]}
        renderControls={() => <FrmSearch onSearch={handleOnSearch} />}
      />
      <div className="page-container">
        <Flex
          className="container"
          style={{ paddingTop: 40, paddingBottom: 40 }}
          wrap={isMobile ? "wrap" : "nowrap"}
        >
          <Col md={{ span: 24 }} lg={{ span: 6 }} hidden={isMobile}>
            <FilterField
              isAuction
              onFieldsChange={handleFieldChange}
              initValues={params as any}
            />
          </Col>
          <Col md={{ span: 24 }} lg={{ span: 18 }} style={{ paddingLeft: isMobile ? 0 : 16 }}>
            <SortAuctionControl sortItems={[{
              label: t('all'),
              value: 'all',
            }, {
              label: t('auction'),
              value: 'auction',
            }, {
              label: t('buy_it_now'),
              value: 'buy-now',
            }]} result={auctions?.data.totals} onChange={handleOnSortOption} />
            {
              auctions?.data?.data?.length
                ? auctions?.data?.data?.map((item, index) => (
                  <ItemTour key={index} data={item} border type="auction" />
                ))
                : <NotFoundItem />
            }
            <FPagination
              hideOnSinglePage
              defaultCurrent={parseInt(`${page}`)}
              total={auctions?.data.totals}
              onChange={handleChangePage}
              current={page}
            />
          </Col>
        </Flex>
      </div >
    </Fragment>
  )
}

export default AuctionPage;