import { Space } from "antd";
import { Link } from "react-router-dom";
import useResponsive from "~/hooks/useResponsive";

type IFooterItems = {
  itemCols: {
    name: string;
    link: string;
  }[],
  hidden?: boolean;
}

const FooterItems: React.FC<IFooterItems> = ({ itemCols, hidden = false }) => {
  const { isMobile } = useResponsive();

  return (
    <div style={{ display: hidden ? 'none' : 'block' }}>
      <Space size={isMobile ? 0 : 15} direction="vertical">
        {itemCols.map(({ name, link }, index) => (
          <Link to={link} key={index}>{name}</Link>
        ))}
      </Space>
    </div>
  )
};

export default FooterItems;