import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/vi';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);


export function formatHourMinuteSecond(
  date: Dayjs | string | number,
  format = 'HH:mm:ss',
) {
  return `${dayjs(date).tz("Atlantic/Azores").format(format)}`;
}