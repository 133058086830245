import { Flex } from "antd";
import { useTranslation } from "react-i18next";
import icons from "~/assets/icons";
import { formatMoney } from "~/utils";

type TProps = {
  // TODO
  bids: number;
  currentBid: number | string;
};

export function ItemTourBids(props: TProps) {
  const { bids, currentBid } = props;
  const { t } = useTranslation();
  return (
    <Flex align="center" justify="space-between">
      <div className="item-tour__bid item-tour__bid-wrap">
        <Flex className="item-tour__bid--inner">
          <div className="item-tour__bid__label">
            <img src={icons.hammers_bid} alt="" />
            <span>
              {bids} {t('bids')}
            </span>
          </div>
        </Flex>
      </div>
      <Flex align="center" justify="space-between" className="item-tour__bid__price">
        <div className="item-tour__bid__progress">
          <span>{t('current_bid')}</span>
        </div>
        <strong>
          USD <b>{formatMoney(parseFloat(`${currentBid}`))}</b>
        </strong>
      </Flex>
    </Flex>
  );
}
