import { Space } from "antd";
// import SignInFacebook from "./SignInFacebook";
import SignInGoogle from "./SignInGoogle";
// import SignInApple from "./SignInApple";

export function SocialsAuth() {
  return (
    <Space size={12} direction="vertical" className="group-button">
      {/* <SignInFacebook /> */}
      <SignInGoogle />
      {/* <SignInApple /> */}
    </Space>
  )
}