import { Flex } from "antd";

import useResponsive from "~/hooks/useResponsive";
import { AppLogo } from "../AppLogo";
import FMenuMobile from "../FMenu/Mobile/FMenuMobile";
import LanguageSwitch from "../LanguageSwitch";

const FHeaderAuth = () => {
  const { isMobile } = useResponsive();

  return (
    <div className="header-auth" style={{ boxShadow: "0 0 20px rgba(73, 151, 172, 1)" }}>
      <div className="container">
        <Flex className="header-auth__inner" justify="space-between">
          <AppLogo />
          {isMobile ? <FMenuMobile /> : <LanguageSwitch />}
        </Flex>
      </div>
    </div>
  )
};

export default FHeaderAuth;