import { Divider, Flex, Form, InputNumber, message } from "antd";
import { useForm } from "antd/es/form/Form";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData, useParams } from "react-router-dom";
import icons from "~/assets/icons";
import strings from "~/assets/strings";
import FButton from "~/components/FButton";
import FTable from "~/components/FTable";
import Loading from "~/components/Loading";
import PageHeading from "~/components/PageHeading";
import Price from "~/components/Price";
import { websocket } from "~/core/websocket";
import useResponsive from "~/hooks/useResponsive";
import { placeBid } from "~/services";
import { formatDate } from "~/utils/Dates";
import { formatMoney } from "~/utils/number";
import { EXPIRED, countdownFormADay, getBiddersToShow, hiddenName } from "~/utils/utils";

const PlaceYourBid = () => {
  const { auctionDetails }: any = useLoaderData();
  const { auction, bidders: biddersFromServer } = auctionDetails;

  const {
    currentPrice,
    endTime: timeEnd,
    startTime: timeStart,
    id,
    tour,
  } = auction;

  const {
    reviewCount,
    reviewRating,
    location,
    title
  } = tour;

  const [form] = useForm();
  const bidePrice = Form.useWatch("bidePrice", form);
  const isDisabled = Boolean(bidePrice);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [currentBid, setCurrentBid] = useState(currentPrice);
  const params = useParams()
  const { isMobile } = useResponsive()
  const [timeLeft, setTimeLeft] = useState('')
  const [bidders, setBidders] = useState<any>(biddersFromServer);
  const uniqueBidders = _.uniqBy(bidders, 'username')
  const { t } = useTranslation()

  const columnsBidderSource = [
    {
      title: strings().bidder,
      dataIndex: "bidderName",
      key: "bidderName",
      render: (value: any, _record: any, index: number) => (
        <Flex
          justify="space-between"
          className={clsx("item-in-current-bid user-info-bid-inner")}
          wrap
        >
          <Flex flex={"1"}>
            <Flex vertical>
              <span className={clsx('username-bided text-ellipsis mb-8', { 'bold-text': index === 0 })}>{hiddenName(value)}</span>
              <span className="date-bid">
                {formatDate(_record.bidTime, "DD MMM YYYY HH:mm:ss")}
              </span>
            </Flex>
          </Flex>
        </Flex>
      ),
    },
    {
      title: strings().bid_amount,
      dataIndex: "bidAmount",
      key: "bidAmount",
      render: (value: any, _record: any, index: number) => (
        <Price price={`$${value}`} style={{ fontSize: 16, lineHeight: '24px', fontWeight: index === 0 ? 700 : 'normal' }} priceClassName={clsx('text-orange-1')} />
      )
    },
    {
      title: strings().bid_time,
      dataIndex: "bidTime",
      key: "bidTime",
      render: (value: any) => formatDate(
        value,
        "DD MMM YYYY HH:mm:ss"
      )
    },
  ];

  useEffect(() => {
    const timer = countdownFormADay(timeEnd, timeStart, setTimeLeft)

    return () => {
      clearInterval(timer)
    }
  }, [timeEnd])

  useEffect(() => {
    if (id) {
      function onFooEvent(value: any) {
        if (value) {
          try {
            const { bidPrice, user } = JSON.parse(value);
            const myBidder = {
              bidderName: user.email,
              bidTime: new Date(),
              bidAmount: bidPrice,
            }
            setBidders((prev: any) => [myBidder].concat(prev))
            setCurrentBid(bidPrice)
          } catch (error) {
            message.error('Socket was receive some errors')
          }
        }
      }

      websocket.onEvent(id, onFooEvent);

      return () => {
        if (id) {
          websocket.offEvent(id);
        }
      };
    }
  }, [id]);

  const handleSetBid = (value: number) => {
    handleFinish({ bidePrice: value })
  };

  const handleFinish = (values: any) => {
    if (params?.auctionId) {
      setIsLoading(true);
      placeBid(
        {
          auctionId: params?.auctionId,
          "setPrice": values.bidePrice,
          "currency": "USD"
        }
      ).then(
        () => {
          setError(false);
          form.resetFields();
          setCurrentBid(values.bidePrice)
        }
      )
        .catch((error) => {
          setError(true)
          message.error(error?.message)
        })
        .finally(() => {
          setIsLoading(false);
          setIsSubmitted(true);
        });
    }
  };

  const FormModal = (
    <React.Fragment>
      <Flex justify="space-between" align="center" className={clsx("current-bid")} wrap>
        <Flex flex={1} justify="space-between" align="center" className="bidder-text">
          <div className="text-primary-1 lh-24">
            <a>{strings().bidders}</a>: {uniqueBidders.length}
          </div>
          <FButton
            className="primary-4 bid-button mb-8"
            icon={<img src={icons.hammers_bid} />}
          >
            {bidders.length} {strings().bids}
          </FButton>
        </Flex>
        <Flex
          align="center"
          justify="flex-end"
          className={clsx("bid-item text-orange-1 item-price lh-24", { currentMobile: isMobile })}
          gap={16}
          style={{ width: isMobile ? "100%" : "auto" }}
          flex={1}
        >
          <div className="bid-title">{strings().current_bid}</div>
          <Price
            price={currentBid}
            priceClassName="text-orange-1 bid-pricing"
          />
        </Flex>
      </Flex>
      <Flex justify="space-between" align="center" className="item-price">
        <span>{strings().time_left}</span>
        <span className="bold-text">
          {timeLeft === EXPIRED ? t('expired') : timeLeft}
        </span>
      </Flex>
      <Divider />
      {timeLeft !== EXPIRED ? (
        <React.Fragment>
          <Flex gap={16} className="group-btn-bids">
            <FButton className="f-bg-none" onClick={() => handleSetBid(currentBid + 1)}>
              {strings().bid_usd} ${formatMoney(currentBid + 1)}
            </FButton>
            <FButton className="f-bg-none" onClick={() => handleSetBid(currentBid + 2)}>
              {strings().bid_usd} ${formatMoney(currentBid + 2)}
            </FButton>
            <FButton className="f-bg-none" onClick={() => handleSetBid(currentBid + 3)}>
              {strings().bid_usd} ${formatMoney(currentBid + 3)}
            </FButton>
          </Flex>
          <Flex className="mt-24 mb-24">
            {strings().or}
          </Flex>
          <div className="your-max-bid">
            <div className="h6 mb-8">{strings().your_max_bid}</div>
            <Form form={form} className="form-submit-bid" onFinish={handleFinish}>
              <div>
                <Form.Item
                  name="bidePrice"
                  rules={[
                    {
                      min: currentBid,
                      type: "number",
                      message: `${strings().your_bid_is_lower_than_the_current_price}`,
                    },
                    {
                      required: true,
                      message: strings().required,
                    },
                  ]}
                  className="mb-8"
                >
                  <InputNumber
                    addonBefore="USD $"
                    className="with-add-on"
                    style={{ padding: 0 }}
                  />
                </Form.Item>
                <div className="help-text-form mt-8">
                  {strings().enter} $21 {strings().or_more}.
                </div>
              </div>
              <FButton
                disabled={!isDisabled}
                className={clsx({ "primary-1": isDisabled })}
                htmlType="submit"
                style={{ textTransform: "capitalize" }}
              >
                {strings().bid}
              </FButton>
            </Form>
          </div>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  )

  const SubmitResults = isSubmitted && (
    <Flex
      vertical
      justify="space-between"
      align="center"
      style={{ minHeight: "inherit" }}
    >
      {!error ? (
        <React.Fragment>
          <div className="h5 text-success">
            {strings().your_bid_has_been_placed_successfully}
          </div>
          <img src={icons.success_icon} className="icon-results" />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="h5 text-error">{strings().your_bid_has_just_been_placed}</div>
          <img src={icons.error_icon} className="icon-results" />
        </React.Fragment>
      )}
    </Flex>
  );

  return (
    <div className={clsx("container place-your-bid", { mobile: isMobile })}>
      <PageHeading
        rating={reviewRating}
        reviewCounting={reviewCount}
        title={title}
        subtitle={location.name}
      />
      <Divider className={clsx({ "mt-24 mb-24": !isMobile, "mt-0 mb-0": isMobile })} />
      <div className={clsx("bid-content", { mobile: isMobile })}>
        {FormModal}
      </div>
      {timeLeft === EXPIRED ? null : (
        <React.Fragment>
          <Flex justify="center" className="mt-8 select-desc">
            <i>
              {strings().by_selecting_bid_you_are_committing_to_buy_this_item_if_you_are_the_winning_bidder}
            </i>
          </Flex>
          <div className="mt-24 mb-24">
            {isLoading ? <Loading isLoading={isLoading} /> : SubmitResults}
          </div>
          <Divider className="mt-24 mb-24" />
        </React.Fragment>
      )}
      <FTable
        dataSource={getBiddersToShow(bidders)}
        columns={columnsBidderSource}
        pagination={false}
        footerText={bidders.length > 5 ? "Show all bidder" : ''}
        className="mb-16"
      />
    </div>
  );
};

export default PlaceYourBid;
