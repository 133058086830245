import icons from "~/assets/icons";
import { FButton, GoogleLoginButton } from "~/components";
import './styles.scss';

const SignInGoogle = () => {
  return <FButton className="f-btn-google">
    <img src={icons.logo_google} alt="google" />
    <span>Continue with Google</span>
    <GoogleLoginButton />
  </FButton>;
};

export default SignInGoogle;
