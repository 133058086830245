import { AnyObject } from "antd/es/_util/type";
import _ from 'lodash';
import { Socket, io } from 'socket.io-client';
import { env } from "~/utils";
import { EVENT_NAME_WS } from "./eventWs";

export class WebSocketInstance {
  static socketIO: Socket

  static isConnected: boolean = false;

  isAsync: Boolean = false;

  constructor (url: string) {
    if (!WebSocketInstance.socketIO) {
      WebSocketInstance.socketIO = io(url, {
        autoConnect: false,
        transports: ["websocket", "polling"]
      })
    }
  }

  checkIsConnected () {
    let timer: any = null;
    let count = 0;
    return new Promise((resolve, reject) => {
      if (WebSocketInstance.isConnected) {
        resolve(true);
        clearInterval(timer);
      } else {
        timer = setInterval(() => {
          if (WebSocketInstance.isConnected) {
            resolve(true);
            clearInterval(timer);
            return;
          }

          if (count === 10) {
            reject(false)
          }

          count += 1
        }, 500)
      }
    })
  }

  asyncOpen() {
    return new Promise((resolve, reject) => {
      if (!WebSocketInstance.isConnected) {
        WebSocketInstance.socketIO.connect();
      } else {
        resolve({ message: 'connected already', isConnected: true })
      }

      WebSocketInstance.socketIO.on(EVENT_NAME_WS.CONNECTED, 
        () => {
          WebSocketInstance.isConnected = true;
          resolve({ message: 'connect successfully', isSuccess: true })
        }
      )

      WebSocketInstance.socketIO.on(EVENT_NAME_WS.CONNECTED_FAILED, 
        () => {
          WebSocketInstance.isConnected = false;
          reject({ message: 'connect failed', isSuccess: false })
          WebSocketInstance.socketIO.io.opts.transports = ["polling", "websocket"];
        }
      )
    })
  }

  validateEventName(eventName: string) {
    return _.isString(eventName)
  }

  validateData(data: ITransmitObject) {
    if (_.isEmpty(data)) {
      throw new RangeError('data is empty')
    }

    try {
      return data;
    } catch (error: any) {
      throw new Error(error?.message)
    }
  }

  emitData(eventName: string, data: ITransmitObject) {
    WebSocketInstance.socketIO.emit(eventName, data)
  }

  asyncEmitData<T>(eventName: string, data: ITransmitObject<T>) {
    return new Promise((resolve) => {
      this.emitData(eventName, data);
      this.onEvent(eventName, resolve)
    })
  }

  async onEvent(eventName: string, cb: (data: AnyObject) => any) {
    await this.asyncOpen();
    WebSocketInstance.socketIO.on(eventName, (value) => {
      try {
        cb(value);
      } catch (error: any) {
        throw new Error('Receive ws data error')
      }
    })
  }

  offEvent(eventName: string) {
    WebSocketInstance.socketIO.off(eventName)
  }

  close() {
    WebSocketInstance.socketIO.close();
    WebSocketInstance.isConnected = false;
  }
}

export const websocket = new WebSocketInstance(env.VITE_WS_URL);
