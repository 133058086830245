import { TNotificationList } from "~/@types/notification";
import AxiosInstance from "./networking";

export const getNotifications = async ({ userId, page = 1, limit = 10 }: any) => {
  try {
    const response = await AxiosInstance.get<TNotificationList>(`/notification/${userId}?page=${page}&limit=${limit}`);
    return response.data;
  } catch (error: any) {
    throw new Error(error?.message)
  }
}

export const readAllNotification = async ({ userId }: any) => {
  try {
    const response = await AxiosInstance.put(`/notification/${userId}/read-all`);
    return response.data;
  } catch (error: any) {
    throw new Error(error?.message)
  }
}