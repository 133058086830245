import { Button, Col, DatePicker, Divider, Flex, Form, Row, Select, Space } from "antd";

import strings from "~/assets/strings";
import { FButton } from "~/components";

const CartPage = () => {

  const fakeData = {
    title: "Ninh Binh - Hoa Lu - Mua Cave - Tam Coc 1 Day Tour",
    address: "Tp. Nha Trang, Khánh Hòa, Việt Nam",
    star: {
      count: 4.5,
      views: 156
    },
  };

  const products = [
    { name: 'Auults' },
    { name: 'Senior (age 66-80)' },
    { name: 'Child (age 8-11)' },
    { name: 'Child (age 5-7)' },
    { name: 'Infant (age 1-4)' },
  ]

  return (
    <div className="container">
      <div className="wrapper-cart">
        <Space direction="vertical" size={15} className="sub-title ">
          <Button
            type="text"
            className="btn-back-item"
            icon={<i className="fa-light fa-chevron-left"></i>}
          >
            {strings().back_to_item_description}
          </Button>
          <h1>{fakeData.title}</h1>
          <Flex justify="space-between">
            <div>
              <Space>
                <i className="fa-solid fa-location-dot"></i>
                <p>{fakeData.address}</p>
              </Space>
            </div>
            <div>
              <Space direction="horizontal">
                <i className="fa-solid fa-star" style={{ color: '#FFD43B' }}></i>
                <p>{fakeData.star.count} ({fakeData.star.views} {strings().reviews})</p>
              </Space>
            </div>
          </Flex>
        </Space>
        <Divider />
        <Form labelCol={{ span: 24 }}>
          <Row justify="center">
            <Col span={17}>
              <div className="cart-content">
                <Space direction="vertical">
                  <h2>{strings().buy_now}</h2>
                  <Flex justify="space-between">
                    <h4>{strings().departure_date}</h4>
                    <h4>08/01 - 12/10</h4>
                  </Flex>
                  <DatePicker
                    variant="borderless"
                    placeholder={strings().see_calendar}
                    suffixIcon={<i
                      className="fa-regular fa-chevron-down"
                      style={{ color: '#09a4d1' }}
                    ></i>}
                    showTime
                  />
                </Space>
                <Divider />
                <Flex justify="space-between">
                  <h4>{strings().start_time}</h4>
                  <h4>07:00 AM</h4>
                </Flex>
                <Divider />
                <Form.Item label={strings().language_options} name='language'>
                  <Select></Select>
                </Form.Item>
                <Divider />
                <h4>{strings().how_many_tickets}?</h4>
                <Row justify="start" gutter={[10, 10]}>
                  {products.map(({ name }, index) => (
                    <Col span={24} key={index}>
                      <Row justify="space-between" align="middle">
                        <Col span={20}>
                          <p>{name}</p>
                        </Col>
                        <Col span={4}>
                          <Flex gap={12} justify="center" align="baseline" className="group-action">
                            <Button
                              icon={<i className="fa-regular fa-horizontal-rule"></i>}
                              type="text"
                            />
                            <span>2</span>
                            <Button
                              type="text"
                              icon={<i className="fa-solid fa-plus"></i>}
                            />
                          </Flex>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>
                <Divider />
                <Flex justify="space-between" align="baseline">
                  <h2>{strings().total}</h2>
                  <Space align="baseline">
                    <h3>USD</h3>
                    <h1>15</h1>
                  </Space>
                </Flex>
                <Space direction="vertical">
                  <p>{strings().includes_taxes_and_fees}</p>
                  <FButton className="primary-1">{strings().payment}</FButton>
                </Space>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  )
};

export default CartPage;