import { Col, Flex, Radio, Row } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import { IBlogs, ICategories } from "~/@types";
import { Banner, FPagination, ItemBlog, NotFoundItem } from "~/components";
import { LINKS } from "~/configs";
import { getBlogList, getBlogListByCategory, getCategories } from "~/services";
import { generateResponsiveProps, getAllParams, objectToQueryString, searchParamsToObject } from "~/utils/utils";
import "./styles.scss";

export async function loaderBlogPage({ request }: any) {
  const params = getAllParams(request.url);
  const blogs = await getBlogList(params);
  return blogs;
}

function BlogPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const params = searchParamsToObject(searchParams);
  const colRes = generateResponsiveProps({ sm: 12, xl: 6, span: 24 });
  const [page, setPage] = useState<number>(parseInt(`${searchParams.get("page") || 1}`));
  const [cateId, setCateId] = useState<string | number>('lastet');
  const [categories, setCategories] = useState<ICategories[]>([]);
  const [listBlogs, setListBlog] = useState<IBlogs[]>([]);
  const [total, setTotal] = useState<number>();

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await getCategories();
      if (response?.isSuccess && response.data) setCategories(response.data);
    };
    fetchCategories();
  }, []);

  function handleChangePage(page: number) {
    setPage(page);
    const _params = { ...params, page };
    navigate(`${LINKS.blog}?${objectToQueryString(_params)}`);
  }


  useEffect(() => {
    const fetchBlogs = async () => {
      const params = { page };
      if (cateId === 'lastet') {
        const res1 = await getBlogList(params);
        console.log("🚀 ~ fetchBlogs ~ res1:", res1)
        if (res1?.data?.length) setListBlog(res1?.data);
        setTotal(res1?.totals)
        navigate(`${LINKS.blog}?${objectToQueryString(params)}`);
      } else {
        const _params = { categoryId: cateId, ...params };
        const res2 = await getBlogListByCategory(_params);
        if (res2?.data.data?.length) setListBlog(res2?.data.data);
        setTotal(res2?.data.totals)
        navigate(`${LINKS.blog}?${objectToQueryString(_params)}`);
      }
    };
    fetchBlogs();
  }, [cateId, page]);

  return (
    <Fragment>
      <Banner
        background="/images/banner-blog.jpg"
        breadcrumb={[
          { label: t('home'), link: LINKS.home, isActive: false },
          { label: t('blog'), link: LINKS.blog, isActive: true }
        ]}
      />
      <div className="page-container blog-page blog-page-wrap">
        <div className="container" style={{ paddingBottom: 40 }}>
          <Flex className="blog__sort" wrap={"wrap"}>
            <Radio.Group defaultValue={'lastet'} onChange={(e: any) => setCateId(e.target.value)}>
              <Radio.Button value={'lastet'}>{t('latest')}</Radio.Button>
              {categories.map(({ id, name }) => (
                <Radio.Button key={id} value={id}>{name}</Radio.Button>
              ))}
            </Radio.Group>
          </Flex>
          <Row gutter={16}>
            {
              listBlogs.length
                ? listBlogs.map((item, index) => (
                  <Col {...colRes} key={`blog-${index}`}>
                    <ItemBlog data={item} />
                  </Col>
                ))
                : <NotFoundItem />
            }
          </Row>
          <FPagination
            justify={"center"}
            defaultCurrent={parseInt(`${page}`)}
            total={total}
            current={page}
            onChange={handleChangePage}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default BlogPage;