import { Flex, Table, TableProps } from 'antd';
import clsx from 'clsx';
import React from 'react';
import './styles.scss'

type TFTableProps = {
  footerText?: string
} & TableProps

const FTable = (props: TFTableProps) => {
  return (
    <React.Fragment>
      <Table {...props} className={clsx(props.className, 'f-table')} />
      {props.footerText && (
      <Flex justify='center' className='text-primary-1 mt-16 mb-16'>
        {props.footerText}
      </Flex>
      )}
    </React.Fragment>
  );
};


export default FTable;