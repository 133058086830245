import { ICategory, ILocation } from "~/@types";
import { TBaseResponse } from "~/types";
import AxiosInstance from "./networking";

//define all functions that need to be run on page load
export async function getAllCategories() {
  //fetch all categories
  const response = await AxiosInstance.get<TBaseResponse<ICategory[]>>('/api/categories');
  return response;
}

export async function getAllLocations() {
  //fetch all categories
  const response = await AxiosInstance.get<TBaseResponse<ILocation[]>>('/api/locations');
  return response.data.data;
}