import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

type TProps = {
  image: {
    src: string;
    alt: string;
  }
}

function ItemThumbnail({ image }:TProps) {
  return (
    <LazyLoadImage
      alt={image.alt}
      effect="blur"
      wrapperProps={{
        style: { transitionDelay: "1s" },
      }}
      src={image.src || '/public/images/not-found.png'} />
  )
}

export default ItemThumbnail