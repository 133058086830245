import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProfile } from "~/types";
import { RootState } from "../store";

type IProfileState = {
  userInfo: IProfile
}

const initialState: IProfileState = {
  userInfo: {
    id: '',
    name: null,
    username: '',
    email: '',
    firstName: '',
    lastName: '',
    address: '',
    dob: '',
    status: 1,
    gender: '',
    type: 2,
    roles: [],
    wallet: 0,
    countryCode: '',
    nationality: null,
    city: '',
    phoneNumber: null,
  }
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<IProfile>) => {
      state.userInfo = action.payload;
    },
    setWallet: (state, action: PayloadAction<number>) => {
      state.userInfo.wallet = state.userInfo.wallet + parseInt(`${action.payload}`);
    }
  },
});

export const selectUserInfo = (state: RootState) => state.user.userInfo;
export const { setUserInfo, setWallet } = userSlice.actions;
export default userSlice.reducer;
