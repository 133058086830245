import { Button, Flex } from "antd";
import { Link } from "react-router-dom";

import strings from "~/assets/strings";
import { LINKS } from "~/configs";

const AuthHeader = () => {
  return (
    <Flex gap={15} justify="flex-end" className="group-button">
      <Button className="btn-register">
        <Link to={LINKS.register}>{strings().register}</Link>
      </Button>
      <Button type="primary">
        <Link to={LINKS.signIn}>{strings().sign_in}</Link>
      </Button>
    </Flex>
  )
};

export default AuthHeader;