import { Button, Col, Divider, Form, Row, Space } from "antd";
import { Fragment, useState } from "react";

import icons from "~/assets/icons";
import strings from "~/assets/strings";
import { FButton } from "~/components";
import useResponsive from "~/hooks/useResponsive";
import FieldCurrency from "./components/FieldCurrency";
import FieldDeleteAccount from "./components/FieldDeleteAccount";
import FieldLanguage from "./components/FieldLanguage";

export function Settings() {
  const { isMobile } = useResponsive();
  const [indexField, setIndexField] = useState<number | false>(6);

  function handleClickShowForm(index: number) {
    setIndexField((prev) => {
      if (prev === index) {
        return false;
      }
      return index;
    });
  }

  const fakeData = {
    currency: "USD ($)",
    language: "American English",
  };

  const initFields = [
    {
      title: strings().currency,
      isEdit: true,
      align: "middle",
      flag: false,
      value: fakeData.currency,
      component: <FieldCurrency />,
    },
    {
      title: strings().language,
      isEdit: true,
      align: "middle",
      flag: true,
      value: fakeData.language,
      component: <FieldLanguage />,
    }
  ];

  return (
    <div className="wrapper-setting-page">
      <Form labelCol={{ span: 24 }}>
        <div className="header-title">
          <h1>{strings().settings}</h1>
          {isMobile && <Divider />}
        </div>
        <Fragment>
          {initFields.map((item, index) => (
            <div key={index}>
              <Row justify="space-between" align={item.align as any} gutter={[10, 10]}>
                <Col xxl={5} xl={5} lg={5} md={5} sm={24} xs={24}>
                  <span className="title">{item.title}</span>
                </Col>
                <Col xxl={19} xl={19} lg={19} md={19} sm={24} xs={24}>
                  <Row
                    justify="space-between"
                    align={item.align as any}
                    gutter={{ xl: 10, sm: 0, xs: 0 }}
                  >
                    <Col span={(indexField === index) && isMobile ? 24 : 22}>
                      {indexField === index
                        ? item.component
                        : item.flag
                          ? (
                            <Space align="baseline">
                              <img style={{ position: 'relative', top: 6 }}
                                width={32} height={24} src={icons.flag_en} alt="en"
                              />
                              <span className="title">{item.value}</span>
                            </Space>
                          ) : (
                            <span className="title">{item.value}</span>
                          )
                      }
                    </Col>
                    <Col span={(indexField === index) && isMobile ? 12 : 2}>
                      <Space className={indexField === index && isMobile ? 'btn-small' : ''}>
                        {item.isEdit && (
                          <Button type="link" className="btn-edit" onClick={() => { handleClickShowForm(index) }}>
                            {indexField === index
                              ? strings().cancel
                              : item.value ? strings().edit : strings().add
                            }
                          </Button>
                        )}
                        {indexField === index && isMobile && (
                          <FButton className="primary-1" htmlType="submit">
                            {strings().save}
                          </FButton>
                        )}
                      </Space>
                    </Col>
                  </Row>
                </Col>
                {indexField === index && !isMobile ? (
                  <Col span={24} className="btn-submit">
                    <Row justify="end">
                      <Col span={3}>
                        <Form.Item>
                          <FButton className="primary-1" htmlType="submit">
                            {strings().save}
                          </FButton>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                ) : null}
              </Row>
              <Divider />
            </div>
          ))}
          <FieldDeleteAccount />
        </Fragment>
      </Form>
    </div>
  )
}
