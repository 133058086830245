import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./styles.scss";
import icons from "~/assets/icons";

// type TCustomCalendarProps = {};

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const CustomCalendar = () => {
  const [value, setValue] = useState<Value>(new Date());

  return (
    <React.Fragment>
      <Calendar
        onChange={setValue}
        value={value}
        className={"custom-calendar"}
        selectRange
        prev2Label=""
        next2Label=""
        nextLabel={
          <img src={icons.arrow_right} style={{ width: 24, height: 24 }} />
        }
        prevLabel={
          <img src={icons.arrow_left} style={{ width: 24, height: 24 }} />
        }
        tileContent={({ date }) => {
          return <div className="date-inner">{date.getDate()}</div>;
        }}
      />
    </React.Fragment>
  );
};

export default CustomCalendar;
