import { Col, Divider, Form, Input, message, Row } from "antd";
import { AnyObject } from "antd/es/_util/type";
import { useForm } from "antd/es/form/Form";
import { useTranslation } from "react-i18next";

import { FButton } from "~/components";
import useResponsive from "~/hooks/useResponsive";
import { authServices } from "~/services";

const ChangePassword: React.FC<any> = () => {
  const [form] = useForm();
  const {t} = useTranslation();
  const { isMobile } = useResponsive();

  const handleChangePassword = async (data: AnyObject) => {
    const response = await authServices.changePassword({
      currentPassword: data.currentPassword,
      newPassword: data.newPassword
    });
    if (response) message.success('Change Password Success');
    form.resetFields()
  }

  return (
    <div className="change-password-page">
      <div className="header-title">
        <h1>{t('change_password')}</h1>
        {isMobile && <Divider />}
      </div>
      <Row justify="start">
        <Col xxl={14} xl={14} lg={14} md={14} sm={24} xs={24}>
          <Form form={form} onFinish={handleChangePassword} labelCol={{ span: 24 }}>
            <Form.Item
              name="currentPassword"
              label={t('old_password')}
              rules={[
                {
                  required: true,
                  message: `${t('please_input_your')} ${t('password')}!`,
                },
                {
                  min: 10,
                  message: t('password_must_be_at_least_10_characters_long'),
                },
                {
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{10,}$/,
                  message: t('password_must_contain_at_least_one_uppercase_letter_one_lowercase_letter_and_one_number'),
                },
              ]}
            >
              <Input.Password placeholder={t('enter_a_password')} />
            </Form.Item>
            <div className="note-description">
              <p>{t('your_password_must_be_at_least_six_characters_and_cannot_contain_spaces_or_match_your_email_address')}</p>
            </div>
            <Form.Item
              name="newPassword"
              label={t('new_password')}
              rules={[
                {
                  required: true,
                  message: `${t('please_input_your')} ${t('password')}!`,
                },
                {
                  min: 10,
                  message: t('password_must_be_at_least_10_characters_long'),
                },
                {
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{10,}$/,
                  message: t('password_must_contain_at_least_one_uppercase_letter_one_lowercase_letter_and_one_number'),
                }
              ]}
            >
              <Input.Password placeholder={t('enter_a_password')} />
            </Form.Item>
            <Form.Item
              name="confirm"
              label={t('config_new_password')}
              dependencies={['newPassword']}
              rules={[
                {
                  required: true,
                  message: t('please_confirm_your_password'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('the_new_password_that_you_entered_do_not_match')));
                  },
                }),
              ]}
            >
              <Input.Password placeholder={t('enter_a_password')} />
            </Form.Item>
            <Form.Item wrapperCol={{ xs: 9, xl: 6 }}>
              <FButton htmlType="submit" className="btn-set-password">
                {t('set_password')}
              </FButton>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default ChangePassword;