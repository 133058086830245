import { Col, Row } from "antd";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";

import { LINKS, LIST_MENUS } from "~/configs";
import useResponsive from "~/hooks/useResponsive";
import { setUserInfo, useAppDispatch } from "~/redux";
import { authServices } from "~/services";
import FMenuDesktop from "./Desktop";
import FMenuMobile from "./Mobile/FMenuMobile";
import "./styles.scss";

const FMenuHeader = () => {
  const dispatch = useAppDispatch();
  const { isMobile } = useResponsive();
  // const userProfile = useAppSelector(selectUserInfo);

  useEffect(() => {
    const token = Cookies.get('accessToken');
    const fetchUser = async () => {
      const user: any = await authServices.getMe();
      dispatch(setUserInfo(user));
    };
    if (token) fetchUser();
  }, []);

  return (
    <Row justify="space-between" align="middle" className="f-menu-header">
      <Col span={4}>
        <h1>
          <Link to={LINKS.home}>TourAuct</Link>
        </h1>
      </Col>
      {
        !isMobile && (
          <Col span={9}>
            <nav className="memu-item">
              <ul>
                {
                  LIST_MENUS.map((menu, index) => (
                    <li key={index}>
                      <NavLink to={menu.key} className={({ isActive, isPending }) =>
                        isPending ? "memu-item--pending" : isActive ? "memu-item--active" : ""
                      }>{menu.name}</NavLink>
                    </li>
                  ))
                }
              </ul>
            </nav>
          </Col>
        )
      }
      <Col span={6}>
        {isMobile ? <FMenuMobile /> : <FMenuDesktop />}
      </Col>
    </Row>
  )
};

export default FMenuHeader;