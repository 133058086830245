import { Button, Col, Dropdown, MenuProps, Radio, Row } from "antd";
import icons from "~/assets/icons";
import strings from "~/assets/strings";
import "./styles.scss";

type TProps = {
  result?: number;
  sortItems?: Array<{ label: string, value: string }>;
  // TODO
  enableSort?: boolean;
  onChange: (val: string) => void;
  value?: string,
};

export function SortAuctionControl(props: TProps) {
  const { result, sortItems, enableSort = true, onChange, value = 'all' } = props;
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <a target="_blank" rel="noopener noreferrer" href="#">
          Sort by 1
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a target="_blank" rel="noopener noreferrer" href="#">
          Sort by 2
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a target="_blank" rel="noopener noreferrer" href="#">
          Sort by 3
        </a>
      ),
    },
  ];
  return (
    <div className="sort-auction-control sort-auction-control-wrap">
      <Row justify={"space-between"} align={"middle"}>
        <Col span={4} className="sort-auction-control__result">
          {result ? <span>{`${result} ${strings().results}`}</span> : null}
        </Col>
        <Col span={20} className="sort-auction-control__options">
          <Row justify={"end"}>
            {sortItems && sortItems.length > 0 ? (
              <Radio.Group defaultValue={value} value={value}>
                {sortItems.map((item, index) => (
                  <Radio.Button onChange={(e) => onChange(e.target.value)} key={index} value={`${item.value}`}>
                    {item.label}
                  </Radio.Button>
                ))}
              </Radio.Group>) : null}
            {enableSort ? (
              <Dropdown
                className="sort-auction-control__dropdown"
                menu={{ items }}
                placement="bottomRight"
              >
                <Button>
                  <img src={icons.sort} alt="" />
                  {strings().sort}
                </Button>
              </Dropdown>)
              : null}
          </Row>
        </Col>
      </Row>
    </div>
  );
}
