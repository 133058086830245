import { Divider, Space } from "antd";
import { useTranslation } from "react-i18next";
import { NavLink as NavLinkRoute } from "react-router-dom";
import { TranslationKeys } from "~/@types";

import { NAVIGATION_PROFILE } from "~/configs";

const NavLinkIsProfile = () => {
  const {t} = useTranslation();
  return (
    <div className="f-nav-menu-link">
      <Space direction="vertical" split={<Divider type="horizontal" />}>
        {NAVIGATION_PROFILE.map((nav, index) => (
          <div key={index}>
            <NavLinkRoute
              className={({ isActive, isPending }) => {
                return isPending ? "pending" : isActive ? "active" : ""
              }}
              to={nav.link}
              key={`nav-link-${index}`}
            >
              <Space>
                <span><img src={nav.icon} alt="" /></span>
                <span>{t(nav.title as TranslationKeys)}</span>
              </Space>
            </NavLinkRoute>
          </div>
        ))}
      </Space>
    </div>
  );
};

export default NavLinkIsProfile;