import { Button, Row } from 'antd'
import { useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import icons from '~/assets/icons'
import strings from '~/assets/strings'
import useResponsive from '~/hooks/useResponsive'

function Suppliers() {
  const { isMobile } = useResponsive();
  const swiperRef = useRef<any | null>(null);
  const items = [icons.GooglePay, icons.SamsungPay, icons.Maestro, icons.Klarna, icons.ShopPay, icons.AmericanExpress, icons.GooglePay, icons.SamsungPay, icons.Maestro, icons.Klarna, icons.ShopPay, icons.AmericanExpress, icons.OpenSea];
  return (
    <div className="home-suppliers container">
      <h2 className="home-thread__title">{strings().our_suppliers}</h2>
      <div className="f-swiper-wrapper">
        <Button className="f-btn-minus left-arrow" type="default" onClick={() => swiperRef.current.slidePrev()} hidden={isMobile}>
          <img src={icons.slide_arrow_left} alt="" />
        </Button>
        <Swiper
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          slidesPerView={5}
          loop
          autoplay
          spaceBetween={0}
          breakpoints={{
            0: {
              slidesPerView: 2.3,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 5,
            },
          }}
        >
          {items.map((item, index) => (
            <SwiperSlide key={index}>
              <Row justify={'center'} align={'middle'}>
                <img src={item} alt={`category-${index}`} />
              </Row>
            </SwiperSlide>
          ))}
        </Swiper>
        <Button className="f-btn-minus right-arrow" type="default" onClick={() => swiperRef.current.slideNext()} hidden={isMobile}>
          <img src={icons.slide_arrow_right} alt="" />
        </Button>
      </div>
    </div>
  )
}

export default Suppliers