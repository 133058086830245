import icons from "~/assets/icons";

export const LINKS = {
  home: "/",
  signIn: "/auth/sign-in",
  signUp: "/auth/sign-up",
  forgotPassword: "/auth/forgot-password",
  resetPassword: "/auth/reset-password",
  changePassword: "/auth/change-password",
  register: "/auth/register",
  about: "/about",
  contact: "/contact",
  dashboard: "/dashboard",
  profile: "/profile",
  settings: "/settings",
  notFound: "/404",
  term: '/terms',
  privacy: '/privacy',
  auction: '/auction',
  blog: '/blog',
  blogDetails: '/blog/:id',
  tour: '/tour',
  search: '/search',
  auctionBidder: '/auction/bidder',
  auctionBuynow: '/auction/buynow',
  auctionDetails: '/auction/:tourId/:auctionId',
  myWishList: '/my-wish-list',
  tourDetails: '/tour/:tourId',
  profilePersonal: '/profile/personal',
  profileWallet: '/profile/wallet',
  profileHistory: '/profile/history',
  profileSettings: '/profile/settings',
  profileChangePassword: '/profile/change-password',
  profileSignOut: '/profile/sign-out',
  JOIN_BIDS: '/auction/:tourId/:auctionId/join-bid',
  payment: '/auction/:tourId/:auctionId/payment',
  auctionsInTour: '/tour/:tourId/auctions'
};

export const ABOUT_ATOUR = [
  { name: "About Us", link: "" },
  { name: "Contact Us", link: "" },
  { name: "Help Center", link: "" },
  { name: "Careers", link: "" },
  { name: "Privacy Notice", link: "" },
];
export const PRODUCT = [
  { name: "Tour", link: "" },
  { name: "Tour Auction", link: "" },
  { name: "Stay", link: "" },
  { name: "Flights", link: "" },
  { name: "Flights + Hotel", link: "" },
  { name: "Attractions", link: "" },
  { name: "Blog", link: "" },
];

export const SOCIALS = [{
  name: "Instagram",
  link: "/",
  icon: icons.Instagram,
}, {
  name: "Facebook",
  link: "/",
  icon: icons.Facebook,
}, {
  name: "Telegram",
  link: "/",
  icon: icons.Telegram,
}, {
  name: "Youtube",
  link: "/",
  icon: icons.Youtube,
}];
export const PAYMENT = [icons.Visa, icons.Jcb, icons.Pay, icons.OnePay];

export const NAVIGATION_MOBILE = [
  {
    title: 'notifications',
    link: "",
    icon: icons.icon_notification,
    isLink: false,
    type: 'NOTIFICATION',
  },
  {
    title: 'wishlist',
    link: LINKS.myWishList,
    icon: icons.icon_wishlist,
    isLink: true,
  },
  {
    title: 'my_wallet',
    link: LINKS.profileWallet,
    icon: icons.icon_wallet,
    isLink: true,
  },
  {
    title: 'history',
    link: LINKS.profileHistory,
    icon: icons.icon_history,
    isLink: true,
  },
  {
    title: 'settings',
    link: LINKS.profileSettings,
    icon: icons.icon_setting,
    isLink: true,
  },
  {
    title: 'change_password',
    link: LINKS.profileChangePassword,
    icon: icons.icon_key,
    isLink: true,
  },
]

export const NAVIGATION_DESKTOP = [
  {
    title: 'my_account',
    link: LINKS.profilePersonal,
    icon: icons.icon_account,
    isLink: true,
  },
  {
    title: 'my_wallet',
    link: LINKS.profileWallet,
    icon: icons.icon_wallet,
    isLink: true,
  },
  {
    title: 'history',
    link: LINKS.profileHistory,
    icon: icons.icon_history,
    isLink: true,
  },
  {
    title: 'settings',
    link: LINKS.profileSettings,
    icon: icons.icon_setting,
    isLink: true,
  },
  {
    title: 'change_password',
    link: LINKS.profileChangePassword,
    icon: icons.icon_key,
    isLink: true,
  },
  {
    title: 'sign_out',
    link: "",
    icon: icons.icon_logout,
    isLink: false,
  },
];

export const NAVIGATION_PROFILE = [
  {
    title: 'personal_detail',
    link: LINKS.profilePersonal,
    icon: icons.icon_account,
  },
  {
    title: 'my_wallet',
    link: LINKS.profileWallet,
    icon: icons.icon_wallet,
  },
  {
    title: 'history',
    link: LINKS.profileHistory,
    icon: icons.icon_history,
  },
  {
    title: 'settings',
    link: LINKS.profileSettings,
    icon: icons.icon_setting,
  },
  {
    title: 'change_password',
    link: LINKS.profileChangePassword,
    icon: icons.icon_key,
  }
];

export default LINKS;
