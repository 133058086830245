import { Button, Divider, Image, Space } from 'antd';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import icons from '~/assets/icons';
import strings from '~/assets/strings';
import { LINKS } from '~/configs';
import useResponsive from '~/hooks/useResponsive';
import { makeRandomFromRange } from '~/utils';

const LocationCarousel = ({ locations }: any) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const swiperRef = useRef<any | null>(null);

  return (
    <div className="container locations-carousel">
      <h2 className="home-thread__title">{strings().location}</h2>
      <div className="f-swiper-wrapper">
        <Button className="f-btn-minus left-arrow" type="default" onClick={() => swiperRef.current.slidePrev()} hidden={isMobile}>
          <img src={icons.slide_arrow_left} alt="" />
        </Button>
        <Swiper
          modules={[Pagination]}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          slidesPerView={6}
          spaceBetween={16}
          loop
          breakpoints={{
            0: {
              slidesPerView: 2.4,
              navigation: false,
              spaceBetween: 8
            },
            768: {
              slidesPerView: 3,
              navigation: false,
              spaceBetween: 16
            },
            1024: {
              slidesPerView: 6,
            },
          }}
        >
          {locations.map(({ name, id }: any, index: number) => (
            <SwiperSlide key={index}>
              <Space direction='vertical' className='location-item'>
                <Link to={''} className='location-item__thumbnail'>
                  <Image src={'/images/Locations/Rectangle 5.png'} fallback='/images/not-found.png' alt={`category-${index}`} />
                </Link>
                <h3>
                  <Link to={`${LINKS.tour}?locationIds=${id}&page=1`}>{name}</Link>
                </h3>
                <p>{makeRandomFromRange(100, 5000)} km {t('away')}</p>
              </Space>
            </SwiperSlide>
          ))}
        </Swiper>
        <Button className="f-btn-minus right-arrow" type="default" onClick={() => swiperRef.current.slideNext()} hidden={isMobile}>
          <img src={icons.slide_arrow_right} alt="" />
        </Button>
      </div>
      <Divider />
    </div>
  )
};

export default LocationCarousel;