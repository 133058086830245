// import { RootState } from "../store";
import appReducer from "./appSlice";
import filterReducer from "./filterSlice";
import notificationSlice from "./notificationSlice";
import userReducer from "./userSlice";

const rootReducer = {
  app: appReducer,
  user: userReducer,
  filter: filterReducer,
  notification: notificationSlice,
};

export default rootReducer;
