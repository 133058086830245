import AmericanExpress from './icons/AmericanExpress.svg';
import Facebook from './icons/Facebook.svg';
import GooglePay from './icons/GooglePay.svg';
import Hammer from './icons/Hammer.svg';
import Instagram from './icons/Instagram.svg';
import Jcb from './icons/Jcb.svg';
import Klarna from './icons/Klarna.svg';
import Maestro from './icons/Maestro.svg';
import OnePay from './icons/OnePay.svg';
import OpenSea from './icons/OpenSea.svg';
import Pay from './icons/Pay.svg';
import SamsungPay from './icons/SamsungPay.svg';
import ShopPay from './icons/ShopPay.svg';
import Telegram from './icons/Telegram.svg';
import Usa from './icons/Usa.svg';
import Visa from './icons/Visa.svg';
import Warning from './icons/Warning.svg';
import Youtube from './icons/Youtube.svg';
import air_place from './icons/air-place.svg';
import arrow_minus_left from './icons/arrow-minus-left.svg';
import arrow_minus_right from './icons/arrow-minus-right.svg';
import arrow_right from './icons/arrow-right.svg';
import arrow_down from './icons/arrow_down.svg';
import arrow_left from './icons/arrow_left.svg';
import arrow_primary_down from './icons/arrow_primary_down.svg';
import arrow_up from './icons/arrow_up.svg';
import building from './icons/building.svg';
import calendar_active from './icons/calendar-active.svg';
import calendar_success from './icons/calendar-success.svg';
import calendar from './icons/calendar.svg';
import car from './icons/car.svg';
import card from './icons/card.svg';
import error_icon from './icons/error-icon.svg';
import error from './icons/error.svg';
import flag_en from './icons/flag-en.svg';
import flag_vi from './icons/flag-vi.svg';
import hammers_bid from './icons/hammers-bid.svg';
import heart_active from './icons/heart-active.svg';
import heart from './icons/heart.svg';
import icon_account from './icons/icon-account.svg';
import icon_ended from './icons/icon-ended.svg';
import icon_ending_soon from './icons/icon-ending-soon.svg';
import icon_history from './icons/icon-history.svg';
import icon_key from './icons/icon-key.svg';
import icon_logout from './icons/icon-logout.svg';
import icon_notification from './icons/icon-notification.svg';
import icon_received from './icons/icon-received.svg';
import icon_setting from './icons/icon-setting.svg';
import icon_starting_soon from './icons/icon-starting-soon.svg';
import icon_success from './icons/icon-success.svg';
import icon_unsucess from './icons/icon-unsucess.svg';
import icon_wallet from './icons/icon-wallet.svg';
import icon_wishlist from './icons/icon-wishlist.svg';
import image_preview from './icons/image_preview.svg';
import location from './icons/location.svg';
import location_search from './icons/location_search.svg';
import logo_apple from './icons/logo-apple.svg';
import logo_fb from './icons/logo-fb.svg';
import logo_google from './icons/logo-google.svg';
import love_face from './icons/love_face.svg';
import minus from './icons/minus.png';
import money_send from './icons/money-send.svg';
import money from './icons/money.svg';
import panda_foot from './icons/panda-foot.svg';
import password from './icons/password.svg';
import people from './icons/people.svg';
import pin_active from './icons/pin-active.svg';
import pin from './icons/pin.svg';
import placeholder_thumbnail from './icons/placeholder-thumbnail.svg';
import plus from './icons/plus.svg';
import profile from './icons/profile.svg';
import sad from './icons/sad.svg';
import slide_arrow_left from './icons/slide-arrow-left.svg';
import slide_arrow_right from './icons/slide-arrow-right.svg';
import sort from './icons/sort.svg';
import star from './icons/star.svg';
import success_icon from './icons/success-icon.svg';
import success from './icons/success.svg';
import ticket from './icons/ticket.svg';
import timer from './icons/timer.svg';
import traveller from './icons/traveller.svg';
import vuesax from './icons/vuesax.svg';
import wallet from './icons/wallet.svg'
      
const icons = {
  AmericanExpress,
  Facebook,
  GooglePay,
  Hammer,
  Instagram,
  Jcb,
  Klarna,
  Maestro,
  OnePay,
  OpenSea,
  Pay,
  SamsungPay,
  ShopPay,
  Telegram,
  Usa,
  Visa,
  Warning,
  Youtube,
  air_place,
  arrow_minus_left,
  arrow_minus_right,
  arrow_right,
  arrow_down,
  arrow_left,
  arrow_primary_down,
  arrow_up,
  building,
  calendar_active,
  calendar_success,
  calendar,
  car,
  card,
  error_icon,
  error,
  flag_en,
  flag_vi,
  hammers_bid,
  heart_active,
  heart,
  icon_account,
  icon_ended,
  icon_ending_soon,
  icon_history,
  icon_key,
  icon_logout,
  icon_notification,
  icon_received,
  icon_setting,
  icon_starting_soon,
  icon_success,
  icon_unsucess,
  icon_wallet,
  icon_wishlist,
  image_preview,
  location,
  location_search,
  logo_apple,
  logo_fb,
  logo_google,
  love_face,
  minus,
  money_send,
  money,
  panda_foot,
  password,
  people,
  pin_active,
  pin,
  placeholder_thumbnail,
  plus,
  profile,
  sad,
  slide_arrow_left,
  slide_arrow_right,
  sort,
  star,
  success_icon,
  success,
  ticket,
  timer,
  traveller,
  vuesax,
  wallet
}
export default icons