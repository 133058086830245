import FMenuHeader from "~/components/FMenu";
import './styles.scss';


export function HeaderBanner() {
  return (
    <header id="header" className="header--banner">
      <div className="container">
        <FMenuHeader />
      </div>
    </header>
  )
}